import * as querystring from "querystring";
import { isString } from "lodash";
import axios from "axios";
import urlJoin from "url-join";
import parseDuration from "parse-duration";
import timeoutifyPromise from "@/lib/utils/timeoutify-promise.js";

const urlOptions = querystring.parse(window.location.href.split("#")[1]);
let dictionaries = {};
let activeLocale = "";

let loadingListeners = [];

/**
 * Wait until `locale` will be ready to use. Will be timed outed in 30s.
 * Will not return error in case when `load` method is failed.
 * @async
 *
 * @param {String} locale - The locale in format `[projectId]-[languageId]`.
 */
export async function ready(locale) {
  locale = locale || activeLocale;

  if (dictionaries[locale]) {
    return true;
  }

  return timeoutifyPromise(new Promise(resolve => {
    loadingListeners.push({
      locale: locale,
      callback: resolve
    });
  }), parseDuration("30s"));
}

/**
 * Load dictionaly from the lms server.
 * @async
 *
 * @param {String} locale - The locale in format `[projectId]-[languageId]`.
 *
 * @returns {Promise<Boolean>} - `true` will be returned only in case when the dictionaly is loaded fine.
 */
export async function load(locale, options) {
  if (activeLocale === locale) {
    return true;
  }

  if (dictionaries[locale]) {
    activeLocale = locale;
    console.info(`Localization: locale has switched to "${ locale }".`);
    return true;
  }

  locale = locale.split("-");
  const projectId = locale[0];
  const languageId = locale[1];
  const url = urlJoin(options.domain, "/l10n/projects", projectId, "languages", `${ languageId }.json`);

  try {
    const response = await axios.get(url);
    if (response.data && response.data.dictionary) {
      dictionaries[locale] = response.data.dictionary;
      activeLocale = locale;

      loadingListeners.filter(listener => listener.locale === locale).map(listener => {
        listener.callback();
      });
      loadingListeners = loadingListeners.filter(listener => listener.locale !== locale);

      console.info(`Localization: locale has switched to "${ locale.join("-") }".`);
      return true;
    } else {
      console.warn(`No dictionary found for file: '${ url }'.`);
    }
  } catch (error) {
    console.error(`Can't load localization '${ url }':`, error);
  }

  return false;
}

/**
 * Replace the slug with any expression { d.something } to data from `data` object.
 * 
 * @param {String} stg - the string to replace the slugs.
 * @param {Object} data - the data for slugs.
 * 
 * @returns {String} String with replaced slugs.
 */
function replacer(str, data) {
  if (!str || !data || !isString(str) || !str.includes("{")) {
    return str;
  }

  const matches = str.match(/{[^]+?}/gi);
  if (matches) {
    matches.forEach(slug => {
      try {
        var replacer = new Function("d", "return " + slug.substring(1, slug.length - 1));
        str = str.replace(slug, replacer(data));
      } catch (e) {
        console.error("Found wrong slug: `" + slug + "` for `" + str + "` translation.");
      }
    });
  }

  return str;
}

/**
 * Translate the string using selected active locale.
 * 
 * @param {String} string - The string for localization.
 *
 * @returns {String} The original string or translations from active dictionary.
 */
export default function localize(string, data) {
  const dictionary = dictionaries[activeLocale];

  if (!dictionary) {
    //TODO: Prevent span of this messages for same localization.
    //console.warn(`Missing dictionary for '${ activeLocale }' locale`);
    return replacer(string, data);
  }

  if (!dictionary[string]) {
    //TODO: Prevent span of this messages for same localization.
    if (!("disableLocalizationWarnings" in urlOptions)) {
      console.warn(`Missing translation for '${ string }'; locale '${ activeLocale }'.`);
    }
    return replacer(string, data);
  }

  return replacer(dictionary[string], data);
}
