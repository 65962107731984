<template>
  <div
    class="line-segment"
    :style="Object.assign(_toCSSLine(start, end), {
      background: color
    })"
  ></div>
</template>

<style src="./line-segment.less" lang="less"></style>

<script>
  export default {
    name: "line-segment",
    props: {
      /* The start point of line in container space (default: { x: 0, y: 0 }). */
      start: {
        type: Object,
        default: () => ({ x: 0, y: 0 })
      },
      /* The end point of line in container space (default: { x: 0, y: 0 }). */
      end: {
        type: Object,
        default: () => ({ x: 0, y: 0 })
      },
      /* Color of the line. */
      color: String
    },
    methods: {
      /**
       * The point in euclidean space.
       *
       * @typedef {Object} EuclideanPoint
       *
       * @property {Number} x - The distance to point by X axis.
       * @property {Number} y - The distance to point by Y axis.
       */
      /**
       * Get the css styles of element based on required start and end points of line
       *
       * @param {EuclideanPoint} start - The start point of line.
       * @param {EuclideanPoint} end - The end point of line.
       *
       * @returns {Object} styles - css styles of line.
       * @returns {Number} styles.top
       * @returns {Number} styles.left
       * @returns {Number} styles.width
       * @returns {String} styles.transform - rotation along pivot (transform-origin: 0 100%).
       */
      _toCSSLine(start, end) {
        return {
          top: start.y,
          left: start.x,
          width: Math.sqrt(Math.pow((start.x - end.x), 2) + Math.pow((start.y - end.y), 2)),
          transform: `rotate(${ Math.atan2(end.y - start.y, end.x - start.x) * 180 / Math.PI }deg)`
        };
      }
    }
  };
</script>
