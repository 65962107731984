import lodash from "lodash";
import { camelCase } from "camel-case";

import nodeList from "./node-list.js";

export default async function(data, slideData) {
  const presentationSet = (await import("./presentation-set.js")).default;

  const additionalEventsData = {};
  await Promise.all(slideData.settings.associatedSlides.map(async (associatedSlide) => {
    const slideOptions = (associatedSlide.slideData.slideName.match(/\[.+?\]/ig) || []).reduce((options, rawOption) => {
      const [optionName, optionValue] = rawOption.substring(1, rawOption.length - 1).split("=");
      options[optionName] = optionValue;

      return options;
    }, {});

    if (slideOptions.eventIndex === undefined) {
      slideOptions.eventIndex = -1;
    }

    let widgetFilter, dataGetter;

    switch (slideOptions.type) {
      case "recommendations":
        widgetFilter = (widget) => widget.contentType === "DigitalShelfCT";
        break;
      case "related-events":
        widgetFilter = (widget) => widget.contentType === "NodeListCT" && widget.type === "events";
        break;
      case "inspiration-scanner":
        widgetFilter = (widget) => widget.contentType === "InspirationScanner";
        break;
      case "related-news":
        widgetFilter = (widget) => widget.contentType === "NodeListCT" && widget.type === "news";
        break;
      case "map":
        dataGetter = (convertedData) => {
          const wayfinderPosition = lodash.get(convertedData, "[0].data.settings.wayfinderPosition");
          if (wayfinderPosition) {
            return {
              viewId: wayfinderPosition.view_id,
              markId: wayfinderPosition.location_id
            };
          }
        };
        break;
    }

    if (widgetFilter) {
      dataGetter = (convertedData) => lodash.get(convertedData, "[0].widgets", []).find(widgetFilter);
    }

    if (dataGetter) {
      const convertedData = await presentationSet([associatedSlide], {});
      const data = dataGetter(convertedData);

      if (data) {
        additionalEventsData[slideOptions.eventIndex] = additionalEventsData[slideOptions.eventIndex] || {};
        additionalEventsData[slideOptions.eventIndex][slideOptions.type] = data;
      }
    }
  }));

  const nodeListWidet = await nodeList(data);

  let widget = {
    ...data.widgetPlacement,
    contentType: "EventsPromitionCT",
    name: "easyscreen-events-promotion",
    getter: async function(context) {
      const nodeListOptions = await nodeListWidet.getter(context);
      await Promise.all(nodeListOptions.items.map(async (event, eventIndex) => {
        const additionalEventData = {
          ...(additionalEventsData[eventIndex] || {}),
          ...(additionalEventsData["-1"] || {})
        };

        if (!additionalEventData) {
          return;
        }

        await Promise.all(Object.keys(additionalEventData).map(async type => {
          const eventDataWithType = additionalEventData[type];
          if (eventDataWithType.getter) {
            eventDataWithType.options = await eventDataWithType.getter(context);
          }

          event[camelCase(type)] = eventDataWithType.options || eventDataWithType;
        }));
      }));

      /* Hack for using the default title at component when widget title is empty string. */
      nodeListOptions.title = nodeListOptions.title || undefined;
      return nodeListOptions;
    },
    options: {
      ...nodeListWidet.options
    }
  };

  return widget;
}
