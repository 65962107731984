import { omit, isFunction, isString, isObjectLike, cloneDeep, castArray } from "lodash";
import axios from "axios";
import parseDuration from "parse-duration";
import * as querystring from "querystring";
import url from "@/lib/utils/url.js";
import LocalStorageCache from "@/lib/utils/local-storage-cache.js";
import { wrapXMLHttpError } from "@/lib/errors.js";
import * as easyscreenServer from "./easyscreen-server.js";
import easyscreenManager from "./easyscreen-manager.js";
import lmsConnector from "./lms-connector.js";
import wayfinder from "./wayfinder.js";
import friendlyFrank from "./friendly-frank.js";

/**
 * API collection for a easyscreen client server, easyscreen manager, lms connector and wayfinder.
 * @class EasyscreenRequest
 *
 * @param {Object} options - Options of APIs.
 * @param {Object} options.easyscreenManager
 * @param {String} options.easyscreenManager.domain - Base url of the easyscreen manager (eg.: http://admin-dev.stg.easyscreen.io).
 * @param {Object} options.lmsConnector
 * @param {Function<String, Object>} options.lmsConnector.l10n(str, context) - Localization method for internal errors.
 * @param {String} options.lmsConnector.domain - The base url of the lms connector api server
 *                                               with consumer hash (eg.: https://stg.lms.inlead.ws/naesbib).
 * @param {String} options.lmsConnector.coversDomain - The base url of the lms cover service
 *                                                     with consumer hash (eg.: https://stg.cover.lms.inlead.dk/naesbib).
 * @param {Boolean} options.lmsConnector.loanWithoutPin - Getter for allow the loan without pin (should not be a static value).
 * @param {Object} options.lmsConnector.screenOptions - Getter for a screen options (should not be a static value or reference to exact object).
 * @param {Object} [options.wayfinder]
 * @param {String} [options.wayfinder.domain] - The base url of the wayfinder (eg.: https://easyway-naesbib.easyscreen.io).
 */
export default class EasyscreenRequest {
  /**
   * Helper for create getter of the search parameters for a request based on route spec.
   * @static
   * @memberof EasyscreenRequest
   *
   * @param {Object} searchSpec - The specification of the request.
   *
   * @returns {Function<(Object)>} Getter for a search options.
   */
  static searchGetterFromSpec(searchSpec) {
    let getters = [];

    castArray(searchSpec).filter(param => param !== undefined).forEach(param => {
      if (isObjectLike(param)) {
        Object.keys(param).forEach(key => {
          getters.push({
            name: key,
            fn: param[key]
          });
        });
      } else if (isString(param)) {
        getters.push({
          name: param,
          fn: (_requestOptions) => _requestOptions[param]
        });
      }
    });

    getters = getters.filter(getter => getter.fn !== undefined);

    return (requestOptions) => {
      return getters.reduce((search, getter) => {
        const value = getter.fn(requestOptions);
        if (value !== undefined) {
          search[getter.name] = getter.fn(requestOptions);
        }

        return search;
      }, {});
    };
  }

  constructor(options) {
    this.initialized = Promise.all([
      this.initialize("easyscreenServer", {}, Object.keys(easyscreenServer).map(name => ({
        name,
        request: easyscreenServer[name]
      }))),
      this.initialize("easyscreenManager", options.easyscreenManager, easyscreenManager),
      this.initialize("lmsConnector", options.lmsConnector, lmsConnector),
      this.initialize("wayfinder", options.wayfinder, wayfinder),
      options.friendlyFrank ? this.initialize("friendlyFrank", options.friendlyFrank, friendlyFrank) : null
    ].filter(Boolean));
  }

  /**
   * Check if the API is fully initialized.
   *
   * @returns {Boolean}
   */
  ready() {
    return this.initialized;
  }

  /**
   * Initialize api by scheme.
   *
   * @param {String} context - The name of the api.
   * @param {Object} config - Api config.
   * @param {Object} scheme - The api scheme (see files ./easyscreen-manager.js, ./easyscreen-server.js, ./lms-connector.js and ./wayfinder.js).
   */
  async initialize(context, config, scheme) {
    const urlOptions = querystring.parse(window.location.href.split("#")[1]);
    const disableRequestsCache = "disableRequestsCache" in urlOptions;

    this[context] = {
      $config: config,
      $l10n: config.l10n
    };
    this[context].$request = this[context];

    for (let method of scheme) {
      if (!method.name) {
        console.warn("Ajax method scheme must have the 'name'!", method);
        continue;
      }

      if (method.static) {
        this[context][method.name] = function() {
          return method.static.apply(null, [this[context]].concat(Array.prototype.slice.call(arguments)));
        }.bind(this);

        continue;
      }

      if (method.request) {
        this[context][method.name] = method.request.bind(this[context]);
      } else {
        this[context][method.name] = await this.buildRequest(this[context], method);
      }

      if (disableRequestsCache) {
        LocalStorageCache.clear();
      }

      if (method.cache && disableRequestsCache !== true) {
        let cache = LocalStorageCache.cache[method.name] || new LocalStorageCache({
          name: method.name,
          duration: method.cache
        });

        const originalRequest = this[context][method.name];
        let getKey = options => JSON.stringify(options || {});
        
        this[context][method.name] = async (options) => {
          const requestKey = getKey(options);
          try {
            const responseValue = await cache.get(requestKey);
            if (responseValue) {
              return responseValue;
            }

            const originalRequestPromise = originalRequest(options);
            cache.set(requestKey, originalRequestPromise);

            const response = await originalRequestPromise;
            cache.set(requestKey, response);

            return response;
          } catch (error) {
            cache.remove(requestKey);

            throw error;
          }

        };
        this[context][method.name + "WithoutCache"] = originalRequest;
      }
    }
  }

  /**
   * Build a request method based on context and specification.
   *
   * @param {Object} context
   * @param {Object} context.$config - API config.
   * @param {Object} context.$request - Other named requests at this context.
   * @param {Function<String, Object>} context.$l10n(str, context) - Localization method for internal errors.
   *
   * @returns {Function<[Object]>} request(requestOptions) - The request handler.
   */
  async buildRequest(context, spec) {
    const searchGetter = EasyscreenRequest.searchGetterFromSpec(spec.search);
    if (spec.beforeCreate) {
      try {
        spec = await spec.beforeCreate(context, spec);
      } catch (error) {
        console.error("'beforeCreate' are skipped due to fail.", spec, error);
      }
    }

    return async (requestOptions) => {
      requestOptions = cloneDeep(requestOptions || {});
      requestOptions.$config = context.$config;
      requestOptions.$l10n = context.$l10n;
      requestOptions.$request = context.$request;

      if (isFunction(spec.options)) {
        requestOptions = await spec.options(requestOptions);
      }

      if (isFunction(spec.validator)) {
        await spec.validator(requestOptions);
      }

      const method = (isFunction(spec.method) ? spec.method(requestOptions) : spec.method) || "get";
      const path = isFunction(spec.path) ? spec.path(requestOptions) : spec.path;
      const data = isFunction(spec.data) ? spec.data(requestOptions) : undefined;
      const search = searchGetter(requestOptions);

      if (spec.filterEmpty) {
        spec.filterEmpty.forEach(key => {
          if (!search[key]) {
            delete search[key];
          }
        });
      }

      try {
        let _url = url({
          domain: context.$config.domain,
          path: path,
          search: search
        });
        let _data = omit(data, ["$config", "$l10n", "$request"]);
        if (spec.easyscreenProxy) {
          _url = url({
            path: "/proxy",
            search: {
              url: _url,
              body: _data ? JSON.stringify(_data) : undefined
            }
          });

          _data = undefined;
        }

        const response = await axios({
          timeout: parseDuration("30s"),
          method: method,
          ...omit(spec, [
            "beforeCreate",
            "beforeResolve",
            "cache",
            "request",
            "name",
            "options",
            "path",
            "search",
            "data",
            "method"
          ]),
          url: _url,
          data: _data
        });

        let body = response.data;
        if (spec.beforeResolve) {
          body = await spec.beforeResolve(body, requestOptions, response);
        }

        return body;
      } catch (error) {
        if (error.status >= 500 || !error.status) {
          console.error("Ajax request failed", error);
        }

        throw error.request ? wrapXMLHttpError(error.request) : error;
      }
    };
  }
}
