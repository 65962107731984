export default function(data, slideData) {
  var wData = data.data;
  var widget = {
    ...data.widgetPlacement,
    contentType: "TextPlusImageCT",
    name: "text-and-image",
    welcomeScreenType: "text-and-image",
    options: {
      /*
       * Do not use the "light" design for a widgets with content type "ImageCT".
       * The requiment of the ES-2316.
       */
      design: window.ESCONFIG.defaultDesign === "light" && data.widgetName !== "ImageCT" ? "light" : undefined,
      title: wData.headline || wData.imageHeadline,
      text: wData.text,
      image: wData.imageFile,
      imageAlign: wData.alignType || "width", // "width" or "height"
      subheadline: wData.subHeadline,
      qrCode: wData.qrCode,
      skin: [
        wData.imageFullscreen === "1" ? "fullscreen" : null,
        slideData.templateId === 12 ? "qr-shelf" : null
      ].filter(Boolean).join(" ")
    }
  };

  /* Override the widget position and size. */
  if (wData.imageFullscreen === "1") {
    widget.height = 100;
    widget.left = 0;
    widget.top = 0;
    widget.width = 100;
  }

  const isFullscreen = wData.imageFullscreen === "1" || widget.height >= 100 && widget.width >= 100;
  if (isFullscreen) {
    widget.presentationSet = {
      class: "presentation-set_fullscreen-image"
    };
  }

  /* Checking are done to prevent the wrong params when the fullscreen has passed as placement params. */
  widget.options.fullscreen = widget.top === 0 && widget.left === 0 && widget.width === 100 && widget.height === 100;

  return widget;
}
