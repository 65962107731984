import { ValidationError } from "@/lib/errors.js";
export default {
  namespaced: true,
  state() {
    return {
      defaultInterestPeriod: 7,
      materials: []
    };
  },
  mutations: {
    ADD_MATERIAL(state, { reservableId, materialDetail, materialHoldings }) {
      state.materials = state.materials.concat({
        id: reservableId,
        detail: materialDetail,
        holdings: materialHoldings,
        period: state.defaultInterestPeriod
      });
    },
    UPDATE_MATERIAL(state, material) {
      state.materials = state.materials.map(_material => {
        if (_material.id === material.id) {
          _material = Object.assign(_material, material, { id: _material.id });
        }

        return _material;
      });
    },
    REMOVE_MATERIAL(state, { reservableId }) {
      state.materials = state.materials.filter(material => material.id !== reservableId);
    },
    REMOVE_ALL_MATERIALS(state) {
      state.materials = [];
    }
  },
  actions: {
    isMaterailNotExists({ getters }, { reservableId }) {
      let existingMaterial = getters.materialByReservableId(reservableId);
      if (existingMaterial) {
        throw new ValidationError({ message: "This material already in basket" });
      }
    },
    isMaterailExists({ getters }, { reservableId }) {
      let existingMaterial = getters.materialByReservableId(reservableId);
      if (!existingMaterial) {
        throw new ValidationError({ message: "This material not in basket" });
      }
    },
    addMaterial({ commit, dispatch }, material) {
      dispatch("isMaterailNotExists", material);

      commit("ADD_MATERIAL", material);
    },
    updateMaterial({ commit, dispatch }, material) {
      dispatch("isMaterailNotExists", material);

      commit("UPDATE_MATERIAL", material);
    },
    removeMaterial({ commit, dispatch }, material) {
      dispatch("isMaterailExists", material);

      commit("REMOVE_MATERIAL", material);
    },
    removeAllMaterials({ commit }) {
      commit("REMOVE_ALL_MATERIALS");
    }
  },
  getters: {
    materialByReservableId: (state) => (id) => state.materials.find(material => material.id === id),
    materials: (state) => state.materials
  }
};
