export default function(data) {
  const wData = data.data;
  return {
    ...data.widgetPlacement,
    contentType: "InspirationScanner",
    name: "inspiration-scanner",
    options: {
      design: window.ESCONFIG.defaultDesign === "light" ? "light" : undefined,
      title: wData.text,
      withCoversOnly: wData.coversOnly == "1",
      availableOnly: wData.availableOnly == "1",
      departmentId: wData.department,
      branchId: wData.branch
    }
  };
}
