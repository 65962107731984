<template>
  <div
    :class="[
      'easyscreen-scrollable',
      { 'easyscreen-scrollable_native-scroll': $easyscreenNativeScrollable }
    ]"
  >
    <div class="easyscreen-scrollable--content-wrapper easyscreen-scrollable--smooth-edge">
      <div
        v-if="smoothEdgeColor"
        class="easyscreen-scrollable--smooth-edge-top"
        :style="{ backgroundImage: `linear-gradient(to bottom, ${ smoothEdgeColor } 15%, rgba(0,0,0,0) 100%)` }"
      ></div>
      <div
        :class="[
          'easyscreen-scrollable--content',
          { 'easyscreen-scrollable--content_with-scroll-bar': visibleScrollBar === true }
        ]"
        :scroll-top.prop.camel="scrollTop"
        :style="{ maxHeight: maxHeight, pointerEvents: scrolling ? 'none' : '' }"
        ref="container"
      >
        <slot></slot>
      </div>
      <div
        v-if="smoothEdgeColor"
        :data-smooth-edge-color="smoothEdgeColor"
        class="easyscreen-scrollable--smooth-edge-bottom"
        :style="{ backgroundImage: `linear-gradient(to top, ${ smoothEdgeColor } 15%, rgba(0,0,0,0) 100%)` }"
      ></div>
    </div>
  </div>
</template>

<style lang="less" src="../mixins.less"></style>
<style lang="less" src="./scrollable.less"></style>
<script>
  import Hammer from "hammerjs";
  import debounceDrop from "@/lib/utils/debounce-drop.js";
  import clampNumber from "@/lib/utils/clamp-number.js";
  import htmlElementHeight from "@/lib/utils/html-element-height.js";
  import Animate from "@/lib/utils/animate.js";
  import orientationMixin from "../mixins/orientation.js";
  import hammerjsToolsMixin from "../mixins/hammerjs-tools.js";

  export default {
    name: "easyscreen-scrollable",
    mixins: [orientationMixin, hammerjsToolsMixin],
    props: {
      /* The maximim height of scroll area. */
      maxHeight: [Number, String],
      /* Color of top and bottom gradients which do the content hiding is smoothly. */
      smoothEdgeColor: String,
      visibleScrollBar: Boolean,
      /* activate scroll to bottom when section will be overflowing */
      activateScrollBottom: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data() {
      return {
        scrollTop: 0,
        scrolling: false
      };
    },
    methods: {
      /**
       * Get the available scroll of the content area.
       *
       * @returns {Number} The available scroll in px.
       */
      getAvailableScroll() {
        return this.$refs.container.scrollHeight - htmlElementHeight(this.$refs.container);
      },
      /**
       * Get the current scroll top of the content area.
       *
       * @returns {Number} The current scroll in px.
       */
      getScroll() {
        return this.scrollTop;
      },
      /**
       * Set scroll position of the content area.
       * @async
       *
       * @param {Number} position - The position of scroll in px from top of the top of container.
       * @param {Object} [transition] - Transition settings.
       * @param {String} [transition.behavior="instant"] - The scroll transition - "instant" or "smooth".
       * @param {Number} [transition.duration=500] - The duration of transition.
       * @param {Number} [transition.easing="easeOutQuad"] - The easing of transition.
       */
      async setScroll(position, transition) {
        this.stopScrollTransition();
        transition = Object.assign({
          behavior: "instant",
          duration: 500,
          easing: "easeOutQuad"
        }, transition || {});

        position = clampNumber(position, 0, this.getAvailableScroll());

        if (transition.behavior !== "smooth") {
          this.scrollTop = position;
          return;
        }

        this._scrollTransition = new Animate({
          easingName: transition.easing,
          from: { scrollTop: this.getScroll() },
          to: { scrollTop: position },
          duration: transition.duration,
          tic: ({ scrollTop }) => {
            this.scrollTop = scrollTop;
          },
          done: () => {
            this._scrollTransition = null;
          }
        });

        return this._scrollTransition.start();
      },
      /**
       * Stop the transition of scroll.
       */
      stopScrollTransition() {
        if (this._scrollTransition) {
          this._scrollTransition.stop();
          this._scrollTransition = null;
        }
      },
      /**
       * Get the status of scroll transition.
       *
       * @return {Booelan} `true` - the scroll transition in progress.
       */
      scrollTransitionInProgress() {
        return Boolean(this._scrollTransition);
      },
      /**
       * Scroll to html element inside of container.
       *
       * @param {HTMLElement} htmlElement - The element to which should be visible after the container scroll.
       * @param {Object} [options] - The scroll options.
       * @param {String} [options.behavior="smooth"] - The scroll transition - "instant" or "smooth".
       * @param {String} [options.duration=500] - The duration in ms of smooth animation.
       * @param {String} [options.aligment="start"] - The aligment of element:
       * @param {Boolean} [options.scrollIfNeeded=true] - Do the scroll only when the element or part of element out of viewport.
       *   - "start" - The top of element will be placed at top of container.
       *   - "center" - The center of element will be placed at center of container.
       *   - "end" - The bottom of element will bt placed at bottom of container.
       *   - "nearest" - The "start", "center" or "end" will be automatically selected based on distance.
       */
      async scrollIntoView(htmlElement, options) {
        options = Object.assign({
          behavior: "smooth",
          duration: 500,
          aligment: "nearest",
          scrollIfNeeded: true
        }, options || {});

        const containerBoundings = this.$refs.container.getBoundingClientRect();
        const elementBoundings = htmlElement.getBoundingClientRect();
        const isOutOfViewport =
          containerBoundings.top > elementBoundings.top ||
          containerBoundings.top > elementBoundings.bottom ||
          containerBoundings.bottom < elementBoundings.top ||
          containerBoundings.bottom < elementBoundings.bottom;

        if (options.scrollIfNeeded === true && isOutOfViewport !== true) {
          return;
        }

        const scaleY = htmlElement.offsetHeight / elementBoundings.height;
        const toStart = elementBoundings.top - containerBoundings.top;
        const toCenter = (elementBoundings.top + elementBoundings.bottom) / 2 - (containerBoundings.top + containerBoundings.bottom) / 2;
        const toEnd = elementBoundings.bottom - containerBoundings.bottom;

        let scrollOffset = toStart; // aligment: "start"
        if (options.aligment === "nearest") {
          [toStart, toCenter, toEnd].forEach(_offset => {
            if (Math.abs(scrollOffset) > Math.abs(_offset)) {
              scrollOffset = _offset;
            }
          });
        } else if (options.aligment === "center") {
          scrollOffset = toCenter;
        } else if (options.aligment === "end") {
          scrollOffset = toEnd;
        }

        this.setScroll(this.getScroll() + scrollOffset * scaleY, {
          behavior: options.behavior,
          duration: options.duration
        });
      },
      /**
       * Initialize the drag and drop scrolling for content area.
       */
      _initDragAndDrop() {
        this._destroyDragAndDrop();

        this._hammerInstance = new Hammer(this.$refs.container);
        this._hammerInstance.get("pan").set({ direction: Hammer.DIRECTION_VERTICAL });

        let startY;
        let availableScroll = 0;
        this._hammerInstance.on("panstart", (e) => {
          if (this._isHammerjsPrevented()) {
            return true;
          }

          this._preventHammerjs();

          startY = e.center.y;
          availableScroll = this.getAvailableScroll();
          this.scrolling = true;
        });

        this._hammerInstance.on("panup pandown panend", debounceDrop((e) => {
          if (this._isHammerjsPrevented()) {
            return true;
          }

          let delta = this._applyScreenScale(e.center.y - startY);
          let offset = this.scrollTop;
          offset = clampNumber(offset - delta, 0, availableScroll);

          startY = e.center.y;
          this.scrollTop = offset;
        }, 16)); // Timeout for events due to next animation frame (the number of calls is usually 60 times per second).

        this._hammerInstance.on("panend", () => {
          if (this._isHammerjsPrevented()) {
            return true;
          }

          /* Required for prevent click events after when the scrolling is finished. */
          setTimeout(() => {
            this.scrolling = false;
            this._allowHammerjs();
          }, 50);
        });
      },
      /**
       * Destroy the drag and drop scrolling of the content area.
       */
      _destroyDragAndDrop() {
        if (this._hammerInstance) {
          this._hammerInstance.destroy();
        }
      },
      /**
       * add observer
       */
      _handleObserve(){
        this._destroyObserve();

        this.observer = new MutationObserver(() => {
          this.setScroll(this.getAvailableScroll());
        });

        this.observer.observe(this.$refs.container, {
          childList: true,
          subtree: true
        });
      },
      /**
       * destroy observer
       */
      _destroyObserve(){
        if (this.observer){
          this.observer.disconnect();
        }
      }
    },
    screenStandby() {
      if (!this._isDestroyed)
        this.setScroll(0, { behavior: "smooth" });
    },
    mounted() {
      if (this.$easyscreenNativeScrollable !== true)
        this._initDragAndDrop();

      if (this.activateScrollBottom)
        this._handleObserve();
    },
    beforeDestroy() {
      if (this.$easyscreenNativeScrollable !== true)
        this._destroyDragAndDrop();

      this.stopScrollTransition();
      this._destroyObserve();
    },
    components: {}
  };
</script>
