<template>
  <div
    class="welcome-screen-overview"
    :style="{
      borderRightColor: color
    }"
  >
    <transition-group
      name="zoom-slide"
      tag="div"
      class="welcome-screen-overview--screens"
    >
      <div
        v-for="(screen, screenIndex) in screens"
        :key="screen.id"
        :style="screen.style"
        :class="[
          'welcome-screen-overview--screen',
          {
            'welcome-screen-overview--screen_no-padding': screen.padding === false,
            'welcome-screen-overview--screen_hidden': screenIndex !== screens.length - 1
          },
          screen.className
        ]"
      >
        <div class="welcome-screen-overview--screen-content">
          <easyscreen-iframe
            v-if="screen.type === 'iframe'"
            :url="screen.data.url"
            :overlay="screen.data.overlay"
          />
          <presentation-set
            v-else-if="screen.type === 'generic'"
            :muteInfo="screen.data.muteInfo"
            :slides="[screen.data.slides].filter(Boolean)"
            @expand-element="(element) => {
              pushScreen({
                type: 'node-list-info',
                data: element
              });
            }"
          />
          <node-list-info
            v-else-if="screen.type === 'node-list-info'"
            layout="welcome-screen"
            :type="screen.data.type"
            :date="screen.data.date"
            :date-to="screen.data.dateTo"
            :location="screen.data.location"
            :price="screen.data.price"
            :image="screen.data.image"
            :title="screen.data.title"
            :es-teaser="screen.data.esTeaser"
            :text="screen.data.text"
            :lead="screen.data.lead"
            :single="screen.data.single"
          />
          <node-list-cinema-info
            v-else-if="screen.type === 'node-list-cinema-info'"
            :items="screen.data.items"
          />
          <div
            v-else-if="screen.type === 'welcome-screen-video'"
            class="welcome-screen-overview--video"
          >
            <h1
              v-if="screen.data.title"
              class="welcome-screen-overview--video-title"
              :style="{ color: screen.data.titleColor }"
            >{{ screen.data.title }}</h1>
            <p
              v-if="screen.data.text"
              class="welcome-screen-overview--video-text"
            >{{ screen.data.text }}</p>
            <easyscreen-video :src="screen.data.src" />
          </div>

          <div class="welcome-screen-overview--screen-links">
            <easyscreen-button
              v-for="(link, linkIndex) in (screen.links || [])"
              :key="`screen[${ screenIndex }].link[${ linkIndex }]:${ link.title }`"
              class="welcome-screen-overview--screen-link"
              size="small"
              :modificator="['full-width', 'padding-big']"
              :style="{ backgroundColor: color }"
              @click.native="() => {
                let screenData = link.screenData
                if (!screenData) {
                  screenData = {
                    type: 'generic',
                    links: link.links,
                    data: {
                      muteInfo: true,
                      slides: link.slides
                    }
                  };
                }

                pushScreen(screenData);
              }"
            >
              <i
                v-if="link.icon"
                :class="link.icon"
              ></i>

              {{ link.title }}
            </easyscreen-button>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<style src="./welcome-screen-overview.less" lang="less"></style>

<script>
  import randomId from "@/lib/utils/random-id.js";
  import debounceDrop from "@/lib/utils/debounce-drop.js";

  import EasyscreenButton from "../core/button/button.vue";
  import EasyscreenIframe from "../core/iframe/iframe.vue";
  import EasyscreenVideo from "../core/video/video.vue";
  const PresentationSet = () => import("../presentation-set/presentation-set.vue");
  import NodeListInfo from "../node-list/node-list-info.vue";
  import NodeListCinemaInfo from "../node-list/node-list-cinema-info.vue";

  /**
   * The event fires when the last screen has been removed from screens stack
   *
   * @event welcome-screen-overview#close
   */
  export default {
    name: "welcome-screen-overview",
    data() {
      return {
        color: "",
        screens: []
      };
    },
    methods: {
      /**
       * Screen data for welcome screen overview.
       *
       * @typedef {Object} WelcomeScreenOverview
       * @property {String} screen.id - Id of the screen.
       * @property {(Object|String)} screen.style - Custom styles of the screen.
       * @property {Boolean} [screen.padding=true] - Flag for disable the padding.
       * @property {(String|String[]|Object)} screen.className - Custom screen class.
       * @property {Object} screen.data - Any screen data; exact format related to `screen.type`.
       * @property {String} screen.type - Type of the screen generic, openin-hours and etc.
       * @property {Object[]} screen.links - Fast action of screen.
       * @property {String} Screen.color - Screen color.
       */
      /**
       * Get available screens.
       *
       * @returns {WelcomeScreenOverview[]} screens
       */
      getScreens() {
        return this.screens;
      },
      /**
       * Open new screen on top of current.
       * Cannot be called more than one time per 350ms.
       *
       * @param {WelcomeScreenOverview} options - Screen options with default values for id and color.
       * @param {String} [options.id=randomId()]
       * @param {String} [options.color=previousScreen.color]
       */
      pushScreen: debounceDrop(function(options) {
        if (!options.id) {
          options.id = randomId();
        }
        const previousScreen = this.screens[this.screens.length - 1] || {};
        this.screens = this.screens.concat([Object.assign({
          color: previousScreen.color
        }, options)]);

        this.color = options.color || previousScreen.color;
      }, 350),
      /**
       * Hide the active screen and how previous.
       * Or close the overview if active screen is last.
       * Cannot be called more than one time per 350ms.
       * @fires welcome-screen-overview#close
       */
      popScreen: debounceDrop(function() {
        if (this.screens.length === 1) {
          this.$emit("close");

          return;
        }

        this.screens = this.screens.slice(0, -1);
      }, 350),
      /**
       * Clear the screens stack.
       */
      clear() {
        this.screens = [];
      }
    },
    components: {
      "easyscreen-button": EasyscreenButton,
      "easyscreen-iframe": EasyscreenIframe,
      "easyscreen-video": EasyscreenVideo,
      "presentation-set": PresentationSet,
      "node-list-info": NodeListInfo,
      "node-list-cinema-info": NodeListCinemaInfo
    }
  };
</script>
