import capitalize from "capitalize";

/**
 * Class for a application skin. Changes the styles and the part of components
 * depends on skin name.
 * @class Skin
 *
 * @param {Object} options - The constructor options.
 * @param {String} [options.skin] - The skin name.
 */
export default class Skin {
  constructor(options) {
    this.skin = options.skin;

    this.init();
  }

  /**
   * Init the skin instance (fill `is{skinName}` variables).
   * Adds the "data-skin" attribute to the body element.
   */
  init() {
    document.body.setAttribute("data-skin", this.skin);

    [
      "agerso",
      "bronbib",
      "btj",
      "faxbib",
      "guldbib",
      "herlev",
      "horsholm",
      "krsbib",
      "middelfart",
      "naesbib",
      "slagelse",
      "tarnbib",
      "varde",
      "vestbib",
      "rclbib"
    ].forEach(_skin => {
      this["is" + capitalize(_skin)] = _skin === this.skin;
    });
  }

  /**
   * Remove the "data-skin" attribute from the body element.
   */
  destroy() {
    if (document.body.getAttribute("data-skin") === this.skin) {
      document.body.removeAttribute("data-skin");
    }
  }
}
