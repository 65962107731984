import { render, staticRenderFns } from "./scrollable.vue?vue&type=template&id=3d2e49ae"
import script from "./scrollable.vue?vue&type=script&lang=js"
export * from "./scrollable.vue?vue&type=script&lang=js"
import style0 from "../mixins.less?vue&type=style&index=0&prod&lang=less&external"
import style1 from "./scrollable.less?vue&type=style&index=1&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports