/**
 * @file
 * This class has created since the original vuex does not support the encapsulation.
 * Goal: provide to part of screen their own storage which will not be directly described in main application,
 * but will do the communication between sub screens more easily.
 */

import EventEmitter from "eventemitter3";
import { cloneDeep, clone, isObjectLike, isFunction, omit, once } from "lodash";
import randomId from "@/lib/utils/random-id.js";

/**
 * The shared storage with vuex like interface.
 * @class VuexLike
 * @augments EventEmitter3
 *
 * @property {Number} [historySize=1000] - The total size of history records per instance.
 * @property {Number} [historyEnabled=true] - Flag for enable the history writing.
 *
 * @param {String} name - The name of the storage (used only for identification).
 * @param {Object} scheme - The scheme of storage.
 * @param {Object} scheme.state - The initial state of storage.
 * @param {Object} scheme.getters - The custom getters of storage.
 * @param {Object} scheme.mutations - The sync handlers which changes the storage.
 * @param {Object} scheme.actions - The the storage specific actions.
 */
export class VuexLike extends EventEmitter {
  /**
   * Default method for set state of store.
   *
   * @param {Object} state - The state of store.
   * @param {Object} payload - The data for update the store state.
   */
  static setState(state, payload) {
    Object.assign(state, payload || {});
  }

  constructor(name, scheme) {
    super();

    this.name = name;
    this.history = [];

    this.state = isFunction(scheme.state) ? scheme.state() : cloneDeep(scheme.state || {});
    this.mutations = clone(scheme.mutations || {});
    this.mutations.setState = VuexLike.setState;

    this.actions = clone(scheme.actions || {});
    this.getters = clone(scheme.getters || {});

    this._root = scheme._root || this;
    this._modules = {};
    Object.keys(scheme.modules || {}).forEach(moduleName => {
      const _module = scheme.modules[moduleName];
      let _store;

      if (_module.namespaced) {
        _store = new VuexLike(moduleName, Object.assign({}, _module, { _root: this }));
        this.state[moduleName] = _store.state;
      } else {
        this._modules[moduleName] = _module;
        this.state = Object.assign(this.state, isFunction(_module.state) ? _module.state() : cloneDeep(_module.state || {}));
      }

      [{
        hookType: "mutations",
        handlerName: "commit"
      }, {
        hookType: "actions",
        handlerName: "dispatch"
      }, {
        hookType: "getters",
        handlerName: "get"
      }].forEach(({ hookType, handlerName }) => {
        Object.keys(_module[hookType] || {}).forEach(hookName => {
          if (_module.namespaced) {
            this._root[hookType][`${ moduleName }/${ hookName }`] = function() {
              const [, ...args] = arguments;

              return _store[handlerName].apply(_store, [hookName].concat(args));
            };
          } else {
            this._root[hookType][hookName] = _module[hookType][hookName];
          }
        });
      });
    });

    this._getters = {};
    /* Attach the getters to storage instance. */
    Object.keys(this.getters).forEach(getterName => {
      this.getters[getterName] = this.getters[getterName].bind(this._root, this.state);

      Object.defineProperty(this, getterName, { get: this.getters[getterName] });
      Object.defineProperty(this._getters, getterName, { get: this.getters[getterName] });
    });

    if (scheme.onInitialization) {
      scheme.onInitialization({
        emit: this.emit.bind(this),
        commit: this.commit.bind(this),
        dispatch: this.dispatch.bind(this),
        state: cloneDeep(this.state)
      });
    }
  }

  /**
   * The method for call mutation. Call of this method will be saved into the instance history.
   *
   * @fires VuexLike#before-mutation
   * @fires VuexLike#mutated
   *
   * @param {(String|Object)} name - The name of mutation or object with property `type` which equals to mutation name.
   * @param {...*} * - The any params which should be passed to mutation.
   */
  commit() {
    const { name, args } = this._getTypeAndArguments.apply(this, arguments);
    if (!this.mutations[name]) {
      return this._notFoundError("Mutation", name);
    }

    let newState = cloneDeep(this.state);
    /**
     * The event before the mutation.
     *
     * @event VuexLike#before-mutation
     * @type {Void}
     */
    this.emit("before-mutation", this.state);
    this.emit(`before-mutation_${ name }`, this.state);
    this.mutations[name].apply(this._root, [newState].concat(args));

    this._pushHistory({
      type: "mutation",
      state: this.state,
      newState: newState,
      arguments: args
    });

    const previousState = this.state;
    this.state = newState;

    /**
     * The event after the mutation.
     *
     * @event VuexLike#mutated
     * @type {Void}
     */
    this.emit("mutated", previousState, newState);
    this.emit(`mutated_${ name }`, previousState, newState);

    return newState;
  }

  /**
   * The method for call action. Call of this method will be saved into the instance history.
   * @async
   *
   * @param {(String|Object)} name - The name of action or object with property `type` which equals to action name.
   * @param {...*} * - The any params which should be passed to action.
   */
  async dispatch() {
    const { name, args } = this._getTypeAndArguments.apply(this, arguments);
    if (!this.actions[name]) {
      return this._notFoundError("Action", name);
    }

    const id = randomId();
    this._pushHistory({
      id: id,
      type: "action",
      state: this.state,
      arguments: args
    });

    return new Promise((resolve, reject) => {
      let done = once(function(err) {
        this._pushHistory.apply(this, [{
          id: id,
          type: err ? "action_failed" : "action_done",
          state: this.state,
          error: err
        }].concat(Array.prototype.slice.call(arguments)));

        if (err) {
          return reject(err);
        }

        resolve();
      }.bind(this));

      let promiseCallback = this.actions[name].apply(this._root, [{
        emit: this.emit.bind(this),
        commit: this.commit.bind(this),
        dispatch: this.dispatch.bind(this),
        done: done,
        state: cloneDeep(this.state),
        getters: this._getters
      }].concat(args));

      if (isObjectLike(promiseCallback) && isFunction(promiseCallback.then)) {
        promiseCallback.then(() => { done(); }).catch(done);
      }
    });
  }

  /**
   * Helper for display the action\mutation not found error.
   *
   * @param {String} container - The name of container where method was not found.
   * @param {String} name - The name of not found method.
   */
  _notFoundError(container, name) {
    console.warn(`${ container } with name "${ name }" not found for ${ this.name } store.`);
  }

  /**
   * Helper to get the name of method and arguments to pass through.
   *
   * @param {(String|Object)} name - The name of method or object with property `type` which equals to method name.
   * @param {...*} * - The any params to pass through.
   *
   * @returns {Object} data
   * @returns {String} data.name - The name of method.
   * @returns {*[]} data.args - The args to pass through.
   */
  _getTypeAndArguments(name) {
    let _name = name;
    let args = Array.prototype.slice.call(arguments, 1);

    if (isObjectLike(name)) {
      _name = name.type;
      args = [omit(name, ["type"])].concat(args);
    }

    return { name: _name, args };
  }
  /**
   * Push the history into the instance history when the history is enabled.
   *
   * @param {Object} action - The action to save in history.
   */
  _pushHistory(action) {
    if (!VuexLike.historyEnabled) {
      return;
    }

    this.history.push(cloneDeep(Object.assign(action, {
      timestamp: new Date()
    })));

    if (this.history.length > VuexLike.historySize) {
      this.history = this.history.slice(-VuexLike.historySize);
    }
  }

  on(event, fn, context) {
    super.on(event, fn, context);
    return () => this.off(event, fn, context);
  }

  once(event, fn, context) {
    super.once(event, fn, context);
    return () => this.off(event, fn, context);
  }
}

VuexLike.historySize = 1000;
VuexLike.historyEnabled = false;

export default VuexLike;

export const VuexLikePlugin = {
  install(Vue) {
    Vue.mixin({
      beforeCreate() {
        const options = this.$options;
        // store injection
        if (options.store) {
          this.$store = typeof options.store === "function"
            ? options.store()
            : options.store;
        } else if (options.parent && options.parent.$store) {
          this.$store = options.parent.$store;
        }
      }
    });
  }
};
