import lodash from "lodash";
import getStyle from "@/lib/utils/get-style.js";

export default {
  methods: {
    /**
     * Fit the container by height changing it font size.
     *
     * @param {Object} options - Fit options.
     * @param {Number} options.maxHeight - The max required height of container; must be above 0.
     * @param {String} options.property - .
     * @param {Object|Array<Object>} options.target - The target opiton to reduce size.
     * @param {Number} options.target.property - The name of property assigned to container font size
     * @param {Number} options.target.default - The default value of property
     *
     * @param {HTMLElement} [options.ref] - The html element for adjustment.
     */
    async _fitByFontSize(options) {
      options = { ...(options || {}) };
      if (!options.maxHeight || options.maxHeight <= 0) {
        console.warn("'maxHeight' should be above 0");

        return;
      }

      options.target = lodash.castArray(options.target).filter(Boolean);

      if (options.target.length === 0) {
        console.warn("'target' should not be empty");

        return; 
      }

      const node = this.$refs[options.ref];
      if (!node) {
        return;
      }

      const isFit = function() {
        return getStyle(node, "height") <= options.maxHeight;
      };

      options.target.forEach(target => {
        if (target.default != null) {
          this[target.property] = target.default;
        }
      });
      await this.$nextTick();

      if (isFit()) {
        return;
      }

      const limit = Math.min.apply(null, options.target.map(t => t.default));
      for (let i = 0; i < limit; i++) {
        options.target.forEach(target => {
          if (target.default != null) {
            this[target.property] = (target.default - i);
          }
        });
        await this.$nextTick();

        if (isFit()) {
          break;
        }
      }
    }
  }
};
