/**
 * @async
 * Timeout using promise.
 * The returned promise will have extended property `._timeout` which equals
 * to returned value of setTimeout, can be used for prevent timeout call.
 *
 * @param {Number} duration - The duration of timeout.
 *
 * @returns {Promise} Promise-based callback.
 */
export default function(duration) {
  let timeout;
  let promise = new Promise((resolve) => {
    timeout = setTimeout(resolve, duration);
  });
  promise._timeout = timeout;

  return promise;
}
