import { omit } from "lodash";
import parseDuration from "parse-duration";
import axios from "axios";
import url from "@/lib/utils/url.js";

export async function proxy(options) {
  return axios({
    timeout: parseDuration("30s"),
    method: "get",
    ...omit(options, ["body"]),
    url: url({
      path: "/proxy",
      search: {
        url: options.url,
        data: options.body ? JSON.stringify(options.body) : undefined
      }
    })
  });
}

const paymentProvidersMap = {
  "dibs-fbs": "mobilepay",
  "vipps-bibliofil": "vipps"
};

export async function paymentConfirmation(options) {
  return axios({
    method: "get",
    timeout: parseDuration("30s"),
    url: url({
      path: "/payment-confirmation",
      search: {
        paymentSystem: paymentProvidersMap[options.paymentProvider],
        url: options.url
      }
    })
  });
}

