export default [{
  name: "ping",
  path: "/ping"
}, {
  name: "operations",
  path: "/operations"
}, {
  name: "saveOperation",
  method: "post",
  path: "/operation",
  data: options => ({
    ...options,
    timestamp: (new Date()).toISOString()
  })
}, {
  name: "deleteOperation",
  method: "delete",
  path: "/operation",
  data: options => ({
    id: options.id
  })
}];
