<template>
  <modal-blank
    :is="type === 'modal-based' ? 'modal-blank' : 'div'"
    :class="[
      'easyscreen-loader',
      `easyscreen-loader_${ type }`,
      `easyscreen-loader_${ size }`,
      `easyscreen-loader_design-${ design }`,
      { 'easyscreen-loader_with-background': withBackground }
    ]"
    ref="modal"
    @before-open="(event) => $emit('before-open', event)"
    @opened="(event) => $emit('opened', event)"
    @before-close="(event) => $emit('before-close', event)"
    @closed="(event) => $emit('closed', event)"
    :design="design"
    :position="position"
  >
    <template :is="type === 'modal-based' ? 'template' : 'div'" slot="raw">
      <div class="easyscreen-loader--spinner">
        <i class="fa fa-spinner fa-spin"></i>
      </div>
    </template>
  </modal-blank>
</template>

<style lang="less" src="./loader.less" />
<script>
  import ModalBlank from "../modal/blank.vue";

  export default {
    name: "easyscreen-loader",
    props: {
      /* The type of loder: in-place or modal-based (default). */
      type: {
        type: String,
        default: "modal-based",
        validator: _type => ["modal-based", "in-place"].includes(_type)
      },
      size: {
        type: String,
        default: "big",
        validator: _size => ["big", "medium", "small"].includes(_size)
      },
      /* The global reskin for materials list. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      },
      withBackground: Boolean,
      position: String
    },
    methods: {
      /**
       * Show the loader, in 200 ms after call (by default).
       * The non instant show is required to prevent blinking of loader to very fast operations.
       * @async
       *
       * @param {Object} [options] - The show options.
       * @param {Boolean} [options.instantly=false] - The show loader instantly.
       */
      show(options) {
        options = options || {};
        if (options.instantly) {
          return this.$refs.modal.show();
        }

        return new Promise((resolve) => {
          this._showTimeout = setTimeout(() => {
            this._showTimeout = null;
            this.$refs.modal.show(resolve);
          }, 200);
        });
      },
      /**
       * Hide the loader.
       * @async
       */
      hide() {
        this._clearShowTimeout();
        return this.$refs.modal.hide();
      },
      /**
       * Reset the show timeout in case if the show is not instant.
       */
      _clearShowTimeout() {
        if (this._showTimeout) {
          clearTimeout(this._showTimeout);
          this._showTimeout = null;
        }
      }
    },
    components: {
      "modal-blank": ModalBlank
    }
  };
</script>
