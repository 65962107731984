<template>
  <div class="node-list-cinema-info">
    
  </div>
</template>

<style src="./node-list-cinema-info.less" lang="less"></style>

<script>
  /*
   * TODO: Migrate later, when the middelfart will have any movies (see ES-1412).
   * https://middelfart.easyscreen.io/#id=df1bc9f9d25b6c7db5d373ae92e46c65
   */
  export default {
    name: "node-list-cinema-info"
  };
</script>
