import { get, isEqual, cloneDeep } from "lodash";
import * as querystring from "querystring";
import EventEmitter from "eventemitter3";
import parseDuration from "parse-duration";
import humanizeDuration from "humanize-duration";
import * as d3 from "d3-timer";
import moment from "moment";
import randomId from "@/lib/utils/random-id.js";
import Stopwatch from "@/lib/utils/stopwatch.js";
import l10n from "@/lib/localization/localization.js";
import fitNumber from "@/lib/utils/fit-number.js";
import presentationSetConverter from "@/lib/presentation-set-converter/presentation-set.js";
import welcomeScreenConverter from "@/lib/presentation-set-converter/welcome-screen.js";
import easyscreenMenuConverter from "@/lib/presentation-set-converter/easyscreen-menu.js";

const startTime = Date.now();
/**
 * Fetch the data from easyscreen mananger and controls the screens.
 * @class ScreenControl
 * @augments EventEmitter3
 *
 * @param {Object} config - The easyscreen client configuration.
 * @param {Object} config.name - The client name for slide title when empty.
 * @param {Object} config.manager - The easyscreen mananger settings.
 * @param {Object} config.enable - The list of enabled features.
 */
export default class ScreenControl extends EventEmitter {
  /**
   * Helper for recognize the type of the screen based on data
   * (presentation set, welcome screen or easyscreen menu).
   * @static
   * @memberof ScreenControl
   *
   * @param {Object} originalData - The response from easyscreen manger.
   *
   * @returns {String} Type of the screen. One of: unknown, presentationSet, welcomeScreen, easyscreenMenu.
   */
  static recognize(originalData) {
    const screenData = get(originalData, "screenData");
    if (!screenData || !Array.isArray(screenData)) {
      return "unknown";
    }

    const isWelcomeScreen = screenData.some(datum => {
      return get(datum, "welcomeScreen");
    });

    if (isWelcomeScreen) {
      return "welcomeScreen";
    }

    const isMenu = get(screenData, "[0].menu") === true;
    if (isMenu) {
      return "easyscreenMenu";
    }

    return "presentationSet";
  }

  /**
   * Helper for get the list of active features based on easyscreen manger response
   * and url query parameters (`enableFeature` and `disableFeature` - see more in ./easyscreen-info.js).
   * @static
   * @memberof ScreenControl
   *
   * @param {Object} originalData - The response from easyscreen manger.
   *
   * @returns {String[]} The list of enabled features.
   */
  static getActiveFeatures(originalData) {
    const urlOptions = querystring.parse(window.location.href.split("#")[1]);
    let features = get(originalData, "screenOptions.features") || [];

    if (urlOptions.enableFeature) {
      let customFeaturesEnabled = [];
      urlOptions.enableFeature.split(",").forEach(function(feature) {
        if (features.includes(feature) === false) {
          features.push(feature);
          customFeaturesEnabled.push(feature);
        }
      });

      console.log(`%cNext features enabled by url parameter: ${ customFeaturesEnabled.join(", ") }`, "color: orange;");
    }

    if (urlOptions.disableFeature) {
      let customFeaturesDisabled = [];
      const featuresForDisable = urlOptions.disableFeature.split(",");
      features = features.filter(function(feature) {
        const disabled = featuresForDisable.includes(feature);
        if (disabled) {
          customFeaturesDisabled.push(feature);
        }

        return !disabled;
      });

      console.log(`%cNext features disabled by url parameter: ${ customFeaturesDisabled.join(", ") }`, "color: orange;");
    }

    return features;
  }

  constructor(options) {
    super();

    this.config = {
      attemptsBetweenTimeout: 5,
      timeoutBetweenAttempts: parseDuration("1m 30s"),
      updateInterval: parseDuration("2m"),
      invetralBetweenAttempts: parseDuration("30s"),
      lmsPingInterval: parseDuration("60s"),
      ...options.config
    };


    this.screens = options.screens;
    this.activeScreen = null;
    this.activeScreenName = null;
    this.root = options.mountTo;

    this._fetchContent = options.fetchContent;
    this._fetchPreview = options.fetchPreview;
    this._lmsPing = options.lmsPing;
    this._lastFetch = null;
    this._fetchError = null;
    this._fetchErrorStopwatch = null;
    this._totalRestoringAttempts = null;
    this._restoringAttempt = null;
    this._pollingTimeout = null;
    this._pollingInterval = null;
    this._presentationSetIndex = 0;
    this._isLmsOnline = true;

    this.init();
  }

  /**
   * Checks is the lms api is available.
   * @async
   *
   * @returns {Boolean}
   */
  async isLmsOnline() {
    try {
      await this._lmsPing();
      return true;
    } catch (_) {
      return false;
    }
  }

  /**
   * Starts the lms checking .
   */
  startLmsPing() {
    this.stopLmsPing();
    this._lmsPingInterval = d3.interval(() => this._onLmsPing(), this.config.lmsPingInterval);

    this._onLmsPing();
  }

  /**
   * Stops the lms checking.
   */
  stopLmsPing() {
    if (this._lmsPingInterval) {
      this._lmsPingInterval.stop();
      this._lmsPingInterval = null;
    }
  }

  /**
   * Stop the polling of the updates from easyscreen manager.
   * Does not afects to the recovery polling (recovery after the fetch error).
   */
  pauseUpdates() {
    if (this._fetchError || this._pollingInterval === null) {
      return;
    }

    this._stopPolling();
  }

  /**
   * Start the polling of the updates from easyscreen manager.
   * Does not affects to the recovery polling (recovery after the fetch error).
   */
  unpauseUpdates() {
    if (this._fetchError || this._pollingInterval !== null) {
      return;
    }

    this._startPolling({ instantPoll: false });
  }

  /**
   * Initialized the screen control (starts the polling).
   * Called at consutructor.
   */
  init() {
    if (this._pollingInterval) {
      return;
    }

    this._startPolling();
  }

  /**
   * Stops any polling (data and recovery).
   */
  destroy() {
    this.stopLmsPing();

    if (!this._pollingInterval) {
      return;
    }

    this._stopPolling();
  }

  /**
   * Checks if the `screenName` are supported screen.
   *
   * @param {String} screenName - The name of the screen to check.
   *
   * @returns {Boolean} true - screen are supported, otherwise false.
   */
  _isValidScreen(screenName) {
    if (!this.screens[screenName]) {
      console.error(`Screen with name "${ screenName }" not found.`);
      return false;
    }

    return true;
  }

  /**
   * Checks if the supported screen has mounted into document.
   *
   * @param {String} screenName - The name of the screen to check.
   *
   * @returns {Boolean} true - screen are mounted, false - not mounted, undefined - screen are not supported.
   */
  _isMounted(screenName) {
    return this._isValidScreen(screenName) && this.activeScreenName === screenName;
  }

  /**
   * Mounts the supported screen are into document (this.root).
   *
   * @param {String} screenName - The name of the screen to mount.
   *
   * @returns {(Object|undefined)} object (instance of vue content) - screen are mounted, undefined - screen are not supported.
   */
  _mountScreen(screenName) {
    if (this._isValidScreen(screenName)) {
      this._unmountScreen();
      this.activeScreen = this.screens[screenName]();
      this.activeScreenName = screenName;

      return this.activeScreen.$mount(this.root);
    }
  }

  /**
   * Unmount supported screen from document and destroy the vue component.
   *
   * @param {String} screenName - The name of the screen to unmount.
   */
  _unmountScreen() {
    if (!this.activeScreen) {
      return;
    }

    this.activeScreen.$destroy();
    if (this.activeScreen && this.activeScreen.$el.parentNode) {
      this.activeScreen.$el.parentNode.removeChild(this.activeScreen.$el);
    }

    this.activeScreen = null;
    this.activeScreenName = null;

    // Return the target for app mount back to DOM, since the vue.$mount(node) will replace the target node.
    if (!this.root.parentNode) {
      document.body.appendChild(this.root);
    }
  }

  /**
   * Show (mount) supported screen and hide (unmount) the active.
   *
   * @param {String} screenName - The name of the screen to show.
   */
  _show(screenName) {
    if (!this._isValidScreen(screenName) || this._isMounted(screenName)) {
      return;
    }

    this._mountScreen(screenName);
  }

  /**
   * Get the presentation set data based on response from easyscreen manager and
   * index of the presentation set (manager can send multiple presentation sets
   * with a different settings and their own slides).
   * @async
   *
   * @param {Object} originalData - The response from easyscreen manager.
   * @param {Number} presentationSetIndex - The index of the presentation set at response.
   *
   * @returns {Object} presentationSetData
   * @returns {Object[]} presentationSetData.slides - The list of presentation set slides.
   * @returns {Number} presentationSetData.defaultActiveSlide - The index of default slide (presentation set starts from this index).
   * @returns {(String|null)} presentationSetData.message - The message instead of slides (error or something else), optional.
   */
  async _getPresentationSetData(originalData, presentationSetIndex) {
    const urlOptions = querystring.parse(window.location.href.split("#")[1]);
    const defaultActiveSlide = parseInt(urlOptions.activeSlide, 10) || 0;

    let data;
    let options = {};
    if (originalData.screenData.content) {
      options = originalData.screenData;
      data = originalData.screenData.content;
    } else if (originalData.screenData.length !== 0) {
      options = originalData.screenData[presentationSetIndex || 0];
      data = options && options.content;
    }

    options.screenOptions = originalData.screenOptions || {};
    const slides = await presentationSetConverter(data, options || {});
    if (slides.length !== 0 && defaultActiveSlide < slides.length) {
      return {
        slides: slides,
        defaultActiveSlide: defaultActiveSlide,
        /* The empty message field are required to drop the error message after recover. */
        message: null
      };
    }

    return {
      slides: [],
      defaultActiveSlide: 0,
      message: l10n("No data for show.")
    };
  }

  /**
   * Get the data for welcome screen.
   * @async
   *
   * @param {Object} originalData - The response from easyscreen manager.
   *
   * @returs {Object} welcomeScreenData
   * @returs {Object[]} welcomeScreenData.slides - The data for welcome screen tiles.
   */
  async _getWelcomeScreenData(originalData) {
    return {
      slides: await welcomeScreenConverter(originalData.screenData)
    };
  }

  /**
   * Get the data for easyscreen menu.
   * @async
   *
   * @param {Object} originalData - The response from easyscreen manager.
   *
   * @returs {Object} Easyscreen menu data
   */
  async _getEasyscreenMenuData(originalData) {
    return easyscreenMenuConverter(originalData.screenData[0]);
  }

  /**
   * Get the screen data by screen name.
   * @async
   *
   * @param {String} screenName - The name of the screen to get the data.
   * @param {Object} originalData - The response from easyscreen manager.
   *
   * @returs {Object} Screen data
   */
  async _getScreenData(screenName, originalData) {
    const getters = {
      presentationSet: this._getPresentationSetData,
      welcomeScreen: this._getWelcomeScreenData,
      easyscreenMenu: this._getEasyscreenMenuData
    };

    return await getters[screenName](originalData);
  }

  /**
   * Fetch data from easyscreen manager.
   * @async
   */
  async _fetchData() {
    const urlOptions = querystring.parse(window.location.href.split("#")[1]);
    const screenId = urlOptions.screenId || urlOptions.id;
    const pageId = urlOptions.pageId;
    const today = moment().format("DD-MM-YYYY");
    const todayTime = moment().format("HHmm");

    const date = moment(`${ urlOptions.fetchDate || today } ${ urlOptions.fetchTime || todayTime }`, "DD-MM-YYYY HHmm");

    if (urlOptions.fetchTime || urlOptions.fetchDate) {
      const fetchTimeModifier = urlOptions.fetchTimeModifier ? parseInt(urlOptions.fetchTimeModifier, 10) : 1;
      date.add((Date.now() - startTime) * fetchTimeModifier, "milliseconds");
    }

    const fetchDate = date.format("DD-MM-YYYY");
    const fetchTime = date.format("HHmm");

    const lastFetchDate = this._lastFetch ? this._lastFetch.format("DD-MM-YYYY") : "00-00-0000";
    const lastFetchTime = this._lastFetch ? this._lastFetch.format("HHmm") : "0000";
    let response = await (screenId ? this._fetchContent : this._fetchPreview)({
      screenId: screenId || pageId,
      date: fetchDate,
      time: fetchTime,
      lastUpdateDate: lastFetchDate,
      lastUpdateTime: lastFetchTime
    });

    if (pageId !== undefined && screenId === undefined) {
      response = {
        screenData: response,
        screenOptions: {
          features: [],
          notifications: {},
          touch: true
        }
      };
    }

    this._lastFetch = date;
    return response;
  }

  /**
   * Poll handler (called by polling interval).
   * Requests the data from manager and updates the screens.
   * @async
   */
  async _onPoll() {
    if (this._lastPollId || this._isLmsOnline === false) {
      return;
    }

    const pollId = randomId();
    this._lastPollId = pollId;

    let screenData;
    let errorMessage;
    try {
      screenData = await this._fetchData();
    } catch (error) {
      const statusCode = get(error, "response.status");
      const isProxyError = get(error, "response.data.code") === "proxy.pipe";
      screenData = get(error, "response.data");
      errorMessage = get(error, "response.data.errors[0]");

      if (screenData && !errorMessage && statusCode === 500 && !isProxyError) {
        errorMessage = "Critical error from manager side.<br/>The content cannot be displayed.";
      }

      if (!errorMessage) {
        console.error("Can't fetch content from manager:", error);

        this._lastScreenData = null;
        this._lastPollId = null;
        this._fetchError = error;
        this._fetchErrorStopwatch = new Stopwatch();
        this._stopPolling();
        this._startPolling();

        return;
      }
    }

    /*
     * Prevent the data hadling when the another poll request are called
     * or when the polling are paused\stoppped.
     */
    if (this._lastPollId !== pollId || !this._pollingInterval) {
      return;
    }

    if (isEqual(this._lastScreenData, screenData)) {
      this._lastPollId = null;
      return;
    }

    this._lastScreenData = cloneDeep(screenData);

    let screenName = ScreenControl.recognize(screenData);
    let screenProps;

    this._show(screenName);
    this.emit("before-screen-update", screenData, screenName);

    this.activeScreen && this.activeScreen.$off("end-of-presentation-set");
    if (!this._isValidScreen(screenName)) {
      screenName = "presentationSet";
      screenProps = {
        slides: [],
        defaultActiveSlide: 0,
        message: errorMessage ? l10n(errorMessage) : l10n("No data for show.")
      };
    } else {
      if (screenName === "presentationSet" && screenData.screenData.length > 1) {
        this._presentationSetIndex = 0;
        this.activeScreen && this.activeScreen.$on("end-of-presentation-set", () => {
          this._onPresentationSetEnd(screenData);
        });
      }
      screenProps = await this._getScreenData(screenName, screenData);
    }

    Object.assign(this.activeScreen.$props, screenProps);
    this.emit("screen-updated", screenData);

    this._lastPollId = null;
  }

  /**
   * Hanlder of presentation set end. Switches to the next presentation set
   * or restarts the current if there no other presentation sets.
   * @async
   *
   * @param {Object} @async - The raw data of the current screen.
   */
  async _onPresentationSetEnd(screenData) {
    this._presentationSetIndex = fitNumber(this._presentationSetIndex + 1, {
      lt: 0,
      value: screenData.screenData.length - 1
    }, {
      gt: screenData.screenData.length - 1,
      value: 0
    });

    Object.assign(
      this.activeScreen.$props,
      await this._getPresentationSetData(screenData, this._presentationSetIndex)
    );
  }

  /**
   * Hanlder of restoring poll interval.
   * Tries to get the data from manager and restore the original screen behaviour.
   * @async
   */
  async _onRestoringPoll() {
    if (this._lastPollId || this._isLmsOnline === false) {
      return;
    }

    if (this._restoringAttempt === null) {
      this._totalRestoringAttempts = 0;
      this._restoringAttempt = 0;

      this._show("presentationSet");
      Object.assign(this.activeScreen.$props, {
        slides: [],
        defaultActiveSlide: 0,
        message: l10n("The connection has lost.<br/><br/>Trying to reconnect...")
      });
    }

    const attemptsBetweenTimeout = this.config.attemptsBetweenTimeout;
    const timeoutBetweenAttempts = this.config.timeoutBetweenAttempts;
    if (this._restoringAttempt !== 0 && this._restoringAttempt % attemptsBetweenTimeout === 0) {
      this._restoringAttempt = 0;
      this._stopPolling();
      this._pollingTimeout = d3.timeout(() => this._startPolling(), timeoutBetweenAttempts);
    }

    this._restoringAttempt += 1;
    this._totalRestoringAttempts += 1;

    const pollId = randomId();
    this._lastPollId = pollId;

    let hasError = false;
    try {
      await this._fetchData();
    } catch (error) {
      hasError = true;
    }

    /*
     * Prevent the data hadling when the another poll request are called
     * or when the polling are paused\stoppped.
     */
    if (this._lastPollId !== pollId || !this._pollingInterval) {
      return;
    }

    this._lastPollId = null;
    if (hasError) {
      return;
    }

    const duration = humanizeDuration(this._fetchErrorStopwatch.stop());
    const from = moment(this._fetchErrorStopwatch.startTime).format("DD-MM-YYYY HH:mm");
    const to = moment(this._fetchErrorStopwatch.stopTime).format("DD-MM-YYYY HH:mm");
    console.warn([
      `Connection has lost at ${ from }-${ to };`,
      `Total duration: ${ duration };`,
      `Total attempts: ${ this._totalRestoringAttempts }.`
    ].join("\n"));

    this._totalRestoringAttempts = null;
    this._restoringAttempt = null;
    this._fetchError = null;
    this._fetchErrorStopwatch = null;

    this._stopPolling();
    this._startPolling();
  }

  /**
   * Starts the polling based on instance status (recovery or default polling).
   *
   * @param {Object} [options] - The polling options.
   * @param {Boolean} [options.instantPoll=true] - Call the poll handler after initialization (without waiting for first tic).
   */
  _startPolling(options) {
    options = options || {};

    this._stopPolling();

    let inverval = this.config.updateInterval;
    let onPoll = this._onPoll.bind(this);

    if (this._fetchError) {
      inverval = this.config.invetralBetweenAttempts;
      onPoll = this._onRestoringPoll.bind(this);
    }

    this._pollingInterval = d3.interval(onPoll, inverval);
    if (options.instantPoll !== false) {
      onPoll();
    }
  }

  /**
   * Stops the polling and unsubscribe from presentation set end event.
   */
  _stopPolling() {
    this._lastPollId = null;
    this.activeScreen && this.activeScreen.$off("end-of-presentation-set");

    if (this._pollingTimeout) {
      this._pollingTimeout.stop();
      this._pollingTimeout = null;
    }

    if (this._pollingInterval) {
      this._pollingInterval.stop();
      this._pollingInterval = null;
    }
  }

  async _onLmsPing() {
    try {
      await this._lmsPing();
      if (this._isLmsOnline === false) {
        this._isLmsOnline = true;

        this._lastPollId = null;
        this._fetchError = null;
        this._startPolling();
        this.emit("lms-status-changed", this._isLmsOnline);
      }
    } catch (error) {
      if (this._isLmsOnline === true) {
        this._isLmsOnline = false;

        this._stopPolling();
        this.emit("lms-status-changed", this._isLmsOnline);
      }
    }
  }
}
