<template>
  <div
    :class="[
      'library-events-tile-content',
      { 'library-events-tile-content_two-elements': amountOfElements === 2 }
    ]"
  >
    <h1
      v-if="title"
      class="library-events-tile-content--title"
    >
      {{ title }}
    </h1>

    <div
      v-for="element in arrayOfElements"
      :key="element.key"
      :class="[
        'library-events-tile-content--element',
        `library-events-tile-content--element_${ element.type }`
      ]"
      :style="{
        backgroundImage: element.image ? `var(--background-overlay), url('${ element.image }')` : null,
        '--element-color': color
      }"
      @click="() => $emit('open-element', element)"
    >
      <h2
        v-if="element.location && element.type === 'event'"
        class="library-events-tile-content--element-location"
      >
        {{ _adjustLocation(element.location) }}
      </h2>
      <i
        v-if="element.location && _isAvailableWayfinderLocation(element.location)"
        class="library-events-tile-content--element-map-marker fal fa-map-marker-alt"
        @click.stop="() => $emit('open-wayfinder', _generateWayfinderUrlByLocation(element.location))"
      ></i>

      <h1 class="library-events-tile-content--element-title">
        {{ _cutText(element.title, 30) }}
      </h1>

      <div
        v-if="element.organizer"
        class="library-events-tile-content--element-organizer"
      >
        {{ element.organizer }}
      </div>

      <div class="library-events-tile-content--element-date">
        <div
          v-if="!subType.includes('cinema')"
        >
          {{ _getElementDate(element) }}
        </div>
        <div>
          {{ _getElementTime(element) }}
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./library-events-tile-content.less" lang="less"></style>

<script>
  import { castArray, uniqBy } from "lodash";
  import moment from "moment";
  import cutText from "@/lib/utils/cut-text.js";
  import l10n from "@/lib/localization/localization.js";

  import wayfinderMixin from "../../core/mixins/wayfinder.js";

  export default {
    name: "library-events-tile-content",
    mixins: [wayfinderMixin],
    props: {
      title: String,
      elements: {
        type: [Array, Object],
        default: () => ([])
      },
      color: String,
      subType: {
        type: String,
        default: "",
        validator: _subType => [
          "",
          "cinema",
          "for-game",
          "welcome-screen-modifer",
          "cinema welcome-screen-modifer",
          "for-game welcome-screen-modifer"
        ].includes(_subType)
      },
      amountOfElements: {
        type: Number,
        default: 3,
        validator: _amountOfElements => _amountOfElements >= 0
      }
    },
    computed: {
      arrayOfElements() {
        if (Array.isArray(this.elements)) {
          return this.elements.slice(0, this.amountOfElements);
        }

        let elements = [];
        Object.keys(this.elements).forEach(key => {
          elements = elements.concat(castArray(this.elements[key]));
        });

        elements = elements.slice(0, this.amountOfElements);
        elements = uniqBy(elements.map(element => {
          return {
            id: JSON.stringify(element),
            ...element
          };
        }), "id");

        return elements;
      }
    },
    methods: {
      /* Proxy for `cutText` method. */
      _cutText: cutText,
      /**
       * Add "..." at the location if there more than 3 parts.
       *
       * @param {String} location - The location with comma delimiter.
       *
       * @return {String} The adjusted location.
       */
      _adjustLocation(location) {
        if (!location) {
          return "";
        }

        location = location.split(",");
        if (location.length > 2) {
          location = location.slice(0, 1).join(", ") + "...";
        } else {
          location = location.join(", ");
        }

        return location;
      },
      /**
       * Get date from element based on active skin.
       *
       * @param {Object} element
       * @param {String} element.date - Element date in format DD/MM-YYYY.
       *
       * @returns {String} Date in format "dddd [d.] DD MMMM" for middelfart and "DD.MM.YYYY" for other.
       */
      _getElementDate(element) {
        const date = moment(element.date, "DD/MM-YYYY");
        if (this.$easyscreenSkin.isMiddelfart) {
          return date.format("dddd [d.] DD MMMM");
        }

        return date.format("DD.MM.YYYY");
      },
      /**
       * Get time from element based on active skin.
       *
       * @param {Object} element
       * @param {String} element.from - Date from; for middlefart only.
       * @param {String} element.fromTime - Element start time; for non-middlefart skins.
       * @param {String} element.toTime - Element end time; for non-middlefart skins.
       *
       * @returns {String} Date in format "Kl. HH.mm" for middelfart and `element.fromTime-element.toTime` for other.
       */
      _getElementTime(element) {
        if (this.$easyscreenSkin.isMiddelfart) {
          let time = l10n("Kl.") + " " + moment(element.from).format("HH.mm");
          if (!this.subType.includes("cinema")) {
            time += " - " + moment(element.to).format("HH.mm");
          }


          return time;
        }

        return [element.fromTime, element.toTime].filter(Boolean).join(" - ");
      }
    }
  };
</script>
