import { isObjectLike } from "lodash";
import moment from "moment";

export default function(data) {
  const wData = data.data;
  return {
    ...data.widgetPlacement,
    contentType: "OpeningHoursCT",
    name: "opening-hours",
    welcomeScreenType: "opening-hours",
    getter: async function openingHoursGetter(context) {
      try {
        let response = await context.$easyscreenRequest.lmsConnector.openingHours({
          node: wData.node
        });

        /*
          Use first if fetched more than one, since widget support only one item.
          Related to changed from LC-201.
        */
        if (Array.isArray(response)) {
          response = response[0];
        }

        if (isObjectLike(response)) {
          response.dayLabel = wData.dayLabel;
          response.openedTodayLabel = wData.openedTodayLabel;
          response.serviceTimeLabel = wData.serviceTimeLabel;
        }

        if (response && Array.isArray(response.openingHours) && response.openingHours[0]) {
          const startDate = moment(response.openingHours[0].date);
          const startDayOfWeek = startDate.isoWeekday();
          if (startDayOfWeek > 1) {
            for (let i = 1; i < startDayOfWeek; i++) {
              response.openingHours = [{
                date: moment(response.openingHours[0].date).date(startDate.date() - i).format("YYYY-MM-DD"),
                isUnknown: true
              }].concat(response.openingHours);
            }
          }
        }

        return response;
      } catch (error) {
        console.error("Can't get opening hours.", error);
        return null;
      }
    }
  };
}
