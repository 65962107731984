var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
  'node-list-info',
  `node-list-info_${ _vm.layout }`,
  `node-list-info_scale-${ _vm.$easyscreenScale }`,
  `node-list-info_design-${ _vm.design }`,
  _vm.orientation
]},[(_vm.originalImage && ['welcome-screen', 'welcome-screen-light'].includes(_vm.layout))?_c('div',{staticClass:"node-list-info--image-wrapper"},[_c('img',{directives:[{name:"onready",rawName:"v-onready",value:(_vm._adjustTeaserHeight),expression:"_adjustTeaserHeight"}],ref:"image",staticClass:"node-list-info--image",attrs:{"src":_vm.originalImage}}),(_vm.qrCode)?[(_vm.layout === 'welcome-screen-light')?_c('qr-code',{staticClass:"node-list-info--qr-code",attrs:{"qr-code":_vm.$easyscreenRequest.lmsConnector.wrapCover(_vm.qrCode)}}):_c('div',{staticClass:"node-list-info--qr-code",style:({ backgroundImage: `url('${ _vm.$easyscreenRequest.lmsConnector.wrapCover(_vm.qrCode) }')` })})]:_vm._e()],2):(_vm.originalImage)?_c('div',{staticClass:"node-list-info--css-image",style:({ backgroundImage: `url('${ _vm.originalImage }')` })}):_vm._e(),_c('div',{staticClass:"node-list-info--type"},[_vm._v(_vm._s(_vm.type))]),_c('h2',{staticClass:"node-list-info--title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"node-list-info--meta"},[_c('div',[_c('div',{staticClass:"node-list-info--date"},[_vm._v(_vm._s(_vm.formattedDate))]),_c('div',{staticClass:"node-list-info--location"},[_vm._v(_vm._s(_vm.location))])]),(_vm.type === 'event' && _vm.price)?_c('div',{staticClass:"node-list-info--price"},[_vm._v(_vm._s(_vm.price))]):_vm._e()]),(_vm.withScrollableContent)?_c('scrollable',{ref:"scrollable",staticClass:"node-list-info--teaser ck-editor",attrs:{"max-height":_vm.maxTeaserHeight}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.anyTeaser)}})]):_c('div',{staticClass:"node-list-info--teaser ck-editor"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.anyTeaser)}})]),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }