import * as querystring from "querystring";

export default {
  data() {
    return {
      wayfinderInProgressRequests: {},
      wayfinderLocations: {}
    };
  },
  methods: {
    /**
     * Check availaility of location at wayfinder based on local state and
     * perform request to wayfinder if local state is not available.
     *
     * @param {String} location - The material location.
     *
     * @returns {Boolean} - false location not found, true - location found.
     */
    _isAvailableWayfinderLocation(location) {
      if (!this.$easyscreenConfig.get("enable.wayfinder")) {
        return false;
      }

      if (this.wayfinderLocations[location] === undefined) {
        this._validateWayfinderLocation(location).catch((error) => {
          console.error(`Can't validate location: "${ location }"`, error);
        });

        return false;
      }

      return true;
    },
    /**
     * Helper of `this._validateWayfinderLocation`.
     * Do the location checking on wayfinder side (request).
     * @async
     *
     * @param {String} location - The material location.
     */
    async _validateWayfinderLocation(location) {
      if (this.wayfinderInProgressRequests[location]) {
        return;
      }

      this.wayfinderInProgressRequests[location] = Date.now();

      const urlOptions = querystring.parse(window.location.href.split("#")[1]);

      try {
        const placementData = await this.$easyscreenRequest.wayfinder.includes({
          placement: location,
          branchId: urlOptions.branchId
        });

        this.wayfinderLocations[location] = placementData;
      } catch (error) {
        delete this.wayfinderInProgressRequests[location];

        throw error;
      }

      delete this.wayfinderInProgressRequests[location];
    },
    /**
     * Helper for `this._generateWayfinderUrl` by location.
     *
     * @param {String} location - The material location.
     *
     * @returns {String} Wayfinder url with selected location.
     */
    _generateWayfinderUrlByLocation(location) {
      const placementData = this.wayfinderLocations[location];
      if (!placementData) {
        return null;
      }

      return this._generateWayfinderUrl(placementData);
    },
    /**
     * Generate wayfinder url based on passed options.
     *
     * @param {Object} options - The wayfinder options.
     * @param {String} options.viewId - The id of the view.
     * @param {String} options.markId - The id of the mark\zone.
     * @param {String} [options.localization="this.$easyscreenConfig.wayfinder.lang"] - `config.wayfinder.lang` have priority over `options.location`.
     * @param {String} [options.here="urlHashQuery.here"] - The id of here mark on the map.
     * @param {String} [options.map] - The id\index of the map at view.
     *
     *
     * @returns {String} Wayfinder url with selected location.
     */
    _generateWayfinderUrl(options) {
      const searchQuery = {
        view: options.viewId
      };

      const wafinderLocalization = this.$easyscreenConfig.get("wayfinder.lang", options.localization);
      if (wafinderLocalization) {
        searchQuery.lang = wafinderLocalization;
      }

      const hashQuery = {
        markId: options.markId
      };

      if (!options.here) {
        const urlOptions = querystring.parse(window.location.href.split("#")[1]);
        hashQuery.here = urlOptions.here;
      }

      if (options.map) {
        hashQuery.map = options.map;
      }

      return `${ this.$easyscreenConfig.get("wayfinder.url") }?${ querystring.stringify(searchQuery) }#${ querystring.stringify(hashQuery) }`;
    }
  }
};
