<template>
  <div class="welcome-screen-overview-light">
    <transition-group
      v-for="groups in allGroups"
      :key="groups.type"
      name="group-slide"
      tag="div"
      :class="[
        'welcome-screen-overview-light--screens-groups',
        `welcome-screen-overview-light--screens-groups_${ groups.type }`
      ]"
    >
      <div
        v-for="(group, groupIndex) in groups.data"
        :key="group.id"
        class="welcome-screen-overview-light--screens-group"
        :style="{
          '--group-index': groupIndex,
          '--groups-amount': groups.data.length,
          ...(group.style || {})
        }"
      >
        <transition-group
          name="screen-slide"
          tag="div"
          class="welcome-screen-overview-light--screens"
        >
          <div
            v-for="(screen, screenIndex) in group.screens"
            :key="screen.id"
            class="welcome-screen-overview-light--screen"
            :style="{ '--screen-index': screenIndex, '--screens-amount': group.screens.length }"
          >
            <component
              :is="$easyscreenIsMobile ? 'scrollable' : 'div'"
              max-height="100%"
            >
              <get-component v-if="screen.node" :data="screen.node" />
              <presentation-set
                v-else-if="screen.type === 'generic'"
                embedded="welcome-screen-light"
                :muteInfo="screen.data.muteInfo"
                :slides="[screen.data.slides].filter(Boolean)"
                @expand-element="(element) => {
                  pushScreen(group, {
                    type: 'node-list-info',
                    data: element
                  });
                }"
              />
              <component
                :is="$easyscreenIsMobile ? 'div' : 'scrollable'"
                v-else-if="screen.type === 'node-list-info'"
                max-height="100%"
              >
                <node-list-info
                  layout="welcome-screen-light"
                  v-bind="screen.data"
                  :with-scrollable-content="false"
                />
              </component>
              <div v-else>
                {{ $l10n("No template found!") }}
                {{ log("Template not found for this screen", screen) }}
              </div>
            </component>
          </div>
        </transition-group>
      </div>
    </transition-group>
  </div>
</template>

<style src="./welcome-screen-overview-light.less" lang="less"></style>

<script>
  import randomId from "@/lib/utils/random-id.js";
  import hasSlotMixin from "../core/mixins/has-slot.js";
  import GetComponent from "../core/get-component/get-component.vue";
  import Scrollable from "../core/scrollable/scrollable.vue";
  const PresentationSet = () => import("../presentation-set/presentation-set.vue");
  import NodeListInfo from "../node-list/node-list-info.vue";

  export default {
    name: "welcome-screen-overview-light",
    mixins: [hasSlotMixin],
    data() {
      let screensGroups = [];
      if (this.hasSlot("default")) {
        screensGroups.push({
          id: "default",
          screens: [{
            id: "screen" + randomId(),
            node: this.$slots.default[0]
          }]
        });
      }

      return {
        screensGroups: screensGroups
      };
    },
    computed: {
      allGroups() {
        return [{
          data: this.regularGroups,
          type: "regular"
        }, {
          data: this.fullscreenGroups,
          type: "fullscreen"
        }];
      },
      regularGroups() {
        return this.screensGroups.filter(group => {
          return group.isFullscreen !== true;
        }).map(group => {
          group.screens.forEach(screen => {
            if (screen.data && screen.data.slides) {
              screen.data.slides.widgets.forEach(widget => {
                if (widget.contentType === "TextPlusImageCT") {
                  widget.options.design = "classic";
                } else if (widget.contentType === "OpeningHoursCT") {
                  widget.options.tileColor = group.color;
                }
              });
            }
          });
          return group;
        });
      },
      fullscreenGroups() {
        return this.screensGroups.filter(group => {
          return group.isFullscreen === true;
        });
      }
    },
    methods: {
      replaceScreensGroup(group) {
        const groupId = group.id || ("group-" + randomId());
        const alredyExists = this.screensGroups.find(screensGroup => screensGroup.id === groupId);
        if (alredyExists)
          return;

        this.screensGroups = this.screensGroups.concat([{
          id: groupId,
          isFullscreen: group.isFullscreen,
          color: group.color,
          screens: [{
            id: "base-screen:" + groupId,
            ...group.content
          }]
        }]);

        /* Wait till animation will be finished. */
        setTimeout(async () => {
          this.screensGroups = this.screensGroups.map(group => {
            if (["default", groupId].includes(group.id) === false) {
              group.style = group.style || {};
              group.style.display = "none";

              group = { ...group };
            }

            return group;
          });

          await this.$nextTick();

          this.screensGroups = this.screensGroups.filter(group => {
            return ["default", groupId].includes(group.id);
          });
        }, 300);
      },
      popScreensGroup() {
        this.screensGroups = this.screensGroups.slice(0, this.screensGroups.length - 1);
      },
      pushScreen(group, screenData) {
        const foundGroup = this.screensGroups.find(_group => _group.id === group.id);
        if (!foundGroup)
          return console.warn("Group with given id not found!", group, screenData);

        const screen = Object.assign({
          id: "group-screen:" + group.id + "-screen:" + randomId()
        }, screenData);
        this.$emit("push-screen", () => this.popScreen(group, screen));

        foundGroup.screens = foundGroup.screens.concat(screen);
        this.$forceUpdate();
      },
      popScreen(group, screen) {
        const foundGroup = this.screensGroups.find(_group => _group.id === group.id);
        if (!foundGroup)
          return console.warn("Group with given id not found!", group, screen);

        foundGroup.screens = foundGroup.screens.filter(_screen => _screen.id !== screen.id);
        this.$forceUpdate();
      }
    },
    components: {
      GetComponent,
      Scrollable,
      PresentationSet,
      NodeListInfo
    }
  };
</script>
