<template>
  <div
    class="opening-hours-tile-content"
    :style="{
      backgroundImage: image ? `var(--background-overlay), url('${ image }')` : null
    }"
  >
    <h1
      v-if="title"
      class="opening-hours-tile-content--title"
    >
      {{ title }}
    </h1>

    <div class="opening-hours-tile-content--label">
      {{ openedTodayLabel || _l10n('Open today') }}:
      <span
        v-if="todayOpeningHours && todayOpeningHours.time"
        class="opening-hours-tile-content--opening-hours"
        :style="{
          color: color
        }"
      >
        {{ todayOpeningHours.time.from }} - {{ todayOpeningHours.time.to }}
      </span>
      <span
        v-else
      >
        {{ _l10n("Closed") }}
      </span>
    </div>

    <div class="opening-hours-tile-content--label">
      {{ serviceTimeLabel || _l10n('Service time') }}:
      <span
        v-if="todayOpeningHours && todayOpeningHours.serviceTime"
      >
        {{ todayOpeningHours.serviceTime.from }} - {{ todayOpeningHours.serviceTime.to }}
      </span>
      <span
        v-else
      >
        {{ _l10n("Closed") }}
      </span>
    </div>
  </div>
</template>

<style src="./opening-hours-tile-content.less" lang="less"></style>

<script>
  import moment from "moment";
  import l10n from "@/lib/localization/localization.js";

  export default {
    name: "opening-hours-tile-content",
    props: {
      title: String,
      color: String,
      image: String,
      /* Custom text for "opening hours" label (second table column). */
      openedTodayLabel: String,
      /* Custom text for "service time" label (third table column). */
      serviceTimeLabel: String,
      /* The library opening hours with type: OpeningHours[] */
      openingHours: {
        type: Array,
        default: () => ([])
      }
    },
    computed: {
      todayOpeningHours: {
        get() {
          const currentDate = moment().format("YYYY-MM-DD");

          return this.openingHours.find(openingHours => {
            return openingHours.date === currentDate;
          });
        }
      }
    },
    methods: {
      /* Proxy for localization function. */
      _l10n: l10n
    }
  };
</script>
