<template>
  <button
    :class="[
      'easyscreen-button',
      `easyscreen-button_${ corners }`,
      color ? `color-${ color }` : '',
      `${ size }-button`,
      modificator,
      { active }
    ]"
    :style="{ '--custom-color': this.customColor, '--custom-active-color': this.customActiveColor }"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<style lang="less" src="./button.less" />
<script>
  export default {
    name: "easyscreen-button",
    props: {
      /* The custom color for a button. */
      customColor: String,
      /* The custom active color for a button. */
      customActiveColor: String,
      /* The color of button background. */
      color: {
        type: String,
        default: "",
        validator: _color => [
          "",
          "primary",
          "secondary",
          "danger",
          "disabled",
          "black",
          "gray",
          "light-gray",
          "white",
          "outline-primary",
          "outline-secondary",
          "outline-danger",
          "outline-disabled",
          "outline-black",
          "outline-gray",
          "outline-light-gray",
          "outline-white",
          "custom",
          "outline-custom"
        ].includes(_color)
      },
      /* Size of the button. */
      size: {
        type: String,
        default: "small",
        validator: _size => ["small", "medium", "big"].includes(_size)
      },
      /* Active state of the button. */
      active: {
        type: Boolean,
        default: false
      },
      /* Disable state of the button. */
      disabled: {
        type: Boolean,
        default: false
      },
      /*
       * The type of button corners.
       *
       * - square - No rounding at corners
       * - round - 6px rounding
       * - pill - 100px rounding.
       */
      corners: {
        type: String,
        default: "pill",
        validator: _corners => ["square", "round", "pill"].includes(_corners)
      },
      /*
       * Button modificators:
       * - f-left - float button to left;
       * - f-right - float button to right;
       * - only-text - Disables the backround, color will be applied text;
       * - full-width - applies the 100% width to button;
       * - fixed-width - alias for "fixed-width_big";
       * - fixed-width_small - set the fixed width for button (smallest from that list);
       * - fixed-width_medium - set the fixed width for button (medium from that list);
       * - fixed-width_big - set the fixed width for button (bigger from that list);
       * - padding-small - applies the custom padding for button (small from that list);
       * - padding-medium - applies the custom padding for button (medium from that list);
       * - padding-big - applies the custom padding for button (big from that list).
       */
      modificator: {
        type: [String, Array],
        default: "",
        validator: _mod => {
          if (!_mod) {
            return true;
          }

          if (!Array.isArray(_mod)) {
            _mod = [_mod];
          }

          const availableModificators = [
            "",
            "f-left",
            "f-right",
            "only-text",
            "full-width",
            "fixed-width",
            "fixed-width_small",
            "fixed-width_medium",
            "fixed-width_big",
            "padding-small",
            "padding-medium",
            "padding-big",
            "fixed-height"
          ];

          return _mod.some(__mod => availableModificators.includes(__mod));
        }
      }
    }
  };
</script>
