import axios from "axios";
import { get, set } from "lodash";

/**
 * The configuration file with lodash get\set and naive access to properties.
 * @class Config
 *
 * @param {Object} config
 */
export default class Config {
  constructor(config) {
    this.update(config);
    this.configUrl = null;
  }

  /**
   * Fetch the config by url and apply it to current instance of Config.
   *
   * @param {String} url - The url to get the config file. Must support the
   * cors requests if the origin is differnt.
   */
  async fetch(url) {
    const response = await axios.get(url);
    this.update(response.data);
    this.configUrl = url;
  }

  async refetch() {
    if (!this.configUrl) {
      return;
    }

    return this.fetch(this.configUrl);
  }

  /**
   * Apply the config file to curretn instance of Config.
   *
   * @param {Object} config - Config to apply.
   */
  update(config) {
    if (!config) {
      return;
    }

    if (this._keys) {
      this._keys.forEach(key => {
        delete this[key];
      });
    }

    this._keys = Object.keys(config);
    Object.assign(this, config);
  }

  /**
   * Get the value using lodash `.get`.
   *
   * @param {String} path - Lodash path for `.get` method.
   * @param {*} defaultValue - The default for `.get` method.
   *
   * @returns {*} Traget value or default value or undefined.
   */
  get(path, defaultValue) {
    return get(this, path, defaultValue);
  }

  /**
   * Set the value using lodash `.set`.
   *
   * @param {String} path - Lodash path for `.set` method.
   * @param {*} value - The value to set.
   */
  set(path, value) {
    return set(this, path, value);
  }

  /**
   * Get the keys of config file.
   *
   * @returns {String[]}
   */
  keys() {
    return this._keys;
  }
}

