<template>
  <div :class="[
    'easyscreen-iframe',
    modificator ? `easyscreen-iframe_${ modificator }` : null
  ]">
    <loader
      v-if="withLoader && loaderShown"
      class="easyscreen-iframe--loader"
      type="in-place"
      :with-background="true"
      :design="design"
    />

    <div
      v-if="url"
      :class="[
        'easyscreen-iframe--wrapper',
        { 'easyscreen-iframe_with-overlay': overlay }
      ]"
    >
      <iframe
        class="easyscreen-iframe--frame"
        frameborder="0"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        :src="url"
        @load="loaderShown = false"
      ></iframe>
      <div
        v-if="overlay"
        class="easyscreen-iframe--overlay"
      ></div>
    </div>
    <h1 v-else class="easyscreen-iframe--alert">
      {{ $l10n("Url is empty") }}
    </h1>
  </div>
</template>

<style lang="less" src="./iframe.less"></style>

<script>
  import Loader from "../loader/loader.vue";

  export default {
    name: "easyscreen-iframe",
    watch: {
      url() {
        this.loaderShown = true;
      }
    },
    props: {
      /* Url of iframe. */
      url: String,
      /* Flag for using overlay which prevents the users actions. */
      overlay: {
        type: Boolean,
        default: true
      },
      /* The modification of iframe view. */
      modificator: {
        type: String,
        default: "",
        validator: _modificator => ["", "content-border"].includes(_modificator)
      },
      withLoader: Boolean,
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      }
    },
    data() {
      return {
        loaderShown: true
      };
    },
    components: {
      Loader
    }
  };
</script>
