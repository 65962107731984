<template>
  <div class="easyscreen-qr-code">
    <a v-if="$easyscreenIsMobile" class="easyscreen-qr-code--link" :href="qrCodeLink">
      {{ $l10n("Open in browser") }}
    </a>

    <div v-else class="easyscreen-qr-code--wrapper">
      <div class="easyscreen-qr-code--image" :style="style"></div>
    </div>
  </div>
</template>

<style src="./qr-code.less" lang="less"/>

<script>
  import * as querystring from "querystring";
  import * as d3 from "d3-timer";
  import jsQR from "jsqr";

  export default {
    name: "qr-code",
    props: {
      url: String,
      qrCode: String,
      size: Number
    },
    watch: {
      url() {
        this.getQrCodeUrl();
      }
    },
    computed: {
      qrCodeUrl() {
        const urlOptions = querystring.parse(window.location.href.split("#")[1]);
        let qrCodeQuery = {
          origin: window.location.origin,
          screen: urlOptions.id,
          lastUpdate: this.lastUpdate
        };

        if (this.url) {
          qrCodeQuery.url = this.url;
        } else if (this.qrCode) {
          qrCodeQuery.qrCode = this.qrCode;
        } else {
          return null;
        }

        return "/qr-code?" + querystring.stringify(qrCodeQuery);
      },
      style() {
        let style = {
          backgroundImage: `url("${ this.qrCodeUrl }")`
        };

        if (this.size) {
          style.width = this.size + "px";
          style.height = this.size + "px";
        }

        return style;
      }
    },
    data() {
      return {
        lastUpdate: Date.now(),
        qrCodeLink: ""
      };
    },
    /*
     * Refresh of the qr-code is required to prevent the server side data cleanup.
     * That required for proper redirect and tracking of the scans.
     */
    methods: {
      startRefreshInterval() {
        this.stopRefreshInterval();

        this.refreshInterval = d3.interval(() => {
          this.lastUpdate = Date.now();
        }, this.$easyscreenConfig.refreshQrCodeInterval);
      },
      stopRefreshInterval() {
        if (this.refreshInterval) {
          this.refreshInterval.stop();
          this.refreshInterval = null;
        }
      },
      async getQrCodeUrl() {
        const image = Object.assign(new Image(), { src: this.qrCodeUrl });
        await new Promise(resolve => { image.onload = resolve; });
        const context = Object.assign(document.createElement("canvas"), {
          width: image.width,
          height: image.height
        }).getContext("2d");
        context.imageSmoothingEnabled = false;
        context.drawImage(image, 0, 0);
        const imageData = context.getImageData(0, 0, image.width, image.height);
        const pixels = new Uint8ClampedArray(imageData.data.buffer);
        this.qrCodeLink = jsQR(pixels, image.width, image.height).data + "?type=qr-link";
      }
    },
    mounted() {
      this.startRefreshInterval();

      if (this.qrCodeUrl){
        this.getQrCodeUrl();
      }
    },
    beforeDestroy() {
      this.stopRefreshInterval();
    }
  };
</script>
