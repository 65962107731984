export default function(data) {
  return {
    contentType: "FullScreenCT",
    name: "easyscreen-iframe",
    height: 100,
    left: 0,
    top: 0,
    width: 100,
    options: {
      url: data.data.url,
      overlay: false
    }
  };
}
