export default [{
  name: "includes",
  path: "/includes",
  search: [
    "placement",
    "branchId",
    "departmentId",
    "locationId",
    "subLocationId",
    "branch",
    "department",
    "location",
    "subLocation",
    "dk5",
    "author",
    "dewey",
    "shelfmark"
  ],
  easyscreenProxy: true
}, {
  name: "viewTitle",
  path: "/view-title",
  search: ["viewId"],
  easyscreenProxy: true
}];
