export default function(data) {
  const wData = data.data;
  return {
    ...data.widgetPlacement,
    contentType: "VideoCT",
    name: "easyscreen-video",
    options: {
      ...wData,
      autoplay: wData.enableAutoplay,
      credits: wData.showCredit && wData.credit ? wData.credit : null,
      description: wData.showNote && wData.note ? wData.note : null,
      image: wData.thumbFile && wData.thumbFile.replace("thumbs", "original"),
      url: wData.video,
      poster: wData.showBubble ? "bubble" : null,
      volume: parseInt(wData.volumeLevel, 10)
    }
  };
}
