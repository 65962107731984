var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-modal',{class:[`vm_${ _vm.position }`, `vm_design-${ _vm.design }`],attrs:{"name":_vm.modalId,"shiftX":0,"shiftY":0,"width":0,"height":'auto',"click-to-close":_vm.clickToClose},on:{"before-open":(event) => {
    _vm.isShown = true;
    _vm.$emit('before-open', event);
  },"opened":(event) => _vm.$emit('opened', event),"before-close":(event) => {
    _vm.isShown = false;
    _vm.$emit('before-close', event);
  },"closed":(event) => _vm.$emit('closed', event)}},[_c('div',{staticClass:"v-modal--content",style:({
    minWidth: _vm.minWidth,
    minHeight: _vm.minHeight,
    maxWidth: _vm.maxWidth,
    maxHeight: _vm.maxHeight
  })},[(_vm.hasSlot('header') || _vm.hasSlot('content') || _vm.hasSlot('footer'))?_c('modal-layout',{attrs:{"hideEmptyContainer":_vm.hideEmptyContainer,"design":_vm.design}},[_c('template',{slot:"header"},[_vm._t("header")],2),_c('template',{slot:"content"},[_vm._t("content")],2),(_vm.hasSlot('footer'))?_c('template',{slot:"footer"},[_vm._t("footer")],2):_vm._e()],2):_vm._e(),(_vm.hasSlot('raw') || _vm.hasSlot('default'))?[_vm._t("raw"),_vm._t("default")]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }