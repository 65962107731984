var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['modal-layout', `modal-layout_design-${ _vm.design }`]},[(!_vm.hideEmptyContainer || (_vm.hideEmptyContainer && _vm.hasSlot('header')))?_c('div',{ref:"header",staticClass:"modal-layout--header",style:({
      height: _vm.headerHeight
    })},[_vm._t("header")],2):_vm._e(),(!_vm.hideEmptyContainer || (_vm.hideEmptyContainer && _vm.hasSlot('content')))?_c('div',{class:[
      'modal-layout--content',
      {
        'modal-layout--content_no-footer': _vm.hideEmptyContainer && !_vm.hasSlot('footer'),
        'modal-layout--content_no-header': _vm.hideEmptyContainer && !_vm.hasSlot('header')
      }
    ],style:({
      height: _vm._getContentHeight()
    })},[_vm._t("content")],2):_vm._e(),(!_vm.hideEmptyContainer || (_vm.hideEmptyContainer && _vm.hasSlot('footer')))?_c('div',{ref:"footer",staticClass:"modal-layout--footer",style:({
      height: _vm.footerHeight
    })},[_vm._t("footer")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }