import Vue from "vue";
import Vuex from "vuex";
import materialsCartStore from "./components/materials-cart/materials-cart-store.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    safetyModeEnabled: false
  },
  mutations: {
    SET_SAFETY_MODE(state, enabled) {
      state.safetyModeEnabled = enabled;
    }
  },
  actions: {
    setSafetyMode({ commit }, enabled) {
      commit("SET_SAFETY_MODE", enabled);
    }
  },
  modules: {
    "materialsCart": materialsCartStore
  }
});
