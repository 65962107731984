var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"easyscreen-video-native"},[(_vm.credits)?_c('div',{staticClass:"easyscreen-video-native--credits"},[_c('span',{staticClass:"easyscreen-video-native--credits-label"},[_vm._v(_vm._s(_vm._l10n("Credit by"))+":")]),_vm._v(" "+_vm._s(_vm.credits)+" ")]):_vm._e(),(_vm.showPoster)?_c('video-poster',{attrs:{"type":_vm.poster,"image":_vm.image,"description":_vm.description},on:{"play":_vm.play}}):[_c('video',{ref:"videoPlayer",staticClass:"easyscreen-video-native--player",attrs:{"poster":_vm.image},domProps:{"muted":_vm.muted},on:{"play":(event) => {
        _vm.isPlaying = true;
        _vm.$emit('playing', event);
      },"pause":(event) => {
        _vm.isPlaying = false;
        _vm.$emit('paused', event);
      },"error":(event) => {
        _vm.isPlaying = false;
        _vm.$emit('error', event);
      }}},[_c('source',{attrs:{"src":_vm.url}})]),(_vm.isPlaying && _vm.isMuted)?_c('div',{staticClass:"easyscreen-video-native--button easyscreen-video-native--unmute-button",on:{"click":_vm.unmute}},[_c('i',{staticClass:"fa fa-volume-up"})]):_vm._e(),(!_vm.isPlaying)?_c('div',{staticClass:"easyscreen-video-native--button easyscreen-video-native--play-button",on:{"click":_vm.play}},[_c('i',{staticClass:"fa fa-play"})]):_vm._e(),(_vm.overlay)?_c('div',{staticClass:"easyscreen-video-native--overlay",on:{"click":() => {
        if (!_vm.isPlaying) {
          _vm.play();
        } else {
          _vm.unmute();
        }
      }}}):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }