/**
 * Helper for get html element height.
 *
 * @param {HTMLElement} element
 *
 * @returns {Number}
 */
export default function htmlElementHeight(element) {
  if (!element) {
    return 0;
  }

  const elementStyles = window.getComputedStyle(element);
  const isBorderBox = elementStyles.boxSizing === "border-box";

  return [
    isBorderBox ? null : "padding-top",
    "margin-top",
    "height",
    "margin-bottom",
    isBorderBox ? null : "padding-bottom"
  ].filter(Boolean).reduce((height, style) => {
    return height + (parseInt(elementStyles[style], 10) || 0);
  }, 0);
}
