<template>
  <div 
    :class="[
      'video-poster',
      `video-poster_${ type }`
    ]"
    :style="{
      backgroundImage: `var(--background-overlay), url('${ image }')`
    }"
  >
    <div class="video-poster--content">
      <div class="video-poster--desciption">
        <svg
          v-if="type === 'bubble'"

          class="video-poster--bubble"
          xmlns:svg="http://www.w3.org/2000/svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 131.51885 135.3687"
          version="1.1"
        >
          <g transform="translate(-6.1375803,-20.615903)">
            <!-- eslint-disable-next-line -->
            <path d="m 41.39264,20.615903 90.9211,0 c 2.95984,0 5.34269,1.84249 5.34269,4.13114 l 0,127.106417 c 0,2.28865 -2.38285,4.13115 -5.34269,4.13115 l -90.9211,0 c -2.95985,0 -5.34269,-1.8425 -5.34269,-4.13115 l 0,-50.6848 L 6.1375803,71.821353 36.55503,79.245523 36.04995,24.747043 c -0.0212,-2.28855 2.38284,-4.13114 5.34269,-4.13114 z" />
          </g>
        </svg>
        <span class="video-poster--text">
          <span class="video-poster--play-button">
            <i
              class="video-poster--play-button-icon fal fa-play-circle"
              @click="(event) => $emit('play', event)"
            >
            </i>
            <span
              v-if="type === 'bubble'"
              class="video-poster--play-button-label"
            >
              {{ _l10n("Press to start video") }}
            </span>
          </span>
          {{ description }}
        </span>
      </div>
    </div>
  </div>
</template>

<style src="./video-poster.less" lang="less"></style>

<script>
  import l10n from "@/lib/localization/localization.js";

  export default {
    name: "video-poster",
    props: {
      /* The poster image. */
      image: String,
      /* The video description. */
      description: String,
      /*
       * Type of the poster. One of: 'default' or 'bubble'.
       *  - default - The centered text with the play button above the text.
       *  - bubble - The square bubble with text inside and play button below the text.
       */
      type: {
        type: String,
        default: "default",
        validator: _type => ["default", "bubble"].includes(_type)
      }
    },
    methods: {
      /* Proxy for localization function. */
      _l10n: l10n
    }
  };
</script>
