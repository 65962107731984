export default function(data) {
  const wData = data.data;
  return {
    ...data.widgetPlacement,
    height: wData.height,
    contentType: "WebContent",
    name: "easyscreen-iframe",
    options: {
      url: wData.url,
      modificator: "content-border",
      overlay: false
    }
  };
}
