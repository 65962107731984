var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'node-list-tile-content',
    `node-list-tile-content_design-${ _vm.design }`,
    { 'node-list-tile-content_two-elements': _vm.amountOfElements === 2 }
  ],style:({ '--special-color': _vm.color })},[(_vm.title)?_c('h1',{staticClass:"node-list-tile-content--title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_vm._l((_vm.arrayOfElements),function(element){return _c('div',{key:element.title + ' ' + element.date,class:[
      'node-list-tile-content--element',
      `node-list-tile-content--element_${ element.type }`
    ],style:({
      backgroundImage: element.image ? `var(--background-overlay), url('${ element.image }')` : null,
      '--element-color': _vm.color
    }),on:{"click":() => _vm.$emit('open-element', element)}},[(element.location && element.type === 'event')?_c('h2',{staticClass:"node-list-tile-content--element-location"},[_vm._v(" "+_vm._s(_vm._adjustLocation(element.location))+" ")]):_vm._e(),(element.location && _vm._isAvailableWayfinderLocation(element.location))?_c('i',{staticClass:"node-list-tile-content--element-map-marker fal fa-map-marker-alt",on:{"click":function($event){$event.stopPropagation();return (() => _vm.$emit('open-wayfinder', _vm._generateWayfinderUrlByLocation(element.location))).apply(null, arguments)}}}):_vm._e(),_c('h1',{staticClass:"node-list-tile-content--element-title"},[_vm._v(" "+_vm._s(_vm._cutText(element.title, 30))+" ")]),(element.type === 'event')?_c('div',{staticClass:"node-list-tile-content--element-date"},[_c('div',[_vm._v(" "+_vm._s(_vm._getElementDate(element))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm._getElementTime(element))+" ")])]):(element.type === 'news')?_c('p',{staticClass:"node-list-tile-content--element-teaser"},[_vm._v(" "+_vm._s(_vm._cutText(element.teaser, 85))+" ")]):_vm._e()])}),(_vm.design === 'light')?_c('button',{staticClass:"node-list-tile-content--more-content-button",on:{"click":function($event){return _vm.$emit('open-tile')}}},[_vm._v(" "+_vm._s(_vm.$l10n("See all events"))+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }