import moment from "moment";

function LibraryEventsDateFormat(data) {
  const timezoneOffset = (new Date()).getTimezoneOffset() * 60 * 1000;
  let items = {};

  Object.keys(data).forEach(rawKey => {
    let key;
    if (rawKey.length === 10) {
      // ES-2000: Support for date and utix timestamp key support.
      key = new Date(parseInt(rawKey + "000", 10)).toString();
    } else {
      key = moment(rawKey, "DD/MM-YYYY").toString();
    }

    items[key] = data[rawKey].map(item => {
      item.original = item.original || {
        from: parseInt(item.from + "000", 10) + timezoneOffset,
        to: parseInt(item.to + "000", 10) + timezoneOffset
      };

      item.from = (new Date(item.original.from)).toString();
      item.to = (new Date(item.original.to)).toString();
      item.date = moment(new Date(item.original.from)).format("DD/MM-YYYY");

      return item;
    });
  });

  return items;
}

function LibraryEventsToSingleList(libraryEventsItems) {
  const timezoneOffset = (new Date()).getTimezoneOffset() * 60 * 1000;
  let singleListItems = [];

  Object.keys(libraryEventsItems).forEach(key => {
    libraryEventsItems[key].forEach(item => {
      (item.takes_place || []).forEach(date => {

        date = moment(date, "DD/MM-YYYY HH.mm") || new Date(parseInt(date, 10) * 1000 + timezoneOffset);
        if (!date.isValid()) {
          date = null;
        }

        const exists = singleListItems.some(_item => {
          return _item.date - date === 0 && _item.title === item.title;
        });

        if (!exists) {
          singleListItems.push({
            date: date,
            image: item.image,
            title: item.title,
            teaser: item.lead,
            takes_place: item.takes_place
          });
        }
      });
    });
  });

  return singleListItems.sort((a, b) => a.date - b.date);
}

export default function(data) {
  var wData = data.data;
  var widget = {
    ...data.widgetPlacement,
    contentType: "LibraryEventsCT",
    name: "library-events-timetable",
    welcomeScreenType: "library-events",
    withBackground: false,
    options: {
      items: LibraryEventsDateFormat(wData.items),
      pageDuration: parseInt(wData.page_duration, 10),
      autoAnimation: true
    }
  };

  if (wData.display_type === "movie_flow") {
    widget.name = "library-events-list";
    widget.options.cinemaItems = widget.options.items;
    widget.options.items = LibraryEventsToSingleList(widget.options.items);
    widget.options.subType = "cinema";
    widget.options.title = wData.title;
    widget.options.itemsLayout = widget.height === 100 ? "ten-elements" : "eight-elements";
  }

  return widget;
}
