<template>
  <v-modal
    :name="modalId"
    :shiftX="0"
    :shiftY="0"
    :width="0"
    :height="'auto'"
    :click-to-close="clickToClose"
    @before-open="(event) => {
      isShown = true;
      $emit('before-open', event);
    }"
    @opened="(event) => $emit('opened', event)"
    @before-close="(event) => {
      isShown = false;
      $emit('before-close', event);
    }"
    @closed="(event) => $emit('closed', event)"
    :class="[`vm_${ position }`, `vm_design-${ design }`]"
  >
    <div class="v-modal--content" :style="{
      minWidth: minWidth,
      minHeight: minHeight,
      maxWidth: maxWidth,
      maxHeight: maxHeight
    }">
      <modal-layout
        v-if="hasSlot('header') || hasSlot('content') || hasSlot('footer')"
        :hideEmptyContainer="hideEmptyContainer"
        :design="design"
      >
        <template slot="header">
          <slot name="header"></slot>
        </template>
        <template slot="content">
          <slot name="content"></slot>
        </template>
        <template v-if="hasSlot('footer')" slot="footer">
          <slot name="footer"></slot>
        </template>
      </modal-layout>

      <template v-if="hasSlot('raw') || hasSlot('default')">
        <slot name="raw"></slot>
        <slot></slot>
      </template>
    </div>
  </v-modal>
</template>

<style lang="less" src="./blank.less" />
<script>
  import Vue from "vue";
  /*
   * Modals system.
   * https://www.npmjs.com/package/vue-js-modal
   */
  import VModal from "vue-js-modal";
  import ModalLayout from "./layout.vue";

  import { isFunction } from "lodash";
  import hasSlotMixin from "../mixins/has-slot.js";
  import randomId from "../../../lib/utils/random-id.js";

  Vue.use(VModal, { componentName: "v-modal" });

  export default {
    name: "modal-blank",
    mixins: [hasSlotMixin],
    props: {
      /*
       * The type of modal:
       * - default - The modal is shown in center of screen (as regular alert and confirm modal);
       * - fullscreen - The modal is shown at fullscreen (backdrop is not visible).
       */
      type: {
        type: String,
        default: "default",
        validator: _type => ["default", "fullscreen"].includes(_type)
      },
      /* Flag for hide the "header", "content" or\and "footer" contatiner if there no content. */
      hideEmptyContainer: Boolean,
      /* The min width of modal. */
      minWidth: {
        type: [Number, String],
        default: "705px"
      },
      /* The min height of modal. */
      minHeight: {
        type: [Number, String],
        default: "250px"
      },
      /* The max width of modal. */
      maxWidth: [Number, String],
      /* The max height of modal. */
      maxHeight: [Number, String],
      /* Flag for close modal by clicking on backdrop. */
      clickToClose: {
        type: Boolean,
        default: true
      },
      /* The type of modal position: fixed or absolute (switched the css position for root container). */
      position: {
        type: String,
        default: "fixed",
        validator: _position => ["fixed", "absolute"].includes(_position)
      },
      /* Close the popup when standby is started. */
      autoClose: {
        type: Boolean,
        default: true
      },
      /* The global reskin. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      }
    },
    data() {
      return {
        modalId: randomId(),
        isShown: false
      };
    },
    methods: {
      /**
       * Show the current modal. Returns promise when callback is not provided.
       * @async
       *
       * @param {Function} [callback] - The show callback, will be called when modal is opened.
       */
      show(callback) {
        if (!callback) {
          return new Promise(resolve => this.show(resolve));
        }

        if (this.isShown) {
          if (isFunction(callback)) {
            callback();
          }

          return;
        }

        if (isFunction(callback)) {
          this.$once("opened", callback);
        }

        this.$modal.show(this.modalId);
      },
      /**
       * Hide the current modal. Returns promise when callback is not provided.
       * @async
       *
       * @param {Function} [callback] - The hide callback, will be called when modal is closed.
       */
      hide(callback) {
        if (!callback) {
          return new Promise(resolve => this.hide(resolve));
        }

        if (!this.isShown) {
          if (isFunction(callback)) {
            callback();
          }

          return;
        }

        if (isFunction(callback)) {
          this.$once("closed", callback);
        }

        this.$modal.hide(this.modalId);
      }
    },
    screenStandby() {
      if (this.autoClose) {
        this.hide();
      }
    },
    components: {
      "modal-layout": ModalLayout
    }
  };
</script>
