<template>
  <div
    :class="[
      'node-list-tile-content',
      `node-list-tile-content_design-${ design }`,
      { 'node-list-tile-content_two-elements': amountOfElements === 2 }
    ]"
    :style="{ '--special-color': color }"
  >
    <h1
      v-if="title"
      class="node-list-tile-content--title"
    >
      {{ title }}
    </h1>

    <div
      v-for="element in arrayOfElements"
      :key="element.title + ' ' + element.date"
      :class="[
        'node-list-tile-content--element',
        `node-list-tile-content--element_${ element.type }`
      ]"
      :style="{
        backgroundImage: element.image ? `var(--background-overlay), url('${ element.image }')` : null,
        '--element-color': color
      }"
      @click="() => $emit('open-element', element)"
    >
      <h2
        v-if="element.location && element.type === 'event'"
        class="node-list-tile-content--element-location"
      >
        {{ _adjustLocation(element.location) }}
      </h2>
      <i
        v-if="element.location && _isAvailableWayfinderLocation(element.location)"
        class="node-list-tile-content--element-map-marker fal fa-map-marker-alt"
        @click.stop="() => $emit('open-wayfinder', _generateWayfinderUrlByLocation(element.location))"
      ></i>

      <h1 class="node-list-tile-content--element-title">
        {{ _cutText(element.title, 30) }}
      </h1>

      <div
        v-if="element.type === 'event'"
        class="node-list-tile-content--element-date"
      >
        <div>
          {{ _getElementDate(element) }}
        </div>
        <div>
          {{ _getElementTime(element) }}
        </div>
      </div>
      <p
        v-else-if="element.type === 'news'"
        class="node-list-tile-content--element-teaser"
      >
        {{ _cutText(element.teaser, 85) }}
      </p>
    </div>
    <button
      v-if="design === 'light'"
      class="node-list-tile-content--more-content-button"
      @click="$emit('open-tile')"
    >
      {{ $l10n("See all events") }}
    </button>
  </div>
</template>

<style src="./node-list-tile-content.less" lang="less"></style>

<script>
  import moment from "moment";
  import cutText from "@/lib/utils/cut-text.js";
  import l10n from "@/lib/localization/localization.js";

  import wayfinderMixin from "../../core/mixins/wayfinder.js";

  export default {
    name: "node-list-tile-content",
    mixins: [wayfinderMixin],
    props: {
      title: String,
      elements: {
        type: Array,
        default: () => ([])
      },
      color: String,
      subType: {
        type: String,
        default: "",
        validator: _subType => [
          "",
          "cinema",
          "for-game",
          "welcome-screen-modifer",
          "cinema welcome-screen-modifer",
          "for-game welcome-screen-modifer"
        ].includes(_subType)
      },
      amountOfElements: {
        type: Number,
        default: 3,
        validator: _amountOfElements => _amountOfElements >= 0
      },
      design: {
        type: String,
        default: "classic"
      }
    },
    computed: {
      arrayOfElements: {
        get() {
          return this.elements.slice(0, this.amountOfElements);
        }
      }
    },
    methods: {
      /* Proxy for `cutText` method. */
      _cutText: cutText,
      /**
       * Add "..." at the location if there more than 3 parts.
       *
       * @param {String} location - The location with comma delimiter.
       *
       * @return {String} The adjusted location.
       */
      _adjustLocation(location) {
        if (!location) {
          return "";
        }

        location = location.split(",");
        if (location.length > 2) {
          location = location.slice(0, 1).join(", ") + "...";
        } else {
          location = location.join(", ");
        }

        return location;
      },
      /**
       * Get date from element based on active skin.
       *
       * @param {Object} element
       * @param {String} element.date - Element date in `new Date` compatible format.
       *
       * @returns {String} Date in format "dddd [d.] DD MMMM" .
       */
      _getElementDate(element) {
        return moment(element.date).format("dddd [d.] DD MMMM");
      },
      /**
       * Get time from element based on active skin.
       *
       * @param {Object} element
       * @param {String} element.date - Element date in `new Date` compatible format.
       *
       * @returns {String} Date in format "Kl. HH.mm" or `All day`.
       */
      _getElementTime(element) {
        const time = moment(element.date).format("HH:mm");
        if (time === "00:00") {
          return l10n("All day");
        }

        return l10n("Kl.") + " " + time;
      }
    }
  };
</script>
