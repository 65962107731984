var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"welcome-screen-overview",style:({
    borderRightColor: _vm.color
  })},[_c('transition-group',{staticClass:"welcome-screen-overview--screens",attrs:{"name":"zoom-slide","tag":"div"}},_vm._l((_vm.screens),function(screen,screenIndex){return _c('div',{key:screen.id,class:[
        'welcome-screen-overview--screen',
        {
          'welcome-screen-overview--screen_no-padding': screen.padding === false,
          'welcome-screen-overview--screen_hidden': screenIndex !== _vm.screens.length - 1
        },
        screen.className
      ],style:(screen.style)},[_c('div',{staticClass:"welcome-screen-overview--screen-content"},[(screen.type === 'iframe')?_c('easyscreen-iframe',{attrs:{"url":screen.data.url,"overlay":screen.data.overlay}}):(screen.type === 'generic')?_c('presentation-set',{attrs:{"muteInfo":screen.data.muteInfo,"slides":[screen.data.slides].filter(Boolean)},on:{"expand-element":(element) => {
            _vm.pushScreen({
              type: 'node-list-info',
              data: element
            });
          }}}):(screen.type === 'node-list-info')?_c('node-list-info',{attrs:{"layout":"welcome-screen","type":screen.data.type,"date":screen.data.date,"date-to":screen.data.dateTo,"location":screen.data.location,"price":screen.data.price,"image":screen.data.image,"title":screen.data.title,"es-teaser":screen.data.esTeaser,"text":screen.data.text,"lead":screen.data.lead,"single":screen.data.single}}):(screen.type === 'node-list-cinema-info')?_c('node-list-cinema-info',{attrs:{"items":screen.data.items}}):(screen.type === 'welcome-screen-video')?_c('div',{staticClass:"welcome-screen-overview--video"},[(screen.data.title)?_c('h1',{staticClass:"welcome-screen-overview--video-title",style:({ color: screen.data.titleColor })},[_vm._v(_vm._s(screen.data.title))]):_vm._e(),(screen.data.text)?_c('p',{staticClass:"welcome-screen-overview--video-text"},[_vm._v(_vm._s(screen.data.text))]):_vm._e(),_c('easyscreen-video',{attrs:{"src":screen.data.src}})],1):_vm._e(),_c('div',{staticClass:"welcome-screen-overview--screen-links"},_vm._l(((screen.links || [])),function(link,linkIndex){return _c('easyscreen-button',{key:`screen[${ screenIndex }].link[${ linkIndex }]:${ link.title }`,staticClass:"welcome-screen-overview--screen-link",style:({ backgroundColor: _vm.color }),attrs:{"size":"small","modificator":['full-width', 'padding-big']},nativeOn:{"click":function($event){return (() => {
              let screenData = link.screenData
              if (!screenData) {
                screenData = {
                  type: 'generic',
                  links: link.links,
                  data: {
                    muteInfo: true,
                    slides: link.slides
                  }
                };
              }

              _vm.pushScreen(screenData);
            }).apply(null, arguments)}}},[(link.icon)?_c('i',{class:link.icon}):_vm._e(),_vm._v(" "+_vm._s(link.title)+" ")])}),1)],1)])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }