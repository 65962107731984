<template>
  <div
    class="text-and-image-tile-content"
    :style="{
      backgroundImage: image ? `var(--background-overlay), url('${ image }')` : null
    }"
  >
    <h1
      v-if="title"
      class="text-and-image-tile-content--title"
    >
      {{ title }}
    </h1>
    <div class="text-and-image-tile-content--text ck-editor" v-html="text"></div>
  </div>
</template>

<style src="./text-and-image-tile-content.less" lang="less"></style>
<style src="../../core/ck-editor.less" lang="less"></style>

<script>
  export default {
    name: "text-and-image-tile-content",
    props: {
      title: String,
      text: String,
      image: String
    }
  };
</script>
