import Vue from "vue";

const eventBus = new Vue();

/**
 * The event fires when `this._preventHammerjs` is called (depends on implementation after mixin usage).
 *
 * @event hammer-tools#prevent-hammerjs
 * @type {Object} instanceWithHammerjs - Instance of hammerjs which prevents events for other instances.
 */

/**
 * The event fires when `this._allowHammerjs` is called (depends on implementation after mixin usage).
 *
 * @event hammer-tools#allow-hammerjs
 * @type {Object} instanceWithHammerjs - Instance of hammerjs which allows events for other instances.
 */

export default {
  props: {
    /* Flag for allow the nested DnD prevent. In case when slider will be a part of another slide, see the materials-list (default: true). */
    preventNestedDragAndDrop: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      hammerjsPreventedBy: []
    };
  },
  methods: {
    /**
     * Get the prevented status. The hammerjs prevented `hammerjsPreventedBy` - have at least one element.
     *
     * @returns {Boolean}
     */
    _isHammerjsPrevented() {
      return this.hammerjsPreventedBy.length !== 0;
    },
    /**
     * Add the instance of hammerjs which prevents events for current instance.
     *
     * @param {Object} instanceWithHammerjs - Preventing instance.
     */
    _setHammerjsPrevented(instanceWithHammerjs) {
      if (instanceWithHammerjs === this) {
        return;
      }

      this.hammerjsPreventedBy = this.hammerjsPreventedBy.concat([instanceWithHammerjs]);
    },
    /**
     * Remove the instance of hammerjs which prevents events for current instance.
     */
    _setHammerjsAllowed(instanceWithHammerjs) {
      if (instanceWithHammerjs === this) {
        return;
      }

      this.hammerjsPreventedBy = this.hammerjsPreventedBy.filter(preventBy => preventBy !== instanceWithHammerjs);
    },
    /**
     * Fires the `prevent-hammerjs` event with current instance.
     * @fires hammer-tools#prevent-hammerjs
     */
    _preventHammerjs() {
      eventBus.$emit("prevent-hammerjs", this);
    },
    /**
     * Fires the `allow-hammerjs` event with current instance.
     * @fires hammer-tools#allow-hammerjs
     */
    _allowHammerjs() {
      eventBus.$emit("allow-hammerjs", this);
    }
  },
  mounted() {
    eventBus.$on("prevent-hammerjs", this._setHammerjsPrevented);
    eventBus.$on("allow-hammerjs", this._setHammerjsAllowed);
  },
  beforeDestroy() {
    eventBus.$off("prevent-hammerjs", this._setHammerjsPrevented);
    eventBus.$off("allow-hammerjs", this._setHammerjsAllowed);

    this._allowHammerjs();
  }
};
