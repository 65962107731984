export default [
  {
    //tile one (opening hours) - red
    style: {
      top: "60px",
      left: "60px",
      width: "420px",
      height: "302px"
    },
    line: [486, 835, 266, 361]
  },
  {
    // tile two (text plus image) - yellow
    style: {
      top: "60px",
      right: "60px",
      width: "420px",
      height: "302px"
    },
    line: [598, 835, 816, 364]
  },
  {
    // tile three (text plus image) - blue
    style: {
      top: "420px",
      left: "330px",
      width: "420px",
      height: "302px"
    },
    line: [538, 823, 538, 723]
  },
  {
    // tile fourth (node list) - green
    style: {
      top: "778px",
      left: "60px",
      width: "260px",
      height: "644px"
    },
    line: [424, 1033, 319, 1102]
  },
  {
    // tile five (node list) - aquamarine
    style: {
      top: "778px",
      right: "60px",
      width: "260px",
      height: "644px"
    },
    line: [655, 1031, 759, 1102]
  },
  {
    // tile six (text plus image) - light blue
    style: {
      top: "1477px",
      left: "60px",
      width: "260px",
      height: "302px"
    },
    line: [487, 1086, 190, 1477]
  },
  {
    // tile seven (texxt plus image) - deep yellow
    style: {
      top: "1477px",
      left: "410px",
      width: "260px",
      height: "302px"
    },
    line: [540, 1085, 540, 1477]
  },
  {
    // tile eight (texxt plus image) - depp blue
    style: {
      top: "1477px",
      right: "60px",
      width: "260px",
      height: "302px"
    },
    line: [596, 1085, 890, 1477]
  }
];
