import * as querystring from "querystring";

export default function head(titleText, slides, currentSlide) {
  var head = [];
  var title = {
    name: "presentation-set-title",
    height: 13,
    top: 5,
    left: 0,
    options: {
      title: titleText
    }
  };

  if (window.ES_SKIN.isHorsholm || window.ES_SKIN.isKrsbib) {
    title.top = 0;
    title.height = 15;
  }

  const titleBoundaries = {
    top: title.top,
    bottom: title.top + title.height,
    left: title.left,
    right: 100
  };

  const hasCollisions = currentSlide.widgets.some(widget => {
    const widgetBoundaries = {
      top: widget.top,
      bottom: widget.top + (widget.height || 0),
      left: widget.left,
      right: widget.left + (widget.width || 0)
    };

    return titleBoundaries.top < widgetBoundaries.bottom
    && titleBoundaries.bottom > widgetBoundaries.top
    && titleBoundaries.left < widgetBoundaries.right
    && titleBoundaries.right > widgetBoundaries.left;
  });

  if (!window.ES_SKIN.isSlagelse && !hasCollisions) {
    head.push(title);
  }

  const urlOptions = querystring.parse(window.location.href.split("#")[1]);
  if ((urlOptions.withSwitcher != null || urlOptions.pageId !== undefined) && urlOptions.withSwitcher !== "false") {
    head.push({
      name: "presentation-set-switcher",
      //type: "psWidgets/switcher/index",
      height: 7,
      width: 55,
      top: 0,
      left: 35,
      options: {
        slides: slides.map(function(s) {
          return s.slideData.overrideTitle || s.slideData.slideName;
        })
      }
    });
  }

  return head;
}
