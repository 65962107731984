/* npm packages */
/* lodash - utils for manipulate object properties and helpers. */
import { extend, isObjectLike } from "lodash";

function parseXMLHttpRequest(XMLHttpRequest) {
  if (!XMLHttpRequest) {
    return {};
  }

  let status = XMLHttpRequest.status;
  let url = XMLHttpRequest.responseURL;
  let text = XMLHttpRequest.responseText;
  let json;

  try {
    json = JSON.parse(text);
  } catch (err) {} /* eslint-disable-line */

  let parsed = { status, url, text, json };
  Object.keys(parsed).forEach(key => {
    if (!parsed[key]) {
      delete parsed[key];
    }
  });

  return parsed;
}
/**
 * Constructor for common request error.
 *
 * @param {object} [options] - Error options.
 * @param {string} [options.code="INTERNAL_ERROR"] - The code of error.
 * @param {number} [options.status=500] - The http status code of error.
 * @param {string} [options.message="Can't make request to provider server"] - Error message.
 * @param {*} [options.*] - Any paramater which should be added to error instance.
 */
export function RequestError(options) {
  if (!options) {
    options = {};
  }

  let response = parseXMLHttpRequest(options.ajaxRequest);

  this.name = this.constructor.name;

  this.code = "PROVIDER_ERROR";
  this.status = 500;
  this.message = "Can't make request to provider server";

  this.err = options.err || response.json;
  this.statusCode = options.statusCode || response.status;
  this.url = options.url || response.url;
  this.responseText = response.text;

  extend(this, options);
}

/**
 * Constructor for json parse error.
 *
 * @param {object} [options] - Error options.
 * @param {string} [options.code="INTERNAL_ERROR"] - The code of error.
 * @param {number} [options.status=500] - The http status code of error.
 * @param {string} [options.message="Can't parse JSON data returned by provider server"] - Error message.
 * @param {*} [options.*] - Any paramater which should be added to error instance.
 */
export function JsonParseError(options) {
  if (!options) {
    options = {};
  }

  let response = parseXMLHttpRequest(options.ajaxRequest);

  this.name = this.constructor.name;

  this.code = "INTERNAL_ERROR";
  this.status = options.statusCode || response.status || 500;
  this.message = "Can't parse JSON data returned by provider server";

  this.err = options.err || response.json;
  this.url = options.url || response.url;
  this.responseText = response.text;

  extend(this, options);
}

/**
 * Constructor for provider error.
 *
 * @param {object} [options] - Error options.
 * @param {string} [options.code="PROVIDER_ERROR"] - The code of error.
 * @param {number} [options.status=500] - The http status code of error.
 * @param {string} options.message - Error message.
 * @param {*} [options.*] - Any paramater which should be added to error instance.
 */
export function ProviderError(options) {
  if (!options) {
    options = {};
  }

  let response = parseXMLHttpRequest(options.ajaxRequest);

  this.name = this.constructor.name;

  this.code = "PROVIDER_ERROR";
  this.status = options.statusCode || response.status || 500;
  this.message = options.message;

  this.err = options.err || response.json;
  this.url = options.url || response.url;
  this.responseText = response.text;

  extend(this, options);
}

/**
 * Constructor for not found error.
 *
 * @param {object} [options] - Error options.
 * @param {string} [options.code="NOT_FOUND_ERROR"] - The code of error.
 * @param {number} [options.status=404] - The http status code of error.
 * @param {string} [options.message="This route is not available"] - Error message.
 * @param {*} [options.*] - Any paramater which should be added to error instance.
 */
export function NotFoundError(options) {
  if (!options) {
    options = {};
  }

  let response = options.ajaxRequest ? parseXMLHttpRequest(options.ajaxRequest) : {};

  this.name = this.constructor.name;

  this.code = "NOT_FOUND_ERROR";
  this.status = options.statusCode || response.status || 404;
  this.message = options.message || "This route is not available";

  this.err = options.err || response.json;
  this.url = options.url || response.url;
  this.responseText = response.text;

  extend(this, options);
}

/**
 * Constructor for any internal error.
 *
 * @param {object} [options] - Error options.
 * @param {string} [options.code="INTERNAL_ERROR"] - The code of error.
 * @param {number} [options.status=500] - The http status code of error.
 * @param {string} [options.message="Internal error"] - Error message.
 * @param {*} [options.*] - Any paramater which should be added to error instance.
 */
export function InternalError(options) {
  if (!options) {
    options = {};
  }

  let response = options.ajaxRequest ? parseXMLHttpRequest(options.ajaxRequest) : {};

  this.name = this.constructor.name;

  this.code = "INTERNAL_ERROR";
  this.status = options.statusCode || response.status || 500;
  this.message = options.message || "Internal error";

  this.err = options.err || response.json;
  this.url = options.url || response.url;
  this.responseText = response.text;

  extend(this, options);
}

/**
 * Constructor for authorization error.
 *
 * @param {object} [options] - Error options.
 * @param {string} [options.code="AUTH_ERROR"] - The code of error.
 * @param {number} [options.status=403] - The http status code of error.
 * @param {string} [options.message="Wrong auth parameters"] - Error message.
 * @param {*} [options.*] - Any paramater which should be added to error instance.
 */
export function AuthError(options) {
  if (!options) {
    options = {};
  }

  let response = parseXMLHttpRequest(options.ajaxRequest);

  this.name = this.constructor.name;

  this.code = "AUTH_ERROR";
  this.status = options.statusCode || response.status || 403;
  this.message = "Wrong auth parameters";

  this.err = options.err || response.json;
  this.url = options.url || response.url;
  this.responseText = response.text;

  extend(this, options);
}

/**
 * Constructor for request timeout error.
 *
 * @param {object} [options] - Error options.
 * @param {string} [options.code="TIMEOUT_ERROR"] - The code of error.
 * @param {number} [options.status=500] - The http status code of error.
 * @param {string} [options.message="Request timedout"] - Error message.
 * @param {*} [options.*] - Any paramater which should be added to error instance.
 */
export function TimeoutError(options) {
  if (!options) {
    options = {};
  }

  let response = parseXMLHttpRequest(options.ajaxRequest);

  this.name = this.constructor.name;

  this.code = "TIMEOUT_ERROR";
  this.status = options.statusCode || response.status || 500;
  this.message = "Request timedout";

  this.err = options.err || response.json;
  this.url = options.url || response.url;
  this.responseText = response.text;

  extend(this, options);
}

/**
 * Constructor for any validation error.
 *
 * @param {object} [options] - Error options.
 * @param {string} [options.code="VALIDATION_ERROR"] - The code of error.
 * @param {string} [options.message="Validation error"] - Error message.
 * @param {*} [options.*] - Any paramater which should be added to error instance.
 */
export function ValidationError(options) {
  if (!options) {
    options = {};
  }

  this.name = this.constructor.name;

  this.code = "VALIDATION_ERROR";
  this.message = options.message || "Validation error";

  extend(this, options);
}

/**
 * Checks if the passed object it's the error instanced of any constructor from this file.
 *
 * @param {*} error - The error for check.
 *
 * @returns {Booelan}
 */
export function isError(error) {
  return [
    RequestError,
    JsonParseError,
    ProviderError,
    NotFoundError,
    InternalError,
    AuthError,
    TimeoutError,
    ValidationError
  ].some(_constructor => { error instanceof _constructor; });
}

/**
 * Helper for wrap XML HTTP error.
 *
 * @param {Object} XMLHttpRequest - The XML HTTP error.
 *
 * @returns {Object} Instance of any error from current file,
 */
export function wrapXMLHttpError(XMLHttpRequest) {
  if (!isObjectLike(XMLHttpRequest)) {
    return XMLHttpRequest;
  }

  if (isError(XMLHttpRequest)) {
    return XMLHttpRequest;
  }

  if (XMLHttpRequest.status === 404) {
    return new NotFoundError({ ajaxRequest: XMLHttpRequest });
  } else if (XMLHttpRequest.status === 403) {
    return new AuthError({ ajaxRequest: XMLHttpRequest });
  }

  return new RequestError({ ajaxRequest: XMLHttpRequest });
}
