export default [
  {
    //tile one (opening hours) - dark yellow
    style: {
      top: "60px",
      left: "60px",
      width: "420px",
      height: "302px"
    },
    line: [486, 835, 266, 361]
  },
  {
    // tile two (text plus image) - light yellow
    style: {
      top: "60px",
      right: "60px",
      width: "420px",
      height: "302px"
    },
    line: [598, 835, 816, 364]
  },
  {
    // tile three (text plus image) - deep orange
    style: {
      top: "420px",
      left: "330px",
      width: "420px",
      height: "302px"
    },
    line: [538, 823, 538, 723]
  },
  {
    // tile fourth (node list) - aquamarine
    style: {
      top: "778px",
      left: "60px",
      width: "260px",
      height: "644px"
    },
    line: [424, 1033, 319, 1102]
  },
  {
    // tile five (library event movie flow) - red
    style: {
      top: "778px",
      right: "60px",
      width: "260px",
      height: "644px"
    },
    line: [655, 1031, 759, 1102]
  },
  {
    // tile six (node list) - blue
    style: {
      top: "1199px",
      left: "410px",
      width: "260px",
      height: "466px"
    },
    line: [539, 1031, 539, 1199],
    meta: {
      nodeList: {
        amountOfElements: 2
      },
      libraryEvents: {
        amountOfElements: 2
      }
    }
  },
  {
    // tile seven (text plus image) - yellow
    style: {
      top: "1478px",
      left: "60px",
      width: "260px",
      height: "302px"
    },
    line: [466, 1076, 190, 1478]
  },
  {
    // tile eight (texxt plus image) - purple
    style: {
      top: "1478px",
      right: "60px",
      width: "260px",
      height: "302px"
    },
    line: [611, 1076, 889, 1478]
  }
];
