<template>
  <modal-layout-fullscreen
    :hide-empty-container="true"
    :footer-height="footerHeight"
    class="layout-navigation"
  >
    <template slot="content">
      <slot></slot>

      <modal-blank
        v-if="wayfinderURL"
        ref="wayfinderModal"

        @before-open="isWayfinderShown = true"
        @closed="isWayfinderShown = false"

        class="material-detail--wayfinder prevent-carousel-actions"
        min-width="100%"
        min-height="100%"
        position="absolute"
      >
        <template slot="raw">
          <easyscreen-iframe
            class="material-detail--wayfinder-view"
            :url="wayfinderURL"
            :overlay="false"
          />
        </template>
      </modal-blank>
    </template>
    <template slot="footer">
      <div v-if="disableNavigation !== true" class="layout-navigation--footer">
        <div class="layout-navigation--footer_left">
          <easyscreen-circle-button
            class="layout-navigation--footer-navigation-button"
            icon="fal fa-home-alt"
            labelPlacement="bottom"
            labelSize="small"
            :size="isMediumScreen ? 'small' : 'medium'"
            :disabled="backActions.length === 0"
            @click.native="toHome"
          >
            {{ $l10n("Home") }}
          </easyscreen-circle-button>
          <easyscreen-circle-button
            class="layout-navigation--footer-navigation-button"
            icon="fal fa-arrow-left"
            labelPlacement="bottom"
            labelSize="small"
            :size="isMediumScreen ? 'small' : 'medium'"
            :disabled="backActions.length === 0"
            @click.native="popAndRunBackAction"
          >
            {{ $l10n("Back") }}
          </easyscreen-circle-button>
          <easyscreen-circle-button
            v-if="withSearch"
            class="layout-navigation--footer-navigation-button"
            icon="fal fa-search"
            labelPlacement="bottom"
            labelSize="small"
            :size="isMediumScreen ? 'small' : 'medium'"
            :disabled="isWayfinderShown || searchDisabled"
            @click.native="$emit('show-search')"
          >
            {{ $l10n("Search") }}
          </easyscreen-circle-button>
          <easyscreen-circle-button
            v-if="wayfinderURL"
            class="layout-navigation--footer-navigation-button"
            icon="fal fa-map"
            labelPlacement="bottom"
            labelSize="small"
            :size="isMediumScreen ? 'small' : 'medium'"
            :disabled="isWayfinderShown || searchDisabled"
            @click.native="openWayfinder"
          >
            {{ $l10n("Map") }}
          </easyscreen-circle-button>
        </div>
        <div class="layout-navigation--footer_center">
          <div class="layout-navigation--title">
            {{ title || "" }}
          </div>
        </div>
        <div class="layout-navigation--footer_right">
          <slot name="footer-right"></slot>
        </div>
      </div>
    </template>
  </modal-layout-fullscreen>
</template>

<style src="./layout-navigation.less" lang="less" />
<script>
  import EasyscreenCircleButton from "../button/circle-button.vue";
  import ModalLayoutFullscreen from "./layout-fullscreen.vue";
  import ModalBlank from "../../core/modal/blank.vue";
  import EasyscreenIframe from "../../core/iframe/iframe.vue";

  import lodash from "lodash";
  import querystring from "querystring";
  import resizeMixin from "../mixins/resize.js";

  export default {
    name: "layout-navigation",
    mixins: [resizeMixin],
    props: {
      title: String,
      withSearch: {
        type: Boolean,
        default: false
      },
      searchDisabled: {
        type: Boolean,
        default: false
      },
      wayfinderData: {
        type: Object,
        default: () => ({})
      },
      autoClose: {
        type: Boolean,
        default: true
      },
      disableNavigation: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      wayfinderURL() {
        if (!this.wayfinderData.viewId || !this.$easyscreenConfig.get("wayfinder.url") || this.$easyscreenConfig.get("enable.showMapView") !== true)
          return null;

        return this.$easyscreenConfig.get("wayfinder.url") + "?" + querystring.stringify({
          view: this.wayfinderData.viewId,
          lang: this.$easyscreenConfig.get("wayfinder.lang")
        }) + "#" + querystring.stringify({
          here: this.wayfinderData.here
        });
      },
      footerHeight() {
        if (this.disableNavigation)
          return 0;

        if (this.isMediumScreen)
          return 80;

        return 100;
      }
    },
    data() {
      return {
        backActions: [],
        isWayfinderShown: false
      };
    },
    methods: {
      async openWayfinder() {
        if (!this.$refs.wayfinderModal)
          return;

        this.$refs.wayfinderModal.show();
        this.pushBackAction(() => this.$refs.wayfinderModal.hide(), "layout-navigation-wayfinder");
      },

      async closeWayfinder() {
        if (!this.$refs.wayfinderModal)
          return;

        this.$refs.wayfinderModal.hide();
        this.removeBackAction("layout-navigation-wayfinder");
      },
      pushBackAction(action, type) {
        let _action = function() {
          if (lodash.isFunction(action)) {
            return action();
          }
        };

        _action._actionType = type;
        this.backActions = this.backActions.concat([_action]);
      },
      popBackAction(type, options) {
        options = {
          findAction: false,
          ...(options || {})
        };

        let foundAction = this.backActions.slice(-1)[0];
        if (type && options.findAction) {
          foundAction = this.backActions.slice().reverse().find(action => action._actionType === type);
        } else if (!foundAction || !foundAction._actionType || !type || foundAction._actionType !== type) {
          return;
        }

        this.backActions = this.backActions.filter(action => action !== foundAction);
      },
      removeBackAction(type) {
        if (type == null) {
          this.backActions = [];
          return;
        }

        this.backActions = this.backActions.filter(action => action._actionType !== type);
      },
      popAndRunBackAction() {
        const action = this.backActions.slice(-1)[0];
        this.backActions = this.backActions.slice(0, -1);

        action();
      },
      toHome() {
        let backActions = this.backActions;
        this.backActions = [];

        return Promise.all(backActions.reverse().map(action => action()));
      }
    },
    screenStandby() {
      if (this.autoClose)
        this.toHome();
    },
    components: {
      EasyscreenIframe,
      "modal-layout-fullscreen": ModalLayoutFullscreen,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "modal-blank": ModalBlank
    }
  };
</script>
