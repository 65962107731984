<template>
  <div
    :class="['welcome-screen', orientation]"
    :style="{
      backgroundImage: backgroundImage
    }"
  >
    <div class="welcome-screen--content">
      <template
        v-for="(tile, index) in activeTiles"
      >
        <welcome-screen-tile
          :key="`tile-${ tile.key }`"
          :class="[
            'welcome-screen--tile',
            { 'welcome-screen--tile_hidden': !_get(contentState, `[${ index }].tile`, true) }
          ]"
          :color="_get(tile, 'tileOptions.color')"
          :actions="tile.loading ? [] : _getTileActions(tile)"
          :style="_get(tile, 'tileOptions.style')"
          :content-action="!['node-list', 'library-events'].includes(_get(tile, 'tileData.type'))"
          @action="(actionId) => _pushOverviewScreen({ actionId, tile, tileIndex: index })"
        >
          <loader
            v-if="tile.loading"
            type="in-place"
            size="medium"
          />
          <text-and-image-tile-content
            v-else-if="_get(tile, 'tileData.type') === 'text-and-image'"
            :title="_get(tile, 'tileOptions.title')"
            :text="_get(tile, 'tileData.options.text')"
            :image="_get(tile, 'tileData.customBg') || _get(tile, 'tileData.options.image')"
          />
          <node-list-tile-content
            v-else-if="_get(tile, 'tileData.type') === 'node-list'"
            :title="_get(tile, 'tileOptions.title')"
            :elements="_get(tile, 'tileData.options.items')"
            :color="_get(tile, 'tileOptions.color')"
            :sub-type="_get(tile, 'tileData.options.subType')"
            :amount-of-elements="_get(tile, 'tileOptions.meta.nodeList.amountOfElements')"
            @open-element="(element) => { _pushOverviewScreen({
              type: 'node-list-info',
              color: _get(tile, 'tileOptions.color'),
              data: element
            }) }"
            @open-wayfinder="(placement) => { log('open node list wayfinder', placement) }"
          />
          <library-events-tile-content
            v-else-if="_get(tile, 'tileData.type') === 'library-events'"
            :title="_get(tile, 'tileOptions.title')"
            :elements="_get(tile, 'tileData.options.items')"
            :color="_get(tile, 'tileOptions.color')"
            :sub-type="_get(tile, 'tileData.options.subType')"
            :amount-of-elements="_get(tile, 'tileOptions.meta.libraryEvents.amountOfElements')"
            @open-element="(element) => { _pushOverviewScreen({
              type: 'node-list-info',
              color: _get(tile, 'tileOptions.color'),
              data: element
            }) }"
            @open-wayfinder="(placement) => { log('open library events wayfinder', placement) }"
          />
          <opening-hours-tile-content
            v-else-if="_get(tile, 'tileData.type') === 'opening-hours'"
            :title="_get(tile, 'tileOptions.title')"
            :color="_get(tile, 'tileOptions.color')"
            :image="_get(tile, 'tileData.customBg')"
            :day-label="_get(tile, 'tileData.options.dayLabel')"
            :opened-today-label="_get(tile, 'tileData.options.openedTodayLabel')"
            :service-time-label="_get(tile, 'tileData.options.serviceTimeLabel')"
            :opening-hours="_get(tile, 'tileData.options.openingHours')"
          />
        </welcome-screen-tile>
        <line-segment
          :key="`tile-line-${ tile.key }`"
          :class="[
            'welcome-screen--tile-line',
            { 'welcome-screen--tile-line_hidden': !_get(contentState, `[${ index }].line`, true) }
          ]"
          :start="{ x: _get(tile, 'tileOptions.line[0]'), y: _get(tile, 'tileOptions.line[1]') }"
          :end="{ x: _get(tile, 'tileOptions.line[2]'), y: _get(tile, 'tileOptions.line[3]') }"
        />
      </template>

      <div
        :class="[
          'welcome-screen--overview',
          { 'welcome-screen--overview_downsized': overviewButtonDownsized },
          { 'welcome-screen--overview_opened': overviewButtonOpened }
        ]"
        @click="() => {
          if (transitionInProgress) {
            return;
          }

          if (overviewOpened) {
            _popOverviewScreen()
          } else if (activeSlide.welcomeVideo) {
            _pushOverviewScreen({
              type: 'welcome-screen-video',
              data: {
                src: activeSlide.welcomeVideo,
                title: welcomeVideoTitle,
                titleColor: welcomeVideoTitleColor,
                text: _l10n('See how you use the welcome screen')
              }
            });
          }
        }"
      >
        <div :class="[
          'welcome-screen--overview-content',
          { 'welcome-screen--overview-content_hidden': overviewButtonContentHidden }
        ]">
          <template v-if="!overviewButtonOpened">
            <div
              v-if="useDateTime"
              class="welcome-screen--datetime"
            >
              <div class="welcome-screen--date">{{ date }}</div>
              <div class="welcome-screen--time">{{ time }}</div>
            </div>

            <h1
              v-if="activeSlide.welcomeScreenText"
              ref="overviewTitle"
              class="welcome-screen--custom-overview-title"
              :style="{ fontSize: overviewTitleFontSize }"
            >
              {{ activeSlide.welcomeScreenText }}
            </h1>
            <h1
              v-else
              ref="overviewTitle"
              class="welcome-screen--overview-title"
              :style="{ fontSize: overviewTitleFontSize }"
            >
              <div>
                <span
                  v-if="$easyscreenSkin.isMiddelfart || $easyscreenSkin.isNaesbib || $easyscreenSkin.isGuldbib"
                  class="welcome-screen--overview-welcome-text"
                >
                  {{ _l10n("Welcome") }}
                </span>
                <span
                  v-else
                  class="welcome-screen--overview-welcome-text"
                >
                  {{ _l10n("Welcome to") }}
                </span>
              </div>

              <img
                v-if="$easyscreenSkin.isGuldbib"
                class="welcome-screen--library-logo"
                :src="libraryLogo"
              />
              <div
                v-else
                class="welcome-screen--library-name"
                v-html="libraryName"
              ></div>

              <div
                v-if="useCustomOverviewPlay && activeSlide.welcomeVideo"
                class="welcome-screen--custom-overview-play"
              >
                <i class="fal fa-play-circle"></i>
                <div>{{ _l10n("Introduction") }}</div>
              </div>
              <i
                v-else-if="activeSlide.welcomeVideo"
                class="esi esi-wifi-finger welcome-screen--overview-play"
              ></i>
            </h1>
          </template>
          <template v-else>
            <transition name="overview-control" mode="out-in">
              <i
                v-if="$refs.overview.getScreens().length <= 1"
                class="fal fa-chevron-circle-left welcome-screen--overview-back"
                key="primary"
              ></i>
              <i
                v-else
                class="fal fa-times welcome-screen--overview-back"
                key="secondary"
              ></i>
            </transition>
          </template>
        </div>
      </div>

      <div
        v-if="slides.length > 1"
        class="welcome-screen--localizations"
      >
        <div
          v-for="(slide, index) in slidesWithLocalizations"
          :key="_get(slide, 'options.lang')"
          :class="[
            'welcome-screen--localization',
            { 'welcome-screen--localization_active': _isActiveLocalization(_get(slide, 'options.lang')) }
          ]"
          @click="() => { activeSlideIndex = slide.initialIndex }"
        >
          <img
            v-if="_localizationIconExists(index)"
            class="welcome-screen--localization-icon"
            :src="_get(slide, 'options.icon')"
            :alt="_get(slide, 'options.lang')"
            @error="() => { missingLocalizationIcons.push(_get(slide, 'options.icon')) }"
          >
          <span
            v-else
            class="welcome-screen--localization-label"
          >
            {{ _get(slide, 'options.lang') }}
          </span>
        </div>
      </div>

      <div class="welcome-screen--info">
        <p class="welcome-screen--info-text">
          {{ _l10n('Click an area to see more') }}
          <i class="welcome-screen--info-icon esi esi-wifi-finger"></i>
        </p>
      </div>

      <line-segment
        v-if="!$easyscreenSkin.isNaesbib && !$easyscreenSkin.isMiddelfart"
        :class="[
          'welcome-screen--tile-line',
          { 'welcome-screen--tile-line_hidden': !overviewOpened }
        ]"
        :start="{ x: 65, y: 1005 }"
        :end="{ x: 132, y: 1005 }"
      />

      <welcome-screen-overview
        ref="overview"
        :class="[
          'welcome-screen--overview-data',
          { 'welcome-screen--overview-data_hidden': !overviewOpened }
        ]"

        @close="() => {
          _hideOverview().then(() => {
            $refs.overview.clear();
          });
        }"
      />
    </div>
  </div>
</template>

<style src="../core/icons.less" lang="less"></style>
<style src="./welcome-screen.less" lang="less"></style>

<script>
  import { get, isString } from "lodash";
  import moment from "moment";
  import * as d3 from "d3-timer";
  import parseDuration from "parse-duration";
  import CacheMap from "@/lib/cache-map.js";
  import l10n from "@/lib/localization/localization.js";
  import fitByFontSizeMixin from "../core/mixins/fit-by-font-size.js";
  import wayfinderMixin from "../core/mixins/wayfinder.js";
  import orientationMixin from "../core/mixins/orientation.js";

  import Loader from "../core/loader/loader.vue";
  import LineSegment from "../core/line-segment/line-segment.vue";
  import WelcomeScreenTile from "./welcome-screen-tile.vue";
  import WelcomeScreenOverview from "./welcome-screen-overview.vue";
  import LibraryEventsTileContent from "./welcome-screen-tile-content/library-events-tile-content.vue";
  import TextAndImageTileContent from "./welcome-screen-tile-content/text-and-image-tile-content.vue";
  import OpeningHoursTileContent from "./welcome-screen-tile-content/opening-hours-tile-content.vue";
  import NodeListTileContent from "./welcome-screen-tile-content/node-list-tile-content.vue";

  //const defaultBackgroundImage = "/images/welcomeScreen/main_bg.jpg";
  const asyncTimeout = function(duration) {
    return new Promise(resolve => {
      d3.timeout(() => {
        resolve();
      }, duration);
    });
  };

  export default {
    name: "welcome-screen",
    mixins: [fitByFontSizeMixin, wayfinderMixin, orientationMixin],
    props: {
      slides: {
        type: Array,
        default: () => ([])
      }
    },
    data() {
      return {
        date: "",
        time: "",
        activeSlideIndex: 0,
        missingLocalizationIcons: [],
        overviewTitleFontSize: null,
        tilesContentCache: new CacheMap(null, { defaultTimeout: "30m" }),
        contentState: [], // new Array(7).fill().map(() => ({ tile: false, line: false })),
        transitionInProgress: false,
        overviewButtonContentHidden: false,
        overviewButtonDownsized: false,
        overviewButtonOpened: false,
        overviewOpened: false,
        overviewColor: "#ca511b",
        overview: []
      };
    },
    watch: {
      tilesContentCache: {
        handler() {},
        deep: true
      }
    },
    computed: {
      libraryName: {
        get() {
          const defaultName = this.$easyscreenConfig.get("library.name", this.$easyscreenConfig.name);
          let customName = "";

          if (this.$easyscreenSkin.isMiddelfart) {
            customName = "Middelfart<br>Kultur & Bibliotek";
          } else if (this.$easyscreenSkin.isNaesbib) {
            customName = "Næstved Bibliotek<br>& Borgerservice";
          } else if (this.$easyscreenSkin.isBronbib) {
            customName = "Brønden";
          }

          return customName || defaultName;
        }
      },
      libraryLogo: {
        get() {
          if (this.$easyscreenSkin.isGuldbib) {
            return "/skins/guldbib/center-logotext.png";
          }

          return "";
        }
      },
      backgroundImage: {
        get() {
          const customBackground = get(this.activeSlide, "bg");
          return customBackground ? `url("${ customBackground }")` : null;
        }
      },
      activeSlide: {
        get() {
          return this.slides[this.activeSlideIndex] || {};
        }
      },
      activeTiles: {
        get() {
          if (Object.keys(this.activeSlide).length === 0) {
            return [];
          }

          /* [minor] TODO: Move the loading of content by getters into PSConverter after converter migration. */
          this._loadTilesContent(this.activeSlide.tiles);

          return this.activeSlide.tiles.map((tile, index) => {
            let _tile = tile;
            const getter = get(tile, "tileData.getter");
            if (getter) {
              const cachedData = this.tilesContentCache.get(getter);

              if (cachedData !== "loading") {
                _tile = Object.assign(tile, {
                  tileData: Object.assign(tile.tileData, {
                    options: cachedData || {}
                  })
                });
              }
            }

            return Object.assign({
              key: `${ this.activeSlideIndex }-${ index }`,
              loading: getter && !this.tilesContentCache.has(getter)
            }, _tile);
          });
        }
      },
      slidesWithLocalizations: {
        get() {
          return this.slides.map((slide, index) => {
            return Object.assign({ initialIndex: index }, slide);
          }).filter(slide => !!get(slide, "options.lang"));
        }
      },
      welcomeVideoTitle() {
        return this.slides[0].welcomeScreenTitle;
      },
      welcomeVideoTitleColor: {
        get() {
          if (this.$easyscreenSkin.isMiddelfart) {
            return "#F67001";
          } else if (this.$easyscreenSkin.isNaesbib) {
            return "#CA511B";
          }

          return "";
        }
      },
      useDateTime: {
        get() {
          return !this.$easyscreenSkin.isGuldbib && !this.$easyscreenSkin.isNaesbib && !this.$easyscreenSkin.isMiddelfart;
        }
      },
      useCustomOverviewPlay: {
        get() {
          return this.$easyscreenSkin.isMiddelfart || this.$easyscreenSkin.isNaesbib || this.$easyscreenSkin.isGuldbib;
        }
      }
    },
    methods: {
      /** The proxy method of `lodash.get`. */
      _get: get,
      /** Proxy for localization function. */
      _l10n: l10n,
      /**
       * Check if the location icon exists for slide with index.
       *
       * @param {Number} slideIndex - The index of slide for check.
       *
       * @returns {Boolean}
       */
      _localizationIconExists(slideIndex) {
        const icon = get(this.slides[slideIndex], "options.icon");
        return icon && !this.missingLocalizationIcons.includes(icon);
      },
      /**
       * Check if localization is active.
       *
       * @param {String} lang - localization for check.
       *
       * @returns {Boolean}
       */
      _isActiveLocalization(lang) {
        return get(this.activeSlide, "options.lang") === lang;
      },
      /**
       * Get the list of tile actions based on links and wayfinder tile settings.
       *
       * @param {Object} tile - Tile settings.
       * @param {Object[]} tile.tileOptions.links
       * @param {String} tile.tileOptions.links[].title
       * @param {Object} tile.tileOptions.wayfinder
       * @param {String} tile.tileOptions.wayfinder.title
       */
      _getTileActions(tile) {
        let actions = [];
        let links = get(tile, "tileOptions.links", []);
        let wayfinder = get(tile, "tileOptions.wayfinder");
        actions = links.map((link, index) => {
          return {
            id: `link:${ index }`,
            icon: "fal fa-chevron-circle-right",
            title: link.title
          };
        });

        if (wayfinder) {
          actions.push({
            id: "wayfinder",
            icon: "fal fa-map-marker-alt",
            title: wayfinder.title
          });
        }

        return actions;
      },
      /**
       * Update date and time of welcome screen watch.
       */
      _updateDatetime() {
        const _date = moment();

        if (this.$easyscreenSkin.isBronbib) {
          this.date = _date.format("DD.MM.YYYY");
          this.time = _date.format("HH.mm");
        } else {
          this.date = _date.format("dddd DD.MM.YYYY");
          this.time = l10n("Clock") + " " + _date.format("HH.mm");
        }
      },
      /**
       * Load the async data for tiles (nodelist or library events).
       *
       * @param {Object[]} tiles - list of welcome screen tiles.
       * @param {Function} tiles.tileData.getter - async loader of tile data.
       */
      _loadTilesContent(tiles) {
        tiles.forEach(async (tile) => {
          const getter = get(tile, "tileData.getter");

          if (getter && !this.tilesContentCache.has(getter)) {
            this.tilesContentCache.set(getter, "loading");
            const data = await getter(this);
            this.tilesContentCache.set(getter, data);
          }
        });
      },
      /**
       * Helper for update the array element without mutation of original array.
       *
       * @param {Object} options
       * @param {Number} options.index - Element index at array.
       * @param {String} options.property - Property at `this` where array is placed.
       * @param {*} options.value - The new value of array element.
       */
      _updateArrayElement({ property, index, value }) {
        let array = this[property].slice();
        array[index] = value;

        this[property] = array;
      },
      /**
       * Timeout with waiting of next tick before timeout initialization.
       * @async
       *
       * @param {Number} duration - Timeout duration.
       */
      async _timeoutWithTick(duration) {
        const started = Date.now();
        await this.$nextTick();
        const tickDuration = Date.now() - started;
        const timeoutDuration = duration - tickDuration;
        if (timeoutDuration > 0) {
          await asyncTimeout(timeoutDuration);
        }
      },
      /**
       * Hide tiles and show overview block.
       * @async
       */
      async _showOverview() {
        if (this.transitionInProgress) {
          return;
        }

        this.transitionInProgress = true;
        const tilesAmount = this.activeTiles.length;
        new Array(tilesAmount).fill().map(async (_, index) => {
          await this._timeoutWithTick(100 * index);
          this._updateArrayElement({
            property: "contentState",
            index: index,
            value: { tile: false }
          });
        });

        const linesAnimation = new Array(tilesAmount).fill().map(async (_, index) => {
          await this._timeoutWithTick(150 * index);
          this._updateArrayElement({
            property: "contentState",
            index: index,
            value: { tile: false, line: false }
          });
        });

        await Promise.all(linesAnimation);
        await this._timeoutWithTick(400);

        this.overviewButtonContentHidden = true;
        await this._timeoutWithTick(250);

        this.overviewButtonDownsized = true;
        await this._timeoutWithTick(250);

        this.overviewButtonOpened = true;
        await this._timeoutWithTick(250);

        this.overviewButtonContentHidden = false;
        this.overviewOpened = true;

        await this._timeoutWithTick(250);

        this.transitionInProgress = false;
      },
      /**
       * Hide overview block and show tiles.
       * @async
       */
      async _hideOverview() {
        if (this.transitionInProgress) {
          return;
        }

        this.transitionInProgress = true;

        this.overviewOpened = false;
        await this._timeoutWithTick(250);

        this.overviewButtonContentHidden = true;
        await this._timeoutWithTick(250);

        this.overviewButtonOpened = false;
        await this._timeoutWithTick(250);

        this.overviewButtonDownsized = false;
        await this._timeoutWithTick(250);

        this.overviewButtonContentHidden = false;
        await this._timeoutWithTick(250);

        const tilesAmount = this.activeTiles.length;
        new Array(tilesAmount).fill().map(async (_, index, array) => {
          await this._timeoutWithTick(100 * index);
          this._updateArrayElement({
            property: "contentState",
            index: array.length - index - 1,
            value: { tile: true, line: false }
          });
        });

        const linesAnimation = new Array(tilesAmount).fill().map(async (_, index, array) => {
          await this._timeoutWithTick(150 * index);
          this._updateArrayElement({
            property: "contentState",
            index: array.length - index - 1,
            value: { line: true }
          });
        });


        await Promise.all(linesAnimation);
        await this._timeoutWithTick(400);

        this.contentState = [];
        this.transitionInProgress = false;
      },
      /**
       * Push the screen to overview.
       * @async
       *
       * @param {Object} options - Screen data to open in overview.
       */
      async _pushOverviewScreen(options) {
        let screenOptions = {};
        if (isString(options.actionId)) {
          const [action, index] = options.actionId.split(":");

          screenOptions.id = `welcome-screen[${ this.activeSlideIndex }].tiles[${ options.tileIndex }];action:${ options.actionId }`;
          screenOptions.color = get(options, "tile.tileOptions.color");

          if (this.$easyscreenSkin.isMiddelfart || this.$easyscreenSkin.isNaesbib || this.$easyscreenSkin.isBronbib) {
            const firstWidget = get(options, `tile.tileOptions.links[${ index }].slides.widgets[0]`);
            const isLibraryEventsSingleList = firstWidget.name.includes("library-events-list");
            const isIframe = firstWidget.name.includes("easyscreen-iframe");

            if (isLibraryEventsSingleList) {
              if (this.$easyscreenSkin.isMiddelfart) {
                if (firstWidget.subType !== "cinema") {
                  screenOptions.style = { backgroundImage: 'url("/skins/middelfart/events-list-bg.png")' };
                }
                screenOptions.className = "info-color-black";
              } else if (this.$easyscreenSkin.isNaesbib) {
                screenOptions.style = { backgroundImage: 'url("/skins/naesbib/BG.png")' };
                screenOptions.className = "info-color-black";
              }
            } else if (isIframe) {
              screenOptions.className = "iframe";
            }
          }

          if (action === "wayfinder") {
            const wayfinderData = get(options, "tile.tileOptions.wayfinder");
            screenOptions.type = "iframe";
            screenOptions.padding = false;
            screenOptions.data = {
              url: this._generateWayfinderUrl({
                viewId: wayfinderData.view,
                markId: wayfinderData.markId
              }),
              overlay: false
            };
          } else if (action === "link") {
            screenOptions.type = "generic";
            screenOptions.links = get(options, `tile.tileOptions.links[${ index }].links`) || [];

            const wayfinderLabel = get(options, `tile.tileOptions.links[${ index }].slides.data.settings.wayfinderLabel`);
            const wayfinderData = get(options, `tile.tileOptions.links[${ index }].slides.data.settings.wayfinderPosition`);
            if (wayfinderData && wayfinderData.location_id) {
              screenOptions.links = [{
                icon: "fal fa-map-marker-alt",
                title: wayfinderLabel || l10n("Show more"),
                screenData: {
                  type: "iframe",
                  padding: false,
                  data: {
                    url: this._generateWayfinderUrl({
                      viewId: wayfinderData.view_id,
                      markId: wayfinderData.location_id
                    }),
                    overlay: false
                  }
                }
              }].concat(screenOptions.links).filter(Boolean);
            }

            screenOptions.data = {
              muteInfo: true,
              slides: get(options, `tile.tileOptions.links[${ index }].slides`) || []
            };
          }
        } else {
          screenOptions = options;
        }

        if (screenOptions.color) {
          get(screenOptions, "data.slides.widgets", []).forEach(widget => {
            if (widget.options && !widget.getter) {
              widget.options.tileColor = screenOptions.color;
            } else if (widget.getter) {
              const getter = widget.getter;
              widget.getter = async () => {
                let _options = await getter(this);
                _options = _options || {};
                _options.tileColor = screenOptions.color;

                return _options;
              };
            }
          });
        }

        if (!this.overviewOpened) {
          await this._showOverview();
        }

        this.$refs.overview.pushScreen(screenOptions);
      },
      /**
       * Close active screen in overview.
       * @async
       */
      async _popOverviewScreen() {
        this.$refs.overview.popScreen();
      }
    },
    mounted() {
      this._updateDatetime();
      this.datetimeInterval = d3.interval(this._updateDatetime, parseDuration("60s"));
      if (!this.$easyscreenSkin.isNaesbib && !this.$easyscreenSkin.isMiddelfart && !this.$easyscreenSkin.isGuldbib) {
        this._fitByFontSize({
          ref: "overviewTitle",
          target: { default: 32, property: "overviewTitleFontSize" },
          maxHeight: 82
        });
      }
    },
    beforeDestroy() {
      if (this.datetimeInterval) {
        this.datetimeInterval.stop();
        this.datetimeInterval = null;
      }
    },
    components: {
      "loader": Loader,
      "line-segment": LineSegment,
      "welcome-screen-tile": WelcomeScreenTile,
      "welcome-screen-overview": WelcomeScreenOverview,
      "library-events-tile-content": LibraryEventsTileContent,
      "text-and-image-tile-content": TextAndImageTileContent,
      "opening-hours-tile-content": OpeningHoursTileContent,
      "node-list-tile-content": NodeListTileContent
    }
  };
</script>
