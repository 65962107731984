var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"welcome-screen-tile",style:({
    borderRightColor: _vm.color
  })},[_c('div',{staticClass:"welcome-screen-tile--wrapper"},[_c('div',{staticClass:"welcome-screen-tile--content",style:({
        height: _vm._getContentHeight()
      }),on:{"click":() => {
        if (_vm.actions.length !== 0 && _vm.contentAction) {
          _vm.$emit('action', _vm.actions[0].id)
        }
      }}},[_vm._t("default")],2),_c('div',{ref:"actions",staticClass:"welcome-screen-tile--actions"},_vm._l((_vm.actions),function(action){return _c('div',{key:action.title,staticClass:"welcome-screen-tile--action",on:{"click":() => _vm.$emit('action', action.id)}},[(action.icon)?_c('i',{class:['welcome-screen-tile--action-icon', action.icon],style:({
            color: _vm.color
          })}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(action.title || _vm._l10n("Show more"))+" ")])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }