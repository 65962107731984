/**
 * Clamp number within the inclusive range specified by the given boundary values a and b.
 *
 * @param {Number} num - Number for clamp.
 * @param {Number} a - The first boundary.
 * @param {Number} b - The second boundary.
 *
 * @returns {Number} Clamped number.
 */
export default function clampNumber(num, a, b) {
  return Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b));
}
