/**
 * Cuts the text under the limit without the word breaking.
 * The nearest dot, comma, space and line end under the lmit will be a break point.
 *
 * @param {String} text - The stirng to cut.
 * @param {Number} limit - The limit under which the text should be cutted.
 *
 * @returns {String} Cutted or full text under the limit.
 */
const punctuationList = ["\n", " ", ",", "."];
export default function substring(text, limit) {
  let cuttedText = text.substr(0, limit);

  if (text.length > cuttedText.length) {
    if (!(punctuationList.includes(text[limit]) || punctuationList.includes(text[limit - 1]))) {
      let lastPunctuation = Math.max.apply(null, punctuationList.map(punctuation => cuttedText.lastIndexOf(punctuation)));
      if (lastPunctuation === -1) {
        lastPunctuation = limit;
      }

      cuttedText = cuttedText.substring(0, lastPunctuation);
    }

    if (punctuationList.includes(cuttedText[cuttedText.length - 1])) {
      cuttedText = cuttedText.substring(0, cuttedText.length - 1);
    }

    cuttedText += "...";
  }

  return cuttedText;
}
