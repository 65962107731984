/**
 * Returns the parsed or raw single style value of node.
 *
 * @param {HTMLElement} node - The html element which style should be taken.
 * @param {String} style - The style name.
 * @param {Object} [options] - The parsing options.
 * @param {Boolean} [options.raw=false] - Return the style without parse attempt.
 *
 * @returns {(Null|String|Number)} The parsed or raw style value.
 */
export default function getStyle(node, style, options) {
  if (!node) {
    return null;
  }

  options = options || {};

  const styleValue = window.getComputedStyle(node)[style];
  const parsedValue = parseInt(styleValue, 10);
  if (options.raw || window.isNaN(parsedValue)) {
    return styleValue;
  }

  return parsedValue;
}
