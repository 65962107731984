<template>
  <modal-layout
    :class="['layout-fullscreen', `layout-fullscreen--color-scheme_${ colorScheme }`, orientation]"
    :hide-empty-container="hideEmptyContainer"
    :header-height="headerHeight"
    :footer-height="footerHeight"
  >
    <template slot="header" v-if="hasSlot('header') || hasSlot('header-left') || hasSlot('header-right') || hasSlot('header-center')">
      <slot name="header"></slot>
      <div class="layout-fullscreen--header" v-if="hasSlot('header-left') || hasSlot('header-right') || hasSlot('header-center')">
        <div class="layout-fullscreen--header_left">
          <slot name="header-left"></slot>
        </div>
        <div class="layout-fullscreen--header_center">
          <slot name="header-center"></slot>
        </div>
        <div class="layout-fullscreen--header_right">
          <slot name="header-right"></slot>
        </div>
      </div>
    </template>

    <template slot="content">
      <slot name="content"></slot>
    </template>

    <template slot="footer" v-if="hasSlot('footer') || hasSlot('footer-left') || hasSlot('footer-right') || hasSlot('footer-center')">
      <slot name="footer"></slot>
      <div class="layout-fullscreen--footer" v-if="hasSlot('footer-left') || hasSlot('footer-right') || hasSlot('footer-center')">
        <div class="layout-fullscreen--footer_left">
          <slot name="footer-left"></slot>
        </div>
        <div class="layout-fullscreen--footer_center">
          <slot name="footer-center"></slot>
        </div>
        <div class="layout-fullscreen--footer_right">
          <slot name="footer-right"></slot>
        </div>
      </div>
    </template>
  </modal-layout>
</template>

<style lang="less" src="./layout-fullscreen.less"></style>
<script>
  import ModalLayout from "./layout.vue";

  import hasSlotMixin from "../mixins/has-slot.js";
  import orientationMixin from "../mixins/orientation.js";

  export default {
    name: "modal-layout-fullscreen",
    mixins: [hasSlotMixin, orientationMixin],
    props: {
      /* Flag for hide the "header", "content" or\and "footer" contatiner if there no content. */
      hideEmptyContainer: Boolean,
      /* The header height. */
      headerHeight: [Number, String],
      /* The footer height. */
      footerHeight: [Number, String],
      /*
       * The color scheme of fullscreen layout (also tweaks the size on layout: "header", "content" and "footer").
       * - default - No changes for layout (used the styles of ./layout.vue);
       * - easyscreen-menu - The color modification for default easyscreen menu;
       * - easyscreen-menu-front-page - The color and layout modification for front screen of easyscreen menu
       *   (the header and footer are bigger than default);
       * - easyscreen-menu-profile-page - The color and layout modification for profile screen of easyscreen menu
       *   (the footer are little bit bigger than default);
       * - digital-shelf - The layout modification for digital shelf (header and footer little bit smaller than default);
       */
      colorScheme: {
        type: String,
        default: "default",
        validator: _colorScheme => [
          "default",
          "easyscreen-menu",
          "easyscreen-menu-front-page",
          "easyscreen-menu-profile-page",
          "digital-shelf",
          "classic-search"
        ].includes(_colorScheme)
      }
    },
    components: {
      "modal-layout": ModalLayout
    }
  };
</script>
