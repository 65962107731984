var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.type === 'modal-based' ? 'modal-blank' : 'div',{ref:"modal",tag:"modal-blank",class:[
    'easyscreen-loader',
    `easyscreen-loader_${ _vm.type }`,
    `easyscreen-loader_${ _vm.size }`,
    `easyscreen-loader_design-${ _vm.design }`,
    { 'easyscreen-loader_with-background': _vm.withBackground }
  ],attrs:{"design":_vm.design,"position":_vm.position},on:{"before-open":(event) => _vm.$emit('before-open', event),"opened":(event) => _vm.$emit('opened', event),"before-close":(event) => _vm.$emit('before-close', event),"closed":(event) => _vm.$emit('closed', event)}},[_c(_vm.type === 'modal-based' ? 'template' : 'div',{tag:"template",slot:"raw"},[_c('div',{staticClass:"easyscreen-loader--spinner"},[_c('i',{staticClass:"fa fa-spinner fa-spin"})])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }