var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"easyscreen-youtube"},[(_vm.showPoster)?_c('video-poster',{attrs:{"type":_vm.poster,"image":_vm.image,"description":_vm.description},on:{"play":_vm.play}}):[_c('youtube-native',{ref:"youtube",attrs:{"width":"100%","height":"100%","video-id":_vm.videoId || _vm._getIdFromUrl(_vm.url),"player-vars":{
        autoplay: _vm.autoplay ? 1 : 0,
        autohide: 1,
        controls: 0,
        disablekb: 1,
        fs: 1,
        modestbranding: 1,
        mute: _vm.muted !== undefined ? _vm.muted : _vm.autoplay ? 1 : 0,
        start: _vm.startTime,
        showinfo: 0,
        rel: 0,
        loop: 1
      }},on:{"playing":(event) => {
        _vm.isPlaying = true;
        _vm.$emit('playing', event);
      },"paused":(event) => {
        _vm.isPlaying = false;
        _vm.$emit('paused', event);
      },"ended":(event) => {
        _vm.isPlaying = false;
        _vm.$emit('ended', event);
      },"buffering":(event) => _vm.$emit('buffering', event),"cued":(event) => _vm.$emit('cued', event),"error":(event) => {
        _vm.isPlaying = false;
        _vm.$emit('error', event)
      },"ready":(event) => {
        _vm.isReady = true;
        _vm.$emit('ready', event)
        _vm._onReady(event);
      },"unstarted":(event) => {
        _vm.$emit('unstarted', event)
        _vm._onUnstarted(event);
      }}}),(_vm.isMuted)?_c('div',{staticClass:"easyscreen-youtube--button easyscreen-youtube--unmute-button",on:{"click":_vm.unmute}},[_c('i',{staticClass:"fa fa-volume-up"})]):_vm._e(),(_vm.overlay)?_c('div',{staticClass:"easyscreen-youtube--overlay",on:{"click":() => {
        if (!_vm.autoplay) {
          _vm.play();
        } else {
          _vm.unmute();
        }
      }}}):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }