var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"welcome-screen-overview-light"},_vm._l((_vm.allGroups),function(groups){return _c('transition-group',{key:groups.type,class:[
      'welcome-screen-overview-light--screens-groups',
      `welcome-screen-overview-light--screens-groups_${ groups.type }`
    ],attrs:{"name":"group-slide","tag":"div"}},_vm._l((groups.data),function(group,groupIndex){return _c('div',{key:group.id,staticClass:"welcome-screen-overview-light--screens-group",style:({
        '--group-index': groupIndex,
        '--groups-amount': groups.data.length,
        ...(group.style || {})
      })},[_c('transition-group',{staticClass:"welcome-screen-overview-light--screens",attrs:{"name":"screen-slide","tag":"div"}},_vm._l((group.screens),function(screen,screenIndex){return _c('div',{key:screen.id,staticClass:"welcome-screen-overview-light--screen",style:({ '--screen-index': screenIndex, '--screens-amount': group.screens.length })},[_c(_vm.$easyscreenIsMobile ? 'scrollable' : 'div',{tag:"component",attrs:{"max-height":"100%"}},[(screen.node)?_c('get-component',{attrs:{"data":screen.node}}):(screen.type === 'generic')?_c('presentation-set',{attrs:{"embedded":"welcome-screen-light","muteInfo":screen.data.muteInfo,"slides":[screen.data.slides].filter(Boolean)},on:{"expand-element":(element) => {
                _vm.pushScreen(group, {
                  type: 'node-list-info',
                  data: element
                });
              }}}):(screen.type === 'node-list-info')?_c(_vm.$easyscreenIsMobile ? 'div' : 'scrollable',{tag:"component",attrs:{"max-height":"100%"}},[_c('node-list-info',_vm._b({attrs:{"layout":"welcome-screen-light","with-scrollable-content":false}},'node-list-info',screen.data,false))],1):_c('div',[_vm._v(" "+_vm._s(_vm.$l10n("No template found!"))+" "+_vm._s(_vm.log("Template not found for this screen", screen))+" ")])],1)],1)}),0)],1)}),0)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }