var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
  'easyscreen-iframe',
  _vm.modificator ? `easyscreen-iframe_${ _vm.modificator }` : null
]},[(_vm.withLoader && _vm.loaderShown)?_c('loader',{staticClass:"easyscreen-iframe--loader",attrs:{"type":"in-place","with-background":true,"design":_vm.design}}):_vm._e(),(_vm.url)?_c('div',{class:[
      'easyscreen-iframe--wrapper',
      { 'easyscreen-iframe_with-overlay': _vm.overlay }
    ]},[_c('iframe',{staticClass:"easyscreen-iframe--frame",attrs:{"frameborder":"0","sandbox":"allow-same-origin allow-scripts allow-popups allow-forms","src":_vm.url},on:{"load":function($event){_vm.loaderShown = false}}}),(_vm.overlay)?_c('div',{staticClass:"easyscreen-iframe--overlay"}):_vm._e()]):_c('h1',{staticClass:"easyscreen-iframe--alert"},[_vm._v(" "+_vm._s(_vm.$l10n("Url is empty"))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }