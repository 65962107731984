export default [
  {
    //tile one (way finder)
    style: {
      top: "100px",
      left: "50px",
      width: "37%",
      height: "280px"
    },
    line: [545, 650, 240, 377]
  },
  {
    // tile two (opening hours)
    style: {
      top: "100px",
      right: "50px",
      width: "37%",
      height: "280px"
    },
    line: [545, 650, 840, 377]
  },
  {
    // tile three (library events)
    style: {
      top: "460px",
      left: "50px",
      width: "250px",
      height: "706px"
    },
    line: [545, 650, 310, 650]
  },
  {
    // tile fourth (node list)
    style: {
      top: "450px",
      right: "50px",
      width: "250px",
      height: "706px"
    },
    line: [545, 650, 770, 650]
  },
  {
    // tile five (???)
    style: {
      top: "930px",
      left: "calc(50% - 125px)",
      width: "250px",
      height: "300px"
    },
    line: [545, 650, 545, 930]
  },
  {
    // tile six (???)
    style: {
      top: "1400px",
      left: "50px",
      width: "250px",
      height: "300px"
    },
    line: [545, 650, 176, 1400]
  },
  {
    // tile seven (???)
    style: {
      top: "1400px",
      left: "calc(50% - 125px)",
      width: "250px",
      height: "300px"
    },
    line: [545, 650, 545, 1400]
  },
  {
    // tile eight (???)
    style: {
      top: "1400px",
      right: "50px",
      width: "250px",
      height: "300px"
    },
    line: [545, 650, 904, 1400]
  }
];
