<template>
  <div
    :class="['welcome-screen-tile-light', { 'welcome-screen-tile-light_active': active }]"
    :style="{ '--special-color': color }"
  >
    <div class="welcome-screen-tile-light--content">
      <slot></slot>

      <div
        v-if="hasSlot('default') === false"
        class="welcome-screen-tile-light--content-placeholder"
        :style="{ backgroundImage: background ? `url('${ background }')` : null }"
      >
        <div class="welcome-screen-tile-light--title">
          <span class="welcome-screen-tile-light--title-text">
            {{ title }}
          </span>
          <div class="welcome-screen-tile-light--title-dark-overlay"></div>
          <div class="welcome-screen-tile-light--title-special-overlay"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./welcome-screen-tile-light.less" lang="less"></style>

<script>
  import hasSlotMixin from "../core/mixins/has-slot.js";

  export default {
    name: "welcome-screen-tile-light",
    mixins: [hasSlotMixin],
    props: {
      color: String,
      title: String,
      background: String,
      active: Boolean
    }
  };
</script>
