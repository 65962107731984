import { isFunction } from "lodash";
import parseDuration from "parse-duration";
import * as d3 from "d3-timer";
import { camelCase } from "camel-case";

/**
 * Provides the interface for send statistic to easyscreen manager.
 *
 * @class EasyscreenManagerStatistic
 *
 * @param {Object} options - constructor options.
 * @param {Object} options.$request - Initialized easyscreen manager request helper.
 * @param {Function<Object>} options.$request.statisticHeartbeat({ screen: String, step: Number }) - Async function for heartbeat request.
 * @param {Function<Object>} options.$request.statisticAction({ screen: String, type: String, value: String }) - Async function for action request.
 * @param {String} options.screenId - The id of the active screen (see `id` url query parameter).
 * @param {Boolean} [options.permanentDisable=false] - Disables statistic sending.
 * @param {(String|Number)} options.heartbeatStep - The interval of heartbeat request in milliseconds or "parse-duration" format.
 */
export default class EasyscreenManagerStatistic {
  constructor(options) {
    this.$request = options.$request;
    this.screenId = options.screenId;
    this.permanentDisable = options.permanentDisable;

    if (/[^0-9]/.test(options.heartbeatStep)) {
      this.heartbeatStep = parseDuration(options.heartbeatStep);
    } else {
      this.heartbeatStep = parseInt(options.heartbeatStep, 10) * 1000;
    }

    this._heartbeatInterval = null;
    this._heartbeatIndex = 0;
    this._enabled = this.permanentDisable !== true;

    this.scheme = [{
      /* Method name: "openMaterial({ title: , faustNumber:  })" */
      name: "open_material",
      value: options => `${ options.title } (${ options.faustNumber })`
    }, {
      /* Method name: "selectDigitalShelfTheme({ tagName:  })" */
      name: "select_digital-shelf-theme",
      value: "tagName"
    }, {
      /* Method name: "makeSearch({ query:  })" */
      name: "make_search",
      value: options => decodeURIComponent(options.query)
    }, {
      /* Method name: "openNodeList({ nid, title })" */
      name: "open_node-list",
      value: "nid",
      additionalData: (options) => {
        return {
          title: options.title
        };
      }
    }, {
      /* Method name: "openMaterialReview({  })" */
      name: "open_material-review",
      value: "title"
    }, {
      /* Method name: "reserveMaterial({ title:  })" */
      name: "reserve_material",
      value: "title"
    }, {
      /* Method name: "clickEsMenuNavigation({ buttonTitle:  })" */
      name: "click_es-menu__navigation",
      value: options => decodeURIComponent(options.buttonTitle)
    }, {
      /* Method name: "loginUserProfile()" */
      name: "login_user-profile"
    }, {
      /* Method name: "loginUserProfileFaile()" */
      name: "login_user-profile__failed"
    }, {
      /* Method name: "paymentUserProfile({ feeIds:  })" */
      name: "payment_user-profile",
      value: "feeIds"
    }, {
      /* Method name: "scanScanner({ materialIds: })" */
      name: "scan_scanner",
      value: "materialIds"
    }, {
      /* Method name: "loanScanner({ materialId: })" */
      name: "loan_scanner",
      value: "materialId"
    }, {
      /* Method name: "unloanScanner({ materialId: })" */
      name: "unloan_scanner",
      value: "materialId"
    }, {
      /* Method name: "unloanScannerFailed({ materialId: })" */
      name: "unloan_scanner__failed",
      value: "materialId"
    }, {
      /* Method name: "loanScannerFailed({ materialId: })" */
      name: "loan_scanner__failed",
      value: "materialId"
    }, {
      /* Method name: "scanScannerFailed({ materialId: })" */
      name: "scan_scanner__failed",
      value: "materialId"
    }, {
      /* Method name: "openWelcomeScreenTile({ title })" */
      name: "open_welcome-screen-tile",
      value: "title"
    }, {
      /* Method name: "openWelcomeScreenNodeList({ nid, title })" */
      name: "open_welcome-screen-node-list",
      value: "nid",
      additionalData: (options) => {
        return {
          title: options.title
        };
      }
    }];

    this.init();
  }

  /**
   * Initialize the statistic handlers (create the methods based on `this.scheme`).
   * Starts the heartbeat requests by interval. Will be called in constructor.
   */
  init() {
    if (this._heartbeatInterval) {
      return;
    }

    this._startHeartbeat();
    this.scheme.forEach(method => {
      this[camelCase(method.name)] = (options) => {
        let actionData = {
          type: method.name,
          value: this._getMethodValue(method, options) || "-"
        };

        if (method.additionalData) {
          actionData = Object.assign(actionData, method.additionalData(options));
        }

        this._action(actionData);
      };
    });
  }

  /**
   * Stops the heartbeat inverval.
   */
  destroy() {
    this._stopHeartbeat();
  }

  /**
   * Temporary disables the statistic sending.
   */
  disableStatistic() {
    this._enabled = false;
  }

  /**
   * Enables the statistic if the statistic is not permanently disabled.
   */
  enableStatistic() {
    if (this.permanentDisable) {
      this.disableStatistic();
      return;
    }

    this._enabled = true;
  }

  /**
   * Helper for get the value for method as object parameter or function.
   *
   * @param {Object} methodOptions - The method scheme.
   * @param {Object} requestOptions - Method options passed on call.
   */
  _getMethodValue(methodOptions, requestOptions) {
    if (!methodOptions.value) {
      return undefined;
    }

    if (isFunction(methodOptions.value)) {
      return methodOptions.value(requestOptions);
    }
    
    return requestOptions[methodOptions.value];
  }

  /**
   * Starts the heartbeat interval.
   */
  _startHeartbeat() {
    this._stopHeartbeat();

    this._heartbeatInterval = d3.interval(() => this.heartbeat, this.heartbeatStep);
  }

  /**
   * Stops the heartbeat interval.
   */
  _stopHeartbeat() {
    if (this._heartbeatInterval) {
      this._heartbeatInterval.stop();
      this._heartbeatInterval = null;
    }
  }

  /**
   * Send the heartbeat request to easyscreen manager.
   */
  async _heartbeat() {
    this._heartbeatIndex += 1;

    return this.$request.statisticHeartbeat({
      screen: this.screenId,
      step: this._heartbeatIndex
    });
  }

  /**
   * Send the action request to easyscreen manager.
   *
   * @param {Object} options - Request options.
   * @param {String} options.type - Action type (search, reservation and etc).
   * @param {String} options.value - Action value (search query, material id, fee id and etc).
   */
  async _action(options) {
    if (this._enabled === false) {
      return;
    }

    return this.$request.statisticAction(Object.assign({ screen: this.screenId }, options));
  }
}
