export default {
  methods: {
    /**
     * Helper for check if the slot with name is defined for conponent instance.
     *
     * @param {String} name - The name of slot.
     *
     * @returns {Boolean} `true` - the slot is exists, `false` otherwise.
     */
    hasSlot(name) {
      name = name || "default";
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    }
  }
};
