/**
 * Prevents the function (callback) call more that 1 time in N (timeout) milliseconds.
 *
 * @param {Function} callback - The function to wrap.
 * @param {Number} timeout - The timeout from last call in milliseconds.
 *
 * @returns {Function} The passed function with debounce wrapper.
 */
export default function debounceDrop(callback, timeout) {
  let lastCall;
  let withDebounce = function() {
    const now = Date.now();
    if (!lastCall || now - lastCall >= timeout) {
      lastCall = now;
      callback.apply(this, arguments);
    }
  };
  withDebounce._callback = callback;

  return withDebounce;
}
