<template>
  <div :class="['modal-layout', `modal-layout_design-${ design }`]">
    <div
      v-if="!hideEmptyContainer || (hideEmptyContainer && hasSlot('header'))"
      ref="header"
      class="modal-layout--header"
      :style="{
        height: headerHeight
      }"
    >
      <slot name="header"></slot>
    </div>
    <div
      v-if="!hideEmptyContainer || (hideEmptyContainer && hasSlot('content'))"
      :class="[
        'modal-layout--content',
        {
          'modal-layout--content_no-footer': hideEmptyContainer && !hasSlot('footer'),
          'modal-layout--content_no-header': hideEmptyContainer && !hasSlot('header')
        }
      ]"
      :style="{
        height: _getContentHeight()
      }"
    >
      <slot name="content"></slot>
    </div>
    <div
      v-if="!hideEmptyContainer || (hideEmptyContainer && hasSlot('footer'))"
      ref="footer"
      class="modal-layout--footer"
      :style="{
        height: footerHeight
      }"
    >
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<style lang="less" src="./layout.less" />
<script>
  import { isNumber } from "lodash";
  import hasSlotMixin from "../mixins/has-slot.js";
  import getStyle from "../../../lib/utils/get-style.js";

  function addUnit(value, unit) {
    if (isNumber(value)) {
      value = value + unit;
    }

    return value;
  }
  export default {
    name: "modal-layout",
    mixins: [hasSlotMixin],
    props: {
      /* Flag for hide the "header", "content" or\and "footer" contatiner if there no content. */
      hideEmptyContainer: Boolean,
      /* The min width of modal. */
      headerHeight: [Number, String],
      /* The min height of modal. */
      footerHeight: [Number, String],
      /* The global reskin. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      }
    },
    methods: {
      /**
       * Get the height of content node based on footer and header size.
       *
       * @returns {String} The height style of content node.
       */
      _getContentHeight() {
        if (!this.headerHeight && !this.footerHeight) {
          return;
        }

        let headerHeight = addUnit(this.headerHeight || getStyle(this.$refs.header, "height") || 0, "px");
        let footerHeight = addUnit(this.footerHeight || getStyle(this.$refs.footer, "height") || 0, "px");

        return `calc(100% - ${ headerHeight } - ${ footerHeight })`;
      }
    }
  };
</script>
