import EventEmitter from "eventemitter3";
import { isString } from "lodash";
import * as d3 from "d3-timer";
import parseDuration from "parse-duration";
import humanizeDuration from "humanize-duration";
import Stopwatch from "@/lib/utils/stopwatch.js";
import randomId from "@/lib/utils/random-id.js";
import { TimeoutError } from "@/lib/errors.js";

/**
 * The event fires when screen is presentations (animation) is running (waiting for users).
 *
 * @event ScreenActivity#running
 */

/**
 * The event fires when detection for user actions is stopped.
 *
 * @event ScreenActivity#disabled
 */

/**
 * The event fires when user interacts with with screen (presentations is stopped).
 *
 * @event ScreenActivity#pending
 */

/**
 * Tracks the users interactions with the screen
 *
 * @class ScreenActivity
 * @augments EventEmitter3
 *
 * @fires ScreenActivity#running
 * @fires ScreenActivity#disabled
 * @fires ScreenActivity#pending
 *
 * @param {Object} options
 * @param {String} [options.pendingTime="10s"] - The minimal range since last user action to count the screen "running".
 * @param {Boolean} [options.dontOverwrite=false] - Prevent the pendingTime override (override is possible by default).
 */
export default class ScreenActivity extends EventEmitter {
  constructor(options) {
    super();

    if (!options) {
      options = {};
    }
    let pendingTime = options.pendingTime || "10s";
    if (isString(pendingTime)) {
      pendingTime = pendingTime.trim();

      if (/[^0-9]/.test(pendingTime)) {
        pendingTime = parseDuration(pendingTime);
      } else {
        pendingTime = parseInt(pendingTime, 10) * 1000;
      }
    }

    this.state = "disabled"; //pending | running | disabled
    this.pendingTime = pendingTime;
    this.pendingTimeout = null;
    this.pendingStopwatch = new Stopwatch();

    this.listeners = [];

    this.logState();

    this.dontOverwrite = options.dontOverwrite || false;
    /* Input are used for detect the switch into iframe. */
    this.dropFocusInput = document.createElement("input");
    this.dropFocusInput.style.position = "absolute";
    this.dropFocusInput.style.left = "-99999px";
    this.dropFocusInput.style.top = "-99999px";

    document.body.appendChild(this.dropFocusInput);

    console.info(`Standby: initial activity timeout is ${ humanizeDuration(this.pendingTime) }.`);

    this.lastAction = null;
  }

  /**
   * Override for `EventEmitter3.on` method (listener for a given event)
   *
   * @param {String} event - The name of event
   * @param {Function} fn - The event listener
   * @param {*} [context=this] - The context to invoke the listener with.
   *
   * @returns {Function} - Unsubscribe method for subscribed function.
   */
  on(event, fn, context) {
    super.on(event, fn, context);
    return () => this.off(event, fn, context);
  }

  /**
   * Override for `EventEmitter3.once` method (one-time listener for a given event)
   *
   * @param {String} event - The name of event
   * @param {Function} fn - The event listener
   * @param {*} [context=this] - The context to invoke the listener with.
   *
   * @returns {Function} - Unsubscribe method for subscribed function.
   */
  once(event, fn, context) {
    super.once(event, fn, context);
    return () => this.off(event, fn, context);
  }

  /**
   * The fallback for original implementation of `EventEmitter3.on`.
   *
   * @param {String} event - The name of event
   * @param {Function} fn - The event listener
   * @param {*} [context=this] - The context to invoke the listener with.
   */
  listen(event, fn, context) {
    return this.on(event, fn, context);
  }

  /**
   * Change the pending time (waiting for user actions before switch to standby mode)
   * if than allowed by instance settings.
   *
   * @param {Number} pendingTime - The new pending time in milliseconds.
   * @param {Boolean} dontOverwrite - The new value for `this.dontOverwrite` flag.
   */
  changePendingTime(pendingTime, dontOverwrite) {
    if (this.dontOverwrite !== true) {
      if (pendingTime !== this.pendingTime) {
        this.pendingTime = pendingTime;

        if (this.pendingTimeout) {
          this.pendingTimeout.stop();
          this.pendingTimeout = d3.timeout(() => {
            this.run();
          }, this.pendingTime);
        }

        console.info(`Standby: activity timeout updated to ${ humanizeDuration(pendingTime) }.`);
      }
    }

    if (dontOverwrite !== undefined) {
      this.dontOverwrite = dontOverwrite;
    }
  }

  /**
   * Log the state of screen activity instance.
   */
  logState() {
    console.info(`Standby: state is "${ this.state }"`);
  }

  /**
   * Handle the switch into the standby running state (waiting for user actions).
   *
   * @param {Boolean} [handleRun=false] - Prevent the reemit the running event if state is already "running".
   */
  run(handleRun) {
    if (handleRun && this.state === "running" || this.state === "disabled") {
      return false;
    }

    this.state = "running";
    this.logState();

    if (this.pendingTimeout) {
      this.pendingTimeout.stop();
      this.pendingStopwatch.stop();

      console.info(`Standby: activity duration ${ humanizeDuration(this.pendingStopwatch.duration) }`);
      window.focus();
    }

    this.emit("running");
  }

  /**
   * Enable\recovety the screen activity listener.
   *
   * @param {Boolean} [force=false] - Enable the the screen activity listener with default state if instance have no saved one.
   */
  enable(force) {
    if (this._savedState || force) {
      this.state = this._savedState || "enabled";
      this._savedState = null;

      if (this.state === "pending") {
        this.action();
      } else {
        this.logState();
      }
    }
  }

  /**
   * Disable the the screen activity listener and save the last state.
   */
  disable() {
    if (this.pendingTimeout) {
      this.pendingTimeout.stop();
      this.pendingTimeout = null;
    }

    this._savedState = this.state;
    this.state = "disabled";
    this.logState();

    this.emit("disabled");
  }

  enablePostmessageScreenActivity(iframe) {
    return new Promise((resolve, reject) => {
      const id = randomId();
      const initTimeout = d3.timeout(() => {
        window.removeEventListener("message", postMessageListener);
        reject(new TimeoutError({ message: "postmessage-screen-activity handshake timeout" }));
      }, 500);

      const postMessageListener = (event) => {
        if (event.data.id !== id)
          return;

        switch (event.data.message) {
          case "action:postmessage-screen-activity":
            this.action(true);
            break;
          case "enabled:postmessage-screen-activity":
            initTimeout.stop();
            resolve();
            break;
          case "close:postmessage-screen-activity":
            window.removeEventListener("message", postMessageListener);
            break;
        }
      };

      window.addEventListener("message", postMessageListener);
      iframe.contentWindow.postMessage({ message: "enable:postmessage-screen-activity", id: id }, "*");
    });
  }

  /**
   * Handler for a user action.
   */
  async action(skipElementValidation) {
    this.lastAction = Date.now();

    if (skipElementValidation !== true) {
      const activeElement = document.activeElement;
      const activeTagName = document.activeElement.tagName;
      if (activeTagName === "IFRAME") {
        try {
          /* Listen the window post messages for update standby timer. */
          await this.enablePostmessageScreenActivity(activeElement);
        } catch (error) {
          if (error.code !== "TIMEOUT_ERROR") {
            console.error(error);
          }

          /* The fallback way to update standby timer. Will reset the focus from input fields! */
          this.dropFocusInput.focus();
        }
      }
    }

    if (this.state !== "disabled") {
      if (this.state !== "pending") {
        this.state = "pending";
        this.logState();

        this.emit("pending");

        this.pendingStopwatch.start();
      }

      if (this.pendingTimeout) {
        this.pendingTimeout.stop();
      }

      this.pendingTimeout = d3.timeout(() => {
        this.run();
      }, this.pendingTime);
    }
  }
}
