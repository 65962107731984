<script>
  import youtubePlayer from "youtube-player";
  import { debounce } from "lodash";

  const UNSTARTED = -1;
  const ENDED = 0;
  const PLAYING = 1;
  const PAUSED = 2;
  const BUFFERING = 3;
  const CUED = 5;
  const eventName = {
    [UNSTARTED]: "unstarted",
    [PLAYING]: "playing",
    [PAUSED]: "paused",
    [ENDED]: "ended",
    [BUFFERING]: "buffering",
    [CUED]: "cued"
  };

  export default {
    name: "youtube-native",
    props: {
      /* The id of the youtube video. */
      videoId: String,
      /* Custom player variables. See available options: https://developers.google.com/youtube/player_parameters.  */
      playerVars: {
        type: Object,
        default: () => ({})
      },
      /* Youtube iframe pixel height. */
      height: {
        type: [Number, String],
        default: 360
      },
      /* Youtube iframe pixel width. */
      width: {
        type: [Number, String],
        default: 640
      },
      /* Youtube iframe will proportionally scale height with its width. */
      resize: {
        type: Boolean,
        default: false
      },
      /* Delay in milliseconds before running resize callback. */
      resizeDelay: {
        type: Number,
        default: 100
      },
      /* No cookie version of youtube. */
      nocookie: {
        type: Boolean,
        default: false
      },
      /* Youtube iframe will use its parent's width */
      fitParent: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        player: {}
      };
    },
    computed: {
      aspectRatio () {
        return this.width / this.height;
      }
    },
    methods: {
      /**
       * Handler of the youtube ready event.
       * 
       * @param {Object} event - The event data.
       */
      _playerReady (event) {
        this.$emit("ready", event);
      },
      /**
       * Handler of the youtube ready state change event.
       * 
       * @param {Object} event - The event data.
       */
      _playerStateChange (event) {
        if (event.data !== null) {
          this.$emit(eventName[event.data], event);
        }
      },
      /**
       * Handler of the youtube error event.
       * 
       * @param {Object} event - The event data.
       */
      _playerError (event) {
        this.$emit("error", event);
      },
      /**
       * Handler of `videoId` watcher.
       * 
       * @param {String} videoId - The new video id.
       */
      _updatePlayer (videoId) {
        if (!videoId) {
          return this.player.stopVideo();
        }

        const params = { videoId };

        if (typeof this.playerVars.start === "number") {
          params.startSeconds = this.playerVars.start;
        }

        if (typeof this.playerVars.end === "number") {
          params.endSeconds = this.playerVars.end;
        }

        if (this.playerVars.autoplay === 1) {
          return this.player.loadVideoById(params);
        }

        this.player.cueVideoById(params);
      },
      /**
       * Resize youtube iframe proportionally scale height with its width.
       */
      async _resizeProportionally () {
        const iframe = await this.player.getIframe();
        const width = this.fitParent ? iframe.parentElement.offsetWidth : iframe.offsetWidth;
        const height = width / this.aspectRatio;

        this.player.setSize(width, height);
      }
    },
    watch: {
      videoId: "_updatePlayer",
      resize (val) {
        if (val) {
          window.addEventListener("resize", debounce(this._resizeProportionally, this.resizeDelay));
          this._resizeProportionally();
        } else {
          window.removeEventListener("resize", debounce(this._resizeProportionally, this.resizeDelay));
          this.player.setSize(this.width, this.height);
        }
      },
      width (val) {
        this.player.setSize(val, this.height);
      },
      height (val) {
        this.player.setSize(this.width, val);
      }
    },
    beforeDestroy () {
      if (this.player !== null && this.player.destroy) {
        this.player.destroy();
        delete this.player;
      }

      if (this.resize) {
        window.removeEventListener("resize", debounce(this._resizeProportionally, this.resizeDelay));
      }
    },
    mounted () {
      window.YTConfig = {
        host: "https://www.youtube.com/iframe_api"
      };

      this.player = youtubePlayer(this.$el, {
        host: this.nocookie ? "https://www.youtube-nocookie.com" : "https://www.youtube.com",
        width: this.width,
        height: this.height,
        videoId: this.videoId,
        playerVars: this.playerVars
      });

      this.player.on("ready", this._playerReady);
      this.player.on("stateChange", this._playerStateChange);
      this.player.on("error", this._playerError);

      if (this.resize) {
        window.addEventListener("resize", debounce(this._resizeProportionally, this.resizeDelay));
      }

      if (this.fitParent) {
        this._resizeProportionally();
      }
    },
    render (h) {
      return h("div");
    }
  };
</script>
