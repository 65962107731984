export default function(data) {
  let widget = {
    ...data.widgetPlacement,
    contentType: "Game",
    name: "easyscreen-game",
    options: data.data
  };

  if (widget.width === 100 && widget.height === 100) {
    widget.presentationSet = {
      class: "presentation-set_fullscreen-game"
    };
  }

  return widget;
}
