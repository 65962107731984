import { debounce } from "lodash";

export default {
  props: {
    /* Delay in milliseconds before running resize callback. */
    resizeDelay: {
      type: Number,
      default: 100
    }
  },
  computed: {
    isMediumScreen() {
      return this.innerScreenWidth < 1281 && this.innerScreenHeight < 1025;
    }
  },
  data() {
    return this._onResizeMixinHandler({});
  },
  methods: {
    /**
     * Update the `innerScreenWidth` and `innerScreenHeight` with
     * window values `window.innerWidth` and `window.innerHeight` accordingly.
     */
    _onResizeMixinHandler(storage) {
      storage = storage || this;

      if (this.$easyscreenCanvasOrientation.mode !== "auto" && this.$easyscreenCanvasOrientation.scaleTarget != null) {
        storage.innerScreenWidth = this.$easyscreenCanvasOrientation.scaleTarget.width;
        storage.innerScreenHeight = this.$easyscreenCanvasOrientation.scaleTarget.height;

        return storage;
      }

      storage.innerScreenWidth = window.innerWidth;
      storage.innerScreenHeight = window.innerHeight;

      return storage;
    }
  },
  mounted() {
    this._boundOnResizeMixinHandler = debounce(this._onResizeMixinHandler, this.resizeDelay);
    window.addEventListener("resize", this._boundOnResizeMixinHandler);
  },
  beforeDestroy() {
    if (this._boundOnResizeMixinHandler) {
      window.removeEventListener("resize", this._boundOnResizeMixinHandler);
      this._boundOnResizeMixinHandler = null;
    }
  }
};
