export default function(data) {
  const wData = data.data;
  return {
    ...data.widgetPlacement,
    contentType: "YouTubeCT",
    name: "easyscreen-youtube",
    options: {
      ...wData,
      url: wData.video,
      image: wData.imageFile,
      poster: wData.showBubble ? "bubble" : null,
      volume: parseInt(wData.volumeLevel, 10)
    }
  };
}
