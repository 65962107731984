<template>
  <easyscreen-button
    :class="[
      'easyscreen-circle-button',
      `easyscreen-circle-button_${ size }`,
      `easyscreen-circle-button_label-${ labelPlacement }`,
      `easyscreen-circle-button_label-${ labelSize || size }`
    ]"
    size="small"
    :color="color"
    :custom-color="customColor"
    :custom-active-color="customActiveColor"
  >
    <i
      v-if="iconType === 'font-icon'"
      :class="['easyscreen-circle-button--icon', icon]"
    ></i>
    <img
      v-if="iconType === 'image'"
      class="easyscreen-circle-button--icon"
      :src="icon"
    >

    <span class="easyscreen-circle-button--text">
      <slot></slot>
    </span>
  </easyscreen-button>
</template>

<style lang="less" src="./button.less" />
<style lang="less" src="./circle-button.less" />

<script>
  import EasyscreenButton from "./button.vue";

  export default {
    name: "easyscreen-circle-button",
    props: {
      active: Boolean,
      /* Icon of button url or class depends on type. */
      icon: [String, Array, Object],
      /* The type of icon: image or font icon. */
      iconType: {
        type: String,
        default: "font-icon",
        validator: (_iconType) => ["font-icon", "image"].includes(_iconType)
      },
      /*
       * Placement of the label from left or right side of icon (button).
       * The label are drawn outside of button borders.
       */
      labelPlacement: {
        type: String,
        default: "right",
        validator: (_labelPlacement) => ["left", "right", "bottom"].includes(_labelPlacement)
      },
      /* Size of the button. */
      size: {
        type: String,
        default: "big",
        validator: _size => ["small", "medium", "big"].includes(_size)
      },
      labelSize: {
        type: String,
        validator: _size => ["small", "medium", "big"].includes(_size)
      },
      /* The custom color for a button. */
      customColor: String,
      /* The custom active color for a button. */
      customActiveColor: String,
      /* The color of button. See available and default values for ./button.vue. */
      color: String
    },
    components: {
      "easyscreen-button": EasyscreenButton
    }
  };
</script>
