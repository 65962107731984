import EventEmitter from "eventemitter3";
import { clamp } from "lodash";
import debounceDrop from "@/lib/utils/debounce-drop.js";

/**
 * Checks the orientation of canvas and keeps the canvas fitted into the available space with selected ratio.
 *
 * @class CanvasOrientation
 * @augments EventEmitter3
 *
 * @property {HTMLElement} el - The tracked and scalable element.
 * @property {String} mode - The mode of instance (see `options.mode`).
 * @property {Number} scale - Current scale of `this.el` based on `this.viewportSize` and `this.scaleTarget`.
 * @property {Number} scaleOffset - The scale offset (applies on top of current scale).
 * @property {Object} scaleTarget - The default size of `this.el` see `options.scaleTarget`.
 * @property {Object} positionOffset - The offset on canvas in px of `this.el` based on current scale.
 * @property {Number} positionOffset.top
 * @property {Number} positionOffset.left
 *
 * @property {String} orientation - The getter of current orientation. One of: "portrait-orientation", "landscape-orientation".
 * @property {Object} viewportSize - The getter of viewport size (inner width and height).
 * @property {Number} viewportSize.width
 * @property {Number} viewportSize.height
 *
 * @param {Object} options
 * @param {HTMLElement} options.el - The tracked and scalable element.
 * @param {String} [options.mode="auto"] - The mode of instance:
 * - "auto" - only the tracking of orientation.
 * - "fit" - keep the `options.el` with ratio `options.scaleTarget`
 *
 * @param {Object} options.scaleTarget - The default size of `options.el`.
 * @param {Number} options.scaleTarget.width - The default width of `options.el`.
 * @param {Number} options.scaleTarget.height - The default height of `options.el`.
 */
export default class CanvasOrientation extends EventEmitter {
  constructor(options) {
    super();

    this.el = options.el;
    this.mode = options.mode || "auto";
    this.scale = 1;
    this.scaleOffset = 0;
    this.scaleTarget = options.scaleTarget;
    this.positionOffset = {
      top: 0,
      left: 0
    };
    this.init();
  }

  get orientation() {
    const viewportSize = this.scaleTarget || this.viewportSize;
    return viewportSize.height >= viewportSize.width ? "portrait-orientation" : "landscape-orientation";
  }

  get viewportSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  /**
   * Swap the `this.scaleTarget.width` and `this.scaleTarget.height`;
   */
  rotateScaleTarget() {
    const width = this.scaleTarget.width;

    this.scaleTarget.width = this.scaleTarget.height;
    this.scaleTarget.height = width;

    this.updateScale();
  }

  /**
   * Get scale based on viewport size and apply the scale to `this.el`.
   */
  updateScale() {
    if (this.mode === "no-scale") {
      return this._setScale(null);
    }

    if (this.mode === "auto") {
      this.scaleTarget = this.viewportSize;
    }

    if (!this.scaleTarget) {
      return;
    }

    if (this.mode === "fit") {
      this._updateScaleTarget();
    }

    const scaleParam = this._getScaleAxisByTargetScale(this.scaleTarget) === "x" ? "width" : "height";
    this.scale = clamp(this.viewportSize[scaleParam] / this.scaleTarget[scaleParam], 0, 1);
    this._setScale(this.scale);
  }

  /**
   * Initialize the canvas orientation (set scale and add the listener of window.resize event).
   */
  init() {
    this.updateScale();

    // Timeout for events due to next animation frame (the number of calls is usually 60 times per second).
    this._debouncedUpdateScale = debounceDrop(() => this.updateScale(), 16);
    window.addEventListener("resize", this._debouncedUpdateScale);
  }

  /**
   * Destoy the window.resize event listener if exists.
   */
  destroy() {
    if (this._debouncedUpdateScale) {
      window.removeEventListener("resize", this._debouncedUpdateScale);
      this._debouncedUpdateScale = null;
    }
  }

  /**
   * Apply the `scale` to `this.el` html element including the this.scaleOffset and this.positionOffset.
   *
   * @param {Number} scale - The element scale.
   */
  _setScale(scale) {
    this.emit("before-orientation-change", this.orientation);

    /*
     * Apply the scale offset. The multiply is used since the substruction will cause
     * different scale force for exact canvas scale.
     * For example 0.05 of step it's the 5% of unscaled canvas and 10% of already scaled to 0.5.
     */
    if (scale !== null) {
      scale = scale * (1 - this.scaleOffset);
      let offset = {
        top: (this.viewportSize.height - this.scaleTarget.height * scale) / 2 + this.positionOffset.top,
        left: (this.viewportSize.width - this.scaleTarget.width * scale) / 2 + this.positionOffset.left
      };

      this.el.style.width = this.scaleTarget.width + "px";
      this.el.style.height = this.scaleTarget.height + "px";
      this.el.style.transformOrigin = "0 0";
      this.el.style.transform = "scale(" + scale + ")";
      this.el.style.marginTop = offset.top + "px";
      this.el.style.marginLeft = offset.left + "px";
    }

    this.emit("orientation-changed", this.orientation);
  }

  /**
   * Get the scale axis based on viewport and target size.
   *
   * @param {Object} scaleTarget - The target size of element.
   * @param {Number} scaleTarget.width
   * @param {Number} scaleTarget.height
   *
   * @returns {String} "x" or "y" axis.
   */
  _getScaleAxisByTargetScale(scaleTarget) {
    const scaleX = clamp(this.viewportSize.width / scaleTarget.width, 0, 1);
    const scaleY = clamp(this.viewportSize.height / scaleTarget.height, 0, 1);
    
    return scaleY - scaleX >= 0 ? "x" : "y";
  }

  /**
   * Swap the scale target based on viewport orientation and scale target size.
   */
  _updateScaleTarget() {
    const width = this.scaleTarget.width || 0;
    const height = this.scaleTarget.height || 0;

    /* The landscape orientation. */
    if (this.viewportSize.width >= this.viewportSize.height) {
      this.scaleTarget.width = Math.max(width, height);
      this.scaleTarget.height = Math.min(width, height);
    /* Portrait orientation. */
    } else {
      this.scaleTarget.width = Math.min(width, height);
      this.scaleTarget.height = Math.max(width, height);
    }
  }
}
