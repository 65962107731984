import * as querystring from "querystring";
import lodash from "lodash";

export default function(data) {
  const urlOptions = querystring.parse(window.location.href.split("#")[1]);
  const wData = data.data;
  let componentName = "easyscreen-search--standalone";
  if (data.widgetName === "VisualShelvesCT") {
    componentName = "easyscreen-search--visual-shelf";
  }

  let widget =  {
    ...data.widgetPlacement,
    contentType: "SearchScreen",
    name: componentName,
    options: {
      title: wData.title,
      queryPrefix: wData.prefix,
      withInspirationScanner: wData.scanner === 1,
      promotedMaterials: [],
      design: window.ESCONFIG.defaultDesign,
      /* Disable the bottom navigation for the light design (ES-2402). */
      disableNavigation: "disableNavigation" in urlOptions,
      wayfinderData: {
        viewLabel: lodash.get(data, "screenOptions.map_view_label"),
        viewId: lodash.get(data, "screenOptions.map_view_id"),
        here: urlOptions.here
      }
    }
  };

  const promotedIds = (wData.recommendations || "").split(",").filter(Boolean);
  if (promotedIds.length !== 0){
    widget.getter = async function(context) {
      const promotedResult = await context.$easyscreenRequest.lmsConnector.search({
        include: promotedIds,
        step: promotedIds.length
      });
      return Object.assign({}, widget.options, { promotedMaterials: promotedResult.objects });
    };
  }

  return widget;
}

