import { get, castArray, cloneDeep, isObjectLike } from "lodash";
import parseDuration from "parse-duration";
import * as d3 from "d3-timer";

let inMemoryCache = {};
/**
 * The in memory cache with permanent storage in browser local storage.
 * @class LocalStorageCache
 *
 * @property {Object} cache - The already loaded cache (in memory cache).
 * @property {Object} storage - The cached data at local storage from local storage item with name "EasyscreenCache".
 *
 * @param {Boolean} [cleanup=true] - The flag for remove saved cache at local storage on saving error.
 * @param {String} name - The name of cache at local storage.
 * @param {Number} duration - The record lifetime in milliseconds.
 * @param {Number} [cleanupInterval=parseDuration("10m")] - The intrval of records cleanup in milliseconds.
 */
export default class LocalStorageCache {
  static get cache() {
    return inMemoryCache;
  }

  static get storage() {
    return JSON.parse(window.localStorage.getItem("EasyscreenCache") || "{}");
  }

  /** @namespace LocalStorageCache */
  /**
   * Save the storage into the borwser localstorage.
   * @static
   * @memberof LocalStorageCache
   *
   * @param {Object} storage - The cache storage for save.
   * @param {Object} [options] - Saving options.
   * @param {Boolean} [options.cleanup=true] - The flag for remove saved cache at local storage on saving error.
   */
  static setStorage(storage, options) {
    if (options && options.cleanup === false) {
      window.localStorage.setItem("EasyscreenCache", JSON.stringify(storage));
    } else {
      try {
        window.localStorage.setItem("EasyscreenCache", JSON.stringify(storage));
      } catch (err) {
        console.error("Can't save the cache storage! The storage will be flushed.", err);
        LocalStorageCache.clear();
      }
    }
  }

  /**
   * Get the cache data with name or all ready loaded cache (in memory cache).
   * @static
   * @memberof LocalStorageCache
   *
   * @param {(String|String[])} [names] - The list cache names which should be returned.
   *
   * @returns {Object} The object with selected cache storages or all ready loaded cache.
   */
  static data(names) {
    names = castArray(names).filter(name => name !== undefined);

    if (names.length === 0) {
      names = Object.keys(LocalStorageCache.cache);
    }

    return names.reduce((data, name) => {
      if (LocalStorageCache.cache[name]) {
        data[name] = LocalStorageCache.cache[name].data();
      }

      return data;
    }, {});
  }

  /**
   * Remove the permanent data for selected cache storages or to all ready loaded.
   * @static
   * @memberof LocalStorageCache
   *
   * @param {(String|String[])} [names] - The list cache names which should be removed.
   */
  static clear(names) {
    names = castArray(names).filter(name => name !== undefined);

    if (names.length === 0) {
      names = Object.keys(LocalStorageCache.cache);
    }

    names.forEach(function(name) {
      if (LocalStorageCache.cache[name]) {
        LocalStorageCache.cache[name].clear();
      }
    });
  }

  /**
   * Fill up the browser local storage with random data.
   * Has used to reproduce the cache saving error when the storage space is ended.
   * @static
   * @memberof LocalStorageCache
   */
  static fillStorage() {
    var id = Date.now() + Math.random();
    var cache = LocalStorageCache.cache.fillStorage || new LocalStorageCache({
      name: "fillStorage",
      duration: "30m"
    });
    // eslint-disable-next-line
    console.warn("The local storage will be filling until the error is appears! The local storage limit will be reached and you should reset the cache for stable easyscreen work.");
    var iterations = 25000;
    try {
      var i = 0;
      while (i < iterations) {
        // eslint-disable-next-line
        cache.set(id + "_key_" + i, "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptates, odit debitis quos sequi, non ex consequatur, adipisci necessitatibus laudantium nihil repellendus cumque distinctio dignissimos commodi? Impedit vero, magni facere eveniet.");
        if (i % 10 === 0) {
          cache._saveToStorage({ cleanup: false });
        }

        if (i % 100 === 0) {
          console.log("Write progress: " + i + "/" + iterations);
        }
        i++;
      }
    } catch (err) {
      console.warn("The local storage limit is reached.", err);
    }

    console.info("Not enought write iterations, call this function again");
  }

  constructor(options) {
    if (LocalStorageCache.cache[options.name]) {
      throw new Error("Error: Cache with name: '" + options.name + "' already exists.");
    }

    this._options = options;

    this.name = options.name;
    this.cache = this._loadFromStorage();
    this.duration = parseDuration(options.duration);

    LocalStorageCache.cache[this.name] = this;

    this._cleanupInterval = d3.interval(() => {
      this._cleanup();
    }, options.cleanupInterval || parseDuration("10m"));
  }

  /**
   * Destroy the cleanup inteval and remove the loaded storage from RAM
   * (does not remove the data from local storage).
   */
  destroy() {
    delete LocalStorageCache.cache[this.name];

    if (this._cleanupInterval) {
      this._cleanupInterval.stop();
      this._cleanupInterval = null;
    }
  }

  /**
   * Get the record with key.
   *
   * @param {String} key - The record key.
   * @param {Boolean} [noHit=false] - Does not update the last access property and amount of hits for record.
   *
   * @returns {(*|undefined)} Any cached content or undefined if the content not found.
   */
  get(key, noHit) {
    if (!this.cache[key]) {
      return;
    }

    if (this.isExpired(key)) {
      this.remove(key);

      return;
    }

    if (noHit !== true) {
      this.cache[key].access = Date.now();
      this.cache[key].hits++;

      this._saveToStorage();
    }

    return this.cache[key].value;
  }

  /**
   * Set the record value.
   *
   * @param {String} key - The record key.
   * @param {Any} value - The record value
   */
  set(key, value) {
    if (!this.cache[key]) {
      this.cache[key] = { hits: 0 };
    }

    this.cache[key] = {
      ...this.cache[key],
      value: value,
      created: Date.now(),
      expireDate: Date.now() + this.duration,
      modified: Date.now(),
      access: Date.now()
    };

    this._saveToStorage();
  }

  /**
   * Check the the record with key are expired
   *
   * @param {String} key - The record key.
   *
   * @returns {Boolean} true - record expired or not exists.
   */
  isExpired(key) {
    return !this.cache[key] || this.cache[key].expiresDate <= Date.now();
  }

  /**
   * Remove the record with key.
   *
   * @param {String} key - The record key.
   */
  remove(key) {
    if (this.cache[key]) {
      delete this.cache[key];
    }

    this._saveToStorage();
  }

  /**
   * Clear storage for this cache instance.
   */
  clear() {
    this.cache = {};
    this._saveToStorage();
  }

  /**
   * Get the cache data and instance options.
   *
   * @returns {Object} data
   * @returns {Object} data.options - The instance initizalization options - see the scheme for constructor options.
   * @returns {Object} data.data - The clone of storage data.
   */
  data() {
    return {
      options: this._options,
      data: cloneDeep(this.cache)
    };
  }

  /**
   * Load the data from local storage with instance name (`this.name`).
   */
  _loadFromStorage() {
    let storage = LocalStorageCache.storage;
    let storedCache = storage[this.name] || {};
    Object.keys(storedCache).forEach(key => {
      let recordValue = get(storedCache, `[${ key }].value`);
      /* Remove the serialized Promises from storage. */
      if (isObjectLike(recordValue) && Object.keys(recordValue).length === 0) {
        delete storedCache[key];
      }
    });

    LocalStorageCache.setStorage(storage);
    return storedCache;
  }

  /**
   * Save data of current instance to local storage with instance name (`this.name`).
   */
  _saveToStorage(options) {
    LocalStorageCache.setStorage({
      ...LocalStorageCache.storage,
      [this.name]: this.cache
    }, options);
  }

  /**
   * Cleanup the expired records from cache and save to local storage.
   */
  _cleanup() {
    Object.keys(this.cache).forEach(key => {
      if (this.isExpired(key)) {
        delete this.cache[key];
      }
    });

    this._saveToStorage();
  }
}
