import { get, clone } from "lodash";
import digitalShelfConverter from "./digital-shelf.js";
import { slidesCoverter } from "./presentation-set.js";

const EasyscreenMenuButtonPresets = {
  map: {
    icon: "/images/es-menu/map_icon.png",
    screenName: "map"
  },
  inspiration: {
    icon: "/images/es-menu/inspiration_icon.png",
    screenName: "inspiration"
  },
  loan: {
    icon: "/images/es-menu/loan_icon.png",
    screenName: "loan"
  },
  return: {
    icon: "/images/es-menu/return_icon.png",
    screenName: "return"
  },
  pay: {
    icon: "/images/es-menu/pay_icon.png",
    screenName: "pay"
  },
  renew: {
    icon: "/images/es-menu/renew_icon.png",
    screenName: "renew"
  },
  profile: {
    icon: "/images/es-menu/profile_icon.png",
    screenName: "profile"
  },
  scan: {
    icon: "/images/es-menu/scan_icon.png",
    screenName: "scan"
  }
};

async function EasyscreenMenuButton(managerSlide) {
  let button = {};

  let slideFeature = get(managerSlide, "slideData.settings.slideFeature") || "none";
  const wayfinderPosition = get(managerSlide, "slideData.settings.wayfinderPosition");
  const slideWidgets = managerSlide.widgets || [];

  if (slideFeature === "none") {
    const isInspiration = slideWidgets.length === 1 && get(slideWidgets, "[0].widgetName") === "DigitalShelfCT";

    if (isInspiration) {
      slideFeature = "inspiration";
    } else  if (wayfinderPosition) {
      slideFeature = "map";
    }
  }

  if (EasyscreenMenuButtonPresets[slideFeature]) {
    button = clone(EasyscreenMenuButtonPresets[slideFeature]);
  }

  if (slideFeature === "inspiration") {
    button.screenData = digitalShelfConverter(slideWidgets[0]).options;
  } else if (slideFeature === "map") {
    button.screenData = {
      view: wayfinderPosition.view_id,
      markId: wayfinderPosition.location_id
    };
  } else if (slideFeature === "none") {
    button.screenName = "generic";
    button.screenData = {
      slides: await slidesCoverter([managerSlide], managerSlide)
    };
  }

  button.icon = get(managerSlide, "slideData.customBg") || button.icon || "";
  button.type = get(managerSlide, "slideData.customBgType") || "image";
  button.label = get(managerSlide, "slideData.overrideTitle") || get(managerSlide, "slideData.slideName", "");

  if (!button.screenData) {
    button.screenData = {};
  }

  button.screenData.title = button.label;
  button.screenData.rawSlideData = managerSlide.slideData;

  return button.screenName ? button : null;
}

export default async function(data) {
  if (!data.popularSearchesType) {
    data.popularSearchesType = "none";
  }

  var menuData = {
    backgroundImage: data.menuBackgroundImage ? "url(" + data.menuBackgroundImage + ")" : "",
    searchEnabled: !!data.menuSearchEnabled,
    title: data.menuTitle,
    subTitle: data.menuSubtitle,
    items: [],
    tags: [],
    buttons: (await Promise.all(data.content.map(EasyscreenMenuButton))).filter(Boolean),
    buttonsColor: data.menuButtonColor,
    hasDigitalShelf: false,
    logoImage: data.menuLogoImage,
    popularSearchesType: data.popularSearchesType
  };

  const rawDigitalShelf = get(data, "menuDigitalShelf.widgets", []).find(widget => {
    return widget.widgetName === "DigitalShelfCT";
  });

  if (data.menuDigitalShelf) {

    if (rawDigitalShelf) {
      const convertedDigitalShelf = digitalShelfConverter(rawDigitalShelf);

      menuData.hasDigitalShelf = true;
      menuData.items = convertedDigitalShelf.options.items;
      menuData.tags = convertedDigitalShelf.options.tags;
    }
  }

  return menuData;
}
