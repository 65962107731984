import { get, castArray, isString, pick } from "lodash";
import { ValidationError, RequestError } from "@/lib/errors.js";
import urlJoin from "url-join";

function wrapCover(object, options) {
  if (object && object.cover && !object.cover.includes("defaultCover")) {
    if (!/^(https?:)?\/\//.test(object.cover)) {
      object.cover = urlJoin(options.$config.coversDomain, object.cover);
    }
  } else {
    object.cover = "/images/defaultCover.jpg";
    object.isDefaultCover = true;
  }

  return object;
}

export default [{
  name: "ping",
  /* the relative path used to remove the consumer hash path prefix for ping. */
  path: "../",
  method: "options"
}, {
  name: "getConsumerPublicData",
  path: "/public-data"
}, {
  name: "wrapCover",
  static(context, cover) {
    return wrapCover({ cover }, context).cover;
  }
}, {
  name: "custom",
  validator: options => {
    if (!options.url) {
      return ValidationError({
        message: "'url' option are required."
      });
    }
  },
  method: options => options.method || options.type || "get",
  path: options => options.url,
  search: options => options.search || options.query,
  data: options => options.data
}, {
  name: "branches",
  path: "/branches",
  cache: "24h"
}, {
  name: "departments",
  path: "/departments",
  search: ["pickup"],
  cache: "24h"
}, {
  name: "detail",
  path: "/detail",
  search: [
    "withExternalResources",
    "faustNumber",
    "forHandInMaterials",
    "withNoCover",
    "withOnlineMaterial"
  ],
  cache: "24h",
  beforeResolve: (body, options) => {
    if (body.title.includes("Error:")) {
      throw body;
    }

    return wrapCover(body, options);
  }
}, {
  name: "detailCover",
  path: "/detail/cover",
  search: {
    id: options => options.id || options.faustNumber
  },
  beforeResolve: (body, options) => {
    return wrapCover(body, options);
  }
}, {
  name: "holdings",
  path: "/holdings",
  search: ["faustNumber"]
}, {
  name: "availability",
  path: "/availability",
  search: ["faustNumber"]
}, {
  name: "lists",
  path: "/lists",
  search: [
    "faustNumber",
    "withCoversOnly",
    {
      "step": () => 75
    }
  ],
  cache: "24h"
}, {
  name: "trailer",
  path: "/dashboard/api/trailers/fetch",
  search: ["id", "title"],
  cache: "24h"
}, {
  name: "reserveMaterial",
  options: _options => {
    _options.reservations = castArray(_options.reservableId).filter(Boolean).map(reservation => {
      if (isString(reservation)) {
        reservation = { reservableId: reservation };
      }

      reservation.branchId = reservation.branchId || _options.branchId;
      reservation.departmentId = reservation.departmentId || _options.departmentId;

      return reservation;
    });

    _options.isSingleReservation = _options.reservations.length === 1;

    return _options;
  },
  validator: options => {
    if (options.reservations.length === 0) {
      throw new ValidationError({
        message: "No materials found for reservation."
      });
    }
  },
  method: options => options.isSingleReservation ? "get" : "post",
  path: "/patron/reserve",
  search: [
    "borrCard",
    "pinCode",
    {
      reservableId: options => options.isSingleReservation ? options.reservations[0].reservableId : undefined,
      branchId: options => options.isSingleReservation ? options.branchId : undefined,
      departmentId: options => options.isSingleReservation ? options.departmentId : undefined
    }
  ],
  data: options => !options.isSingleReservation ? options.reservations : undefined
}, {
  name: "getLoans",
  path: "/patron/loans",
  search: ["user", "pin"]
}, {
  name: "renewLoan",
  method: "post",
  path: "/patron/loan/renew",
  search: ["user", "pin", "loanId"]
}, {
  name: "getFees",
  path: "/patron/fees",
  search: ["user", "pin"]
}, {
  name: "getReservations",
  path: "/patron/reservations",
  search: [
    "user",
    "pin"
  ]
}, {
  name: "deleteReservations",
  method: "delete",
  path: "/patron/reservations",
  search: [
    "user",
    "pin",
    "reservationId"
  ]
}, {
  name: "updateReservations",
  method: "put",
  path: "/patron/reservations",
  search: [
    "user",
    "pin",
    "reservationId",
    "branchId",
    "to"
  ]
}, {
  name: "getPatronInfo",
  path: "/patron/info",
  search: [
    "user",
    "pin",
    {
      sip2User: options => get(options.$config.screenOptions, "sip2.profile")
    }
  ],
  filterEmpty: ["sip2User"]
}, {
  name: "updatePatronInfo",
  method: "put",
  path: "/patron/info",
  search: [
    "user",
    "pin"
  ],
  data: options => options.data
}, {
  name: "getPatronEmail",
  path: "/patron/email",
  search: ["user"]
}, {
  name: "materialToFaust",
  path: "/material-to-faust",
  search: [
    "materialId",
    {
      sip2User: options => get(options.$config.screenOptions, "sip2.profile")
    }
  ],
  filterEmpty: ["sip2User"],
  beforeResolve: body => {
    return body.id;
  }
}, {
  name: "loanMaterial",
  options: _options => {
    _options.materialIds = castArray(_options.materialIds).filter(Boolean);

    return _options;
  },
  validator: options => {
    const invalidPin = options.$config.loanWithoutPin !== true && !options.pin;
    const invalidCpr = !options.cpr;

    if (invalidPin || invalidCpr) {
      return ValidationError({
        message: options.$l10n("E.g. incorrect CPR or PIN")
      });
    }

    if (!options.materialIds || options.materialIds.length === 0) {
      return ValidationError({
        message: options.$l10n("Books for loan not selected.")
      });
    }
  },
  path: "/patron/loan",
  search: [
    "pin",
    {
      user: options => options.user || options.cpr,
      materialId: options => options.materialIds.join(","),
      sip2User: options => get(options.$config.screenOptions, "sip2.profile")
    }
  ],
  filterEmpty: ["sip2User"],
  beforeResolve: body => {
    let loanResult = {};

    if (Array.isArray(body)) {
      body.forEach(loanItemResult => {
        if (loanItemResult.id && loanItemResult.status === true) {
          loanResult[loanItemResult.id] = loanItemResult.dueDate;
        }
      });
    }

    if (body[0].isUserBlocked) {
      loanResult.isUserBlocked = true;
    }

    return loanResult;
  }
}, {
  name: "unloanMaterial",
  options: _options => {
    _options.materialId = castArray(_options.materialId).filter(Boolean);

    return _options;
  },
  validator: options => {
    if (!options.materialId || options.materialId.length === 0) {
      return ValidationError({
        message: options.$l10n("Books for loan not selected.")
      });
    }
  },
  path: "/patron/unloan",
  search: {
    materialId: options => options.materialId.join(","),
    sip2User: options => get(options.$config.screenOptions, "sip2.profile")
  },
  filterEmpty: ["sip2User"],
  beforeResolve: (body, options) => {
    let unloanResult = [];
    let messages = {};

    if (Array.isArray(body)) {
      body.forEach(unloanItemResult => {
        if (unloanItemResult.id && unloanItemResult.status === true) {
          unloanResult.push(unloanItemResult.id);
          if (unloanItemResult.message && ("" + unloanItemResult.message).trim()) {
            messages[unloanItemResult.id] = unloanItemResult.message;
          } else if ("sortBin" in unloanItemResult && unloanItemResult.sortBin !== "stay") {
            messages[unloanItemResult.id] = get(options.$config.screenOptions, "sip2.deliveryToSortingBinMessage");
          } else {
            messages[unloanItemResult.id] = get(options.$config.screenOptions, "sip2.defaultDeliveryMessage");
          }
        }
      });
    }

    return {
      unloanResult,
      messages,
      body
    };
  }
}, {
  name: "providers",
  path: "/providers",
  cache: "12h"
}, {
  name: "recommend",
  path: "/recommend",
  search: [
    "faustNumber",
    { step: options => options.step || 30 }
  ]
}, {
  beforeCreate: async (context, spec) => {
    try {
      /* TODO: only the methods which are initialized before this method will be available. */
      const providers = await context.$request.providers();
      const searchProvider = providers["get /search"] || "";
      if (searchProvider.includes("bibliofil")) {
        const originalOptionsHandler = spec.options;
        spec.options = _options => {
          _options.$isBibliofil = true;

          return originalOptionsHandler(_options);
        };
      }
    } catch (error) {
      console.warn("The bibliofil for search are skipped due to wrong providers response", error);
    }

    return spec;
  },
  name: "search",
  path: "/search",
  options: _options => {
    if (Array.isArray(_options.include)) {
      _options.include = _options.include.join(",");
    }

    _options.sort = _options.sort || _options.sorting;

    if (_options.$isBibliofil) {
      _options.queryType = "freetext";
      _options.queryPostfix = _options.queryPostfix || _options.facets;

      delete _options.facets;
    }

    /* Delete the flags from requests which counts by presenting in query, not by value. */
    [
      "availableFacets",
      "availableOnly",
      "withMeta",
      "withHoldings",
      "withCoversOnly"
    ].forEach(property => {
      if (_options[property] === false) {
        delete _options[property];
      }
    });

    return _options;
  },
  search: [
    "query",
    "include",
    "step",
    "queryType",
    "queryPostfix",
    "sort",
    "availableFacets",
    "availableOnly",
    "withMeta",
    "withHoldings",
    "withCoversOnly",
    "offset",
    "branchId",
    "departmentId"
  ],
  filterEmpty: [
    "query",
    "include",
    "step",
    "queryType",
    "queryPostfix",
    "sort",
    "offset",
    "branchId",
    "departmentId"
  ]
}, {
  name: "suggestions",
  path: "/search/suggestions",
  search: ["query", "step"],
  beforeResolve: (body, options) => {
    if (Array.isArray(body.hits)) {
      body.hits = body.hits.map(hit => wrapCover(hit, options));
    }

    return body;
  }
}, {
  name: "suggestionsFeedback",
  path: "/search/suggestions/feedback",
  search: ["id"]
}, {
  name: "mobileSearch",
  options: _options => {
    _options.step = _options.step || _options.amount;
    _options.upcoming = _options.$config.screenOptions.skipUpcoming !== undefined ? "false" : undefined;

    return _options;
  },
  path: options => `/${ options.type }`,
  search: [
    "step",
    "nodes",
    "vocabulary",
    "terms",
    "library",
    "upcoming",
    "language"
  ]
}, {
  name: "openingHours",
  path: "/opening-hours",
  search: ["node"]
}, {
  name: "email",
  method: "post",
  path: "/sendmail",
  data: options => pick(options, ["to", "html", "subject"]),
  beforeResolve: (body, _, rawResponse) => {
    if (body.code === "INTERNAL_ERROR") {
      throw new RequestError({
        message: body.message,
        ajaxRequest: rawResponse.request
      });
    }
  }
}, {
  name: "paymentGateway",
  path: "/patron/payment/gateway",
  search: [
    "user",
    "pin",
    "phone",
    "feeId"
  ]
}, {
  name: "extendedLists",
  cache: "24h",
  request: async function(options) {
    const [lists, recommendations] = await Promise.all([(async function() {
      try {
        return await this.$request.lists(options);
      } catch (error) {
        console.log("Can't get lists for 'extendedLists'.", error);

        return {};
      }
    }.bind(this))(), (async function() {
      const step = options.step || 15;

      try {
        const recommendations = await this.$request.recommend({
          faustNumber: options.faustNumber,
          /*
           * multiply by 2 is needed to avoid the problems if recommended
           * items will not be available or will not have a cover.
           */
          step: options.withCoversOnly ? step * 2 : step
        });

        if (recommendations.items.length === 0) {
          return [];
        }

        const foundRecommendations = await this.$request.search({
          include: recommendations.items.map(recommendation => {
            return get(recommendation, "hasPart[0].pid");
          }).filter(Boolean),
          withCoversOnly: options.withCoversOnly,
          step: step
        });

        return foundRecommendations.objects;
      } catch (error) {
        console.log("Can't get recommendations for 'extendedLists'.", error);
        return [];
      }
    }.bind(this))()]);

    return {
      ...lists,
      recommendations: recommendations
    };
  }
}];
