export default function(data) {
  const wData = data.data;

  return {
    ...data.widgetPlacement,
    contentType: "MaterialPromotionCT",
    name: "material-promotion",
    options: {
      title: wData.title,
      elements: wData.items,
      animationSpeed: parseInt(wData.animation_speed || 0, 10) * 1000,
      withMetaInformation: wData.meta_information == "1",
      layout: wData.single_view == "1" ? "single-view-carousel" : undefined
    }
  };
}
