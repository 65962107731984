export default [{
  name: "content",
  path: "/content",
  search: [
    {
      screen: options => options.screenId
    },
    "date",
    "time",
    "lastUpdateDate",
    "lastUpdateTime"
  ],
  easyscreenProxy: true
}, {
  name: "preview",
  path: "/page/preview",
  search: {
    pageId: options => options.screenId,
    languageId: options => options.languageId || 1,
    status: () => "preview"
  },
  easyscreenProxy: true
}, {
  name: "popularSearches",
  path: "/statistic/user",
  search: {
    type: () => "make_search",
    limit: options => options.limit || 4
  },
  beforeResolve: body => {
    return (body.stats || []).map(stat => {
      return {
        title: stat.item.includes("%") ? decodeURIComponent(stat.item) : stat.item,
        frequency: stat.clicks
      };
    });
  },
  easyscreenProxy: true
}, {
  name: "statisticHeartbeat",
  method: "post",
  path: "/statistic/heartbeat",
  data: options => ({
    screen: options.screen,
    timestamp: Math.floor(Date.now() / 1000),
    step: options.step
  }),
  easyscreenProxy: true
}, {
  name: "statisticAction",
  method: "post",
  path: "/statistic/user",
  data: options => {
    return Object.assign({
      timestamp: Math.floor(Date.now() / 1000)
    }, options);
  },
  easyscreenProxy: true
}];
