<template>
  <div class="easyscreen-video-native">
    <div
      v-if="credits"
      class="easyscreen-video-native--credits"
    >
      <span class="easyscreen-video-native--credits-label">{{ _l10n("Credit by") }}:</span>
      {{ credits }}
    </div>
    <video-poster
      v-if="showPoster"
      :type="poster"
      :image="image"
      :description="description"
      @play="play"
    />
    <template v-else>
      <video
        ref="videoPlayer"
        class="easyscreen-video-native--player"
        :poster="image"
        :muted="muted"
        @play="(event) => {
          isPlaying = true;
          $emit('playing', event);
        }"
        @pause="(event) => {
          isPlaying = false;
          $emit('paused', event);
        }"
        @error="(event) => {
          isPlaying = false;
          $emit('error', event);
        }"
      >
        <source :src="url" />
      </video>
      <div
        v-if="isPlaying && isMuted"
        class="easyscreen-video-native--button easyscreen-video-native--unmute-button"
        @click="unmute"
      >
        <i class="fa fa-volume-up"></i>
      </div>
      <div
        v-if="!isPlaying"
        class="easyscreen-video-native--button easyscreen-video-native--play-button"
        @click="play"
      >
        <i class="fa fa-play"></i>
      </div>
      <div
        v-if="overlay"
        class="easyscreen-video-native--overlay"
        @click="() => {
          if (!isPlaying) {
            play();
          } else {
            unmute();
          }
        }"
      ></div>
    </template>
  </div>
</template>

<style src="./video-native.less" lang="less"></style>

<script>
  import l10n from "@/lib/localization/localization.js";

  import VideoPoster from "./video-poster.vue";

  export default {
    name: "video-native",
    props: {
      /* The url of html5 video. */
      url: String,
      /* The mute option of video (will be applied if possible). */
      muted: {
        type: Boolean,
        default: false
      },
      /* Enables autoplay. */
      autoplay: {
        type: Boolean,
        default: true
      },
      /* The time from which the video should be started. */
      startTime: {
        type: Number,
        default: 0
      },
      /* Flag for draw the overlay which prevents any interaction. */
      overlay: {
        type: Boolean,
        default: true
      },
      /* The default volume of video (video will be muted with autoplay). */
      volume: {
        type: [String, Number],
        default: 80
      },
      /* The poster image. The autoplay should be disabled for use. */
      image: String,
      /* The credits of the video. */
      credits: String,
      /* The video description. The autoplay should be disabled for use. */
      description: String,
      /* The type of video poster. See the available options for ./video-poster.vue. */
      poster: String
    },
    watch: {
      volume: "setVolume"
    },
    computed: {
      isMuted: {
        cache: false,
        get() {
          return this.$refs.videoPlayer && this.$refs.videoPlayer.muted;
        }
      }
    },
    data() {
      return {
        showPoster: !this.autoplay && this.description,
        isPlaying: false
      };
    },
    methods: {
      /**
       * Set the sound volume.
       * Set of the html5 native attribute `.volume` to `volume / 100`.
       *
       * @param {(Number|String)} volume - The volume in integer range from 0 to 100.
       */
      setVolume(volume) {
        if (!this.$refs.videoPlayer) {
          return;
        }

        this.$refs.videoPlayer.volume = parseInt(volume, 10) / 100;
      },
      /**
       * Play the video from start or continue and unmute.
       * Calling of the html5 native api `.play`.
       * @async
       */
      async play() {

        if (this.showPoster) {
          this.showPoster = false;
          await this.$nextTick();
        }

        this.setVolume(this.volume);

        if (!this.$refs.videoPlayer || this.$refs.videoPlayer.paused === false) {
          return;
        }

        try {
          await this.unmute();
          await this.$refs.videoPlayer.play();
        } catch (error) {
          await this.mute();
          if (this.$refs.videoPlayer) {
            await this.$refs.videoPlayer.play();
          }
        }
      },
      /**
       * Pause the video.
       * Calling of the html5 native api `.pause`.
       * @async
       */
      async pause() {
        if (!this.$refs.videoPlayer) {
          return;
        }

        this.$refs.videoPlayer.pause();
        return this.$forceUpdate();
      },
      /**
       * Stop the video.
       * Calling of the html5 native api `.pause` and set the attribute `currentTime` to `0`.
       * @async
       */
      async stop() {
        if (!this.$refs.videoPlayer) {
          return;
        }

        this.pause();
        this.$refs.videoPlayer.currentTime = 0;

        this.$emit("stopped");
        return this.$forceUpdate();
      },
      /**
       * Mutes the player.
       * Set of the html5 native attribute `.muted` to `true`.
       * @async
       */
      async mute() {
        if (!this.$refs.videoPlayer || this.$refs.videoPlayer.muted === true) {
          return;
        }

        this.$refs.videoPlayer.muted = true;
        await this.$forceUpdate();
        this.$emit("muted");
      },
      /**
       * Unmutes the player.
       * Set of the html5 native attribute `.muted` to `false`.
       * @async
       */
      unmute() {
        if (!this.$refs.videoPlayer || this.$refs.videoPlayer.muted === false) {
          return;
        }

        this.$refs.videoPlayer.muted = false;
        this.$forceUpdate();
        this.$emit("unmuted");
      },
      /* Proxy for localization function. */
      _l10n: l10n
    },
    async mounted() {
      if (this.autoplay) {
        await this.play();
      }
    },
    components: {
      "video-poster": VideoPoster
    }
  };
</script>
