<script>
  /**
   * @file
   * The component which allows to user the processed vue component into template.
   * Like the single element from slot variable, or manually initialized vue component.
   */
  import { get, isString } from "lodash";
  export default {
    name: "get-component",
    props: ["data", "path"],
    render() {
      if (this.path == null)
        return this.data;

      let path = this.path;
      if (!isString(path) && path.toString) {
        path = path.toString();
      }

      return get(this.data, path);
    }
  };
</script>
