var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'library-events-tile-content',
    { 'library-events-tile-content_two-elements': _vm.amountOfElements === 2 }
  ]},[(_vm.title)?_c('h1',{staticClass:"library-events-tile-content--title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_vm._l((_vm.arrayOfElements),function(element){return _c('div',{key:element.key,class:[
      'library-events-tile-content--element',
      `library-events-tile-content--element_${ element.type }`
    ],style:({
      backgroundImage: element.image ? `var(--background-overlay), url('${ element.image }')` : null,
      '--element-color': _vm.color
    }),on:{"click":() => _vm.$emit('open-element', element)}},[(element.location && element.type === 'event')?_c('h2',{staticClass:"library-events-tile-content--element-location"},[_vm._v(" "+_vm._s(_vm._adjustLocation(element.location))+" ")]):_vm._e(),(element.location && _vm._isAvailableWayfinderLocation(element.location))?_c('i',{staticClass:"library-events-tile-content--element-map-marker fal fa-map-marker-alt",on:{"click":function($event){$event.stopPropagation();return (() => _vm.$emit('open-wayfinder', _vm._generateWayfinderUrlByLocation(element.location))).apply(null, arguments)}}}):_vm._e(),_c('h1',{staticClass:"library-events-tile-content--element-title"},[_vm._v(" "+_vm._s(_vm._cutText(element.title, 30))+" ")]),(element.organizer)?_c('div',{staticClass:"library-events-tile-content--element-organizer"},[_vm._v(" "+_vm._s(element.organizer)+" ")]):_vm._e(),_c('div',{staticClass:"library-events-tile-content--element-date"},[(!_vm.subType.includes('cinema'))?_c('div',[_vm._v(" "+_vm._s(_vm._getElementDate(element))+" ")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm._getElementTime(element))+" ")])])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }