<template>
  <div
    class="welcome-screen-tile"
    :style="{
      borderRightColor: color
    }"
  >
    <div class="welcome-screen-tile--wrapper">
      <div
        class="welcome-screen-tile--content"
        :style="{
          height: _getContentHeight()
        }"
        @click="() => {
          if (actions.length !== 0 && contentAction) {
            $emit('action', actions[0].id)
          }
        }"
      >
        <slot></slot>
      </div>
      <div class="welcome-screen-tile--actions" ref="actions">
        <div
          v-for="action in actions"
          :key="action.title"
          class="welcome-screen-tile--action"
          @click="() => $emit('action', action.id)"
        >
          <i
            v-if="action.icon"
            :class="['welcome-screen-tile--action-icon', action.icon]"
            :style="{
              color: color
            }"
          ></i>
          <span>
            {{ action.title || _l10n("Show more") }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./welcome-screen-tile.less" lang="less"></style>

<script>
  import l10n from "@/lib/localization/localization.js";
  import htmlElementHeight from "@/lib/utils/html-element-height.js";

  export default {
    name: "welcome-screen-tile",
    props: {
      color: String,
      contentAction: {
        type: Boolean,
        default: true
      },
      actions: {
        type: Array,
        default: () => ([])
      }
    },
    methods: {
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Get the css style for content area height based on `this.$refs.actions` height.
       */
      _getContentHeight() {
        if (this.$refs.actions) {
          return `calc(100% - ${ htmlElementHeight(this.$refs.actions) }px)`;
        }
      }
    }
  };
</script>
