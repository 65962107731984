<template>
  <div class="easyscreen-youtube">
    <video-poster
      v-if="showPoster"
      :type="poster"
      :image="image"
      :description="description"
      @play="play"
    />
    <template v-else>
      <youtube-native
        ref="youtube"
        width="100%"
        height="100%"
        :video-id="videoId || _getIdFromUrl(url)"
        :player-vars="{
          autoplay: autoplay ? 1 : 0,
          autohide: 1,
          controls: 0,
          disablekb: 1,
          fs: 1,
          modestbranding: 1,
          mute: muted !== undefined ? muted : autoplay ? 1 : 0,
          start: startTime,
          showinfo: 0,
          rel: 0,
          loop: 1
        }"
        @playing="(event) => {
          isPlaying = true;
          $emit('playing', event);
        }"
        @paused="(event) => {
          isPlaying = false;
          $emit('paused', event);
        }"
        @ended="(event) => {
          isPlaying = false;
          $emit('ended', event);
        }"
        @buffering="(event) => $emit('buffering', event)"
        @cued="(event) => $emit('cued', event)"
        @error="(event) => {
          isPlaying = false;
          $emit('error', event)
        }"
        @ready="(event) => {
          isReady = true;
          $emit('ready', event)
          _onReady(event);
        }"
        @unstarted="(event) => {
          $emit('unstarted', event)
          _onUnstarted(event);
        }"
      />
      <div
        v-if="isMuted"
        class="easyscreen-youtube--button easyscreen-youtube--unmute-button"
        @click="unmute"
      >
        <i class="fa fa-volume-up"></i>
      </div>
      <div
        v-if="overlay"
        class="easyscreen-youtube--overlay"
        @click="() => {
          if (!autoplay) {
            play();
          } else {
            unmute();
          }
        }"
      ></div>
    </template>
  </div>
</template>

<style lang="less" src="./youtube.less"></style>

<script>
  import  getIdFromUrl from "get-youtube-id";

  import VideoPoster from "../video/video-poster.vue";
  import YoutubeNative from "./youtube-native.vue";

  export default {
    name: "easyscreen-youtube",
    props: {
      /* The url of youtube video. */
      url: String,
      /* The mute option of youtube video (will be applied if possible). */
      muted: {
        type: Boolean,
        default: false
      },
      /* The id of youtube video, the videoId has bigger proirity in usage than url. */
      videoId: String,
      /* Enables autoplay. */
      autoplay: {
        type: Boolean,
        default: true
      },
      /* The time from which the video should be started. */
      startTime: {
        type: Number,
        default: 0
      },
      /* Flag for draw the overlay which prevents any interaction. */
      overlay: {
        type: Boolean,
        default: true
      },
      /* The default volume of video (video will be muted with autoplay). */
      volume: {
        type: [String, Number],
        default: 80
      },
      /* The poster image. The autoplay should be disabled for use. */
      image: String,
      /* The video description. The autoplay should be disabled for use. */
      description: String,
      /* The type of video poster. See the available options for ../video/video-poster.vue. */
      poster: String
    },
    data() {
      return {
        isReady: false,
        isPlaying: false,
        showPoster: !this.autoplay && this.description,
        isMuted: this.muted
      };
    },
    methods: {
      /**
       * Play the video from start or continue.
       * Calling of the youtube api `.playVideo`.
       * @async
       */
      async play() {
        if (this.showPoster) {
          this.showPoster = false;
          await this.$nextTick();
        }

        this.isPlaying = true;

        if (!this.isReady)
          return;

        return this.$refs.youtube.player.playVideo();
      },

      /**
       * Pause the video.
       * Calling of the youtube api `.pauseVideo`.
       */
      pause() {
        this.$refs.youtube.player.pauseVideo();
      },

      /**
       * Stop the video.
       * Calling of the youtube api `.stopVideo`.
       */
      stop() {
        this.$refs.youtube.player.stopVideo();
      },

      /**
       * Set the sound volume.
       * Calling of the youtube api `.setVolume`.
       *
       * @param {(Number|String)} volume - The volume in integer range from 0 to 100.
       */
      setVolume(volume) {
        this.$refs.youtube.player.setVolume(volume);
      },

      /**
       * Mutes the player.
       * Calling of the youtube api `.mute`.
       */
      async mute() {
        if (this.isMuted === true) {
          return;
        }

        this.isMuted = true;
        this.$emit("muted");
        return this.$refs.youtube.player.mute();
      },

      /**
       * Unmutes the player.
       * Calling of the youtube api `.unMute`.
       */
      async unmute() {
        if (this.isMuted === false) {
          return;
        }

        this.isMuted = false;
        this.$emit("unmuted");
        return this.$refs.youtube.player.unMute();
      },

      /**
       * Checks if the player are are initialized and muted.
       * Calling of the youtube api `.isMuted`.
       * @async
       *
       * @returns {Boolean} true if the player is muted, false if not.
       */
      isMutedNative() {
        return this.$refs.youtube && this.$refs.youtube.player.isMuted();
      },

      /**
       * Parse a youtube url returning the video ID. (https://www.npmjs.com/package/vue-youtube#vmyoutubegetidfromurl)
       *
       * @param {String} url - The url of the youtube video.
       * @param {Object} [options] - The parsing options.
       * @param {Boolean} [options.fuzzy=true] - Disables the exact url matching.
       *
       * @returns {String} The video id.
       */
      _getIdFromUrl: getIdFromUrl,

      /**
       * Ready event handler.
       * Sets the volume and play the video if autoplay is enabled.
       */
      async _onReady() {
        if (this.autoplay || this.isPlaying) {
          await this.setVolume(this.volume);
          await this.play();
        }
      },
      /**
       * Youtube player unstart handler.
       * Mutes the video and play video again.
       */
      async _onUnstarted() {
        if (this.autoplay || this.isPlaying) {
          await this.mute();
          await this.play();
        }
      }
    },
    components: {
      "video-poster": VideoPoster,
      "youtube-native": YoutubeNative
    }
  };
</script>
