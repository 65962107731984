import moment from "moment";
import { default as l10n, load } from "@/lib/localization/localization.js";

const definedLocales = [];
function initMomentLocale(locale) {
  if (definedLocales.includes(locale)) {
    return;
  }

  definedLocales.push(locale);
  moment.locale(locale, {
    week: {
      dow: 1
    },
    months: [
      l10n("January"),
      l10n("February"),
      l10n("March"),
      l10n("April"),
      l10n("May"),
      l10n("June"),
      l10n("July"),
      l10n("August"),
      l10n("September"),
      l10n("October"),
      l10n("November"),
      l10n("December")
    ],
    shortMonths: [
      l10n("jan"),
      l10n("feb"),
      l10n("mar"),
      l10n("apr"),
      l10n("may"),
      l10n("jun"),
      l10n("jul"),
      l10n("aug"),
      l10n("sept"),
      l10n("oct"),
      l10n("nov"),
      l10n("dec")
    ],
    weekdays: [
      l10n("Sunday"),
      l10n("Monday"),
      l10n("Tuesday"),
      l10n("Wednesday"),
      l10n("Thursday"),
      l10n("Friday"),
      l10n("Saturday")
    ],
    weekdaysShort: [
      l10n("Sun"),
      l10n("Mon"),
      l10n("Tue"),
      l10n("Wed"),
      l10n("Thu"),
      l10n("Fri"),
      l10n("Sat")
    ]
  });

  moment.locale(locale);
}
/**
 * Set the easyscreen localization of months and weekdays in moment.
 * The localization will be applied globally of moment library.
 * @async
 *
 * @returns {Promise<Boolean>} - `true` will be returned only in case when
 * the localization is loaded and applied.
 */
export default async function setEasyscreenLocalization(locale, localizationServiceOptions) {
  const loaded = await load(locale, localizationServiceOptions);
  if (!loaded) {
    initMomentLocale("es-en");
    return false;
  }

  initMomentLocale(locale);

  return true;
}
