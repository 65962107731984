import * as querystring from "querystring";
import { get } from "lodash";
import { queue } from "async";
import asyncTimeout from "@/lib/utils/async-timeout.js";
import LocalStorageCache from "@/lib/utils/local-storage-cache.js";

export const preloadQueue = queue(async function(task) {
  const externalResourcesEnabled = task.context.$easyscreenConfig.get("enable.externalResources");
  const videosFromYoutubeEnabled = task.context.$easyscreenConfig.get("enable.videosFromYT");
  const detailResponse = await task.context.$easyscreenRequest.lmsConnector.detail({
    faustNumber: task.id,
    withExternalResources: externalResourcesEnabled || videosFromYoutubeEnabled
  });

  if (detailResponse.code === "INTERNAL_ERROR") {
    throw detailResponse;
  }
}, 5);

export default function(data) {
  const urlOptions = querystring.parse(window.location.href.split("#")[1]);
  const wData = data.data;
  const tags = wData.themes.items.map(shelf => shelf.theme);
  const materials = wData.themes.items.map(shelf => {
    return Object.keys(get(shelf, "settings.items", {})).map(materialId => {
      const material = get(shelf, `settings.items[${ materialId }]`);

      return {
        id: material.id || material.faustNumber,
        type: material.type,
        title: material.title,
        author: material.author,
        year: material.year,
        cover: material.cover || "/images/defaultCover.jpg",
        faustNumber: material.faustNumber
      };
    });
  });
  const widgetHeight = (data.options[window.canvasOrientation] || data.options.vertical).location.height;

  return {
    ...data.widgetPlacement,
    contentType: "DigitalShelfCT",
    /* TODO: add the usage of digital shelf skin to manager. For now the skin replaced globally. */
    name: window.ESCONFIG.defaultDesign === "light" ? "digital-shelf-light" : "digital-shelf",
    settings: function(context) {
      if (wData.wait_user && !urlOptions.standBy) {
        context.$easyscreenScreenActivity.changePendingTime(parseInt(wData.wait_user, 10) * 1000);
      }

      if (!("disableDigitalShelfPreload" in urlOptions)) {
        /* Run the preload in background mode for more faster digital shelf render. */
        setTimeout(async () => {
          const externalResourcesEnabled = context.$easyscreenConfig.get("enable.externalResources");
          const videosFromYoutubeEnabled = context.$easyscreenConfig.get("enable.videosFromYT");
          const withExternalResources = externalResourcesEnabled || videosFromYoutubeEnabled;
          const detailCache = LocalStorageCache.cache.detail;

          for (let themeIndex in materials) {
            for (let material of materials[themeIndex]) {
              const detailSearch = {
                faustNumber: material.id,
                withExternalResources
              };

              if (detailCache) {
                // skip timeout when material already cached.
                const cachedMaterial = detailCache.get(JSON.stringify(detailSearch), true);
                if (cachedMaterial)
                  continue;
              }

              try {
                await context.$easyscreenRequest.lmsConnector.detail(detailSearch);
              } catch (_) {
                // continue preload ignorings the errors
              }
              /* Allow the other tasks to run. */
              await asyncTimeout(300);
            }

            console.info(`Preload for materials from "${ tags[themeIndex] }" finished.`);
          }
        }, 5000);
      }
    },
    options: {
      styleType: widgetHeight,
      title: wData.custom_title || undefined,
      tags: tags,
      items: materials,
      /* Disable the bottom navigation for the light design (ES-2402). */
      disableNavigation: "disableNavigation" in urlOptions,
      request: {
        shelves: wData.themes.items.map(function(shelf) {
          return { options: shelf.settings };
        })
      },
      wayfinderData: {
        viewLabel: get(data, "screenOptions.map_view_label"),
        viewId: get(data, "screenOptions.map_view_id"),
        here: urlOptions.here
      },
      // Disable suggested lists when digital shelf height is not 100%,
      // since in material view will not enough space for this.
      // Not full width digital shelf used as part of video host template.
      suggestedLists: widgetHeight < 100 ? false : undefined,
      animationInterval: wData.animate && wData.animate != "0" ? parseInt(wData.animate, 10) * 1000 : undefined
    }
  };
}
