export default [
  {
    //tile one (way finder)
    style: {
      top: "60px",
      left: "60px",
      width: "420px",
      height: "302px"
    },
    line: [539, 650, 267, 363]
  },
  {
    // tile two (opening hours)
    style: {
      top: "60px",
      right: "60px",
      width: "420px",
      height: "302px"
    },
    line: [539, 650, 815, 363]
  },
  {
    // tile three (library events)
    style: {
      top: "515px",
      left: "60px",
      width: "260px",
      height: "644px"
    },
    line: [539, 650, 320, 837]
  },
  {
    // tile fourth (node list)
    style: {
      top: "515px",
      right: "60px",
      width: "260px",
      height: "644px"
    },
    line: [539, 650, 760, 837]
  },
  {
    // tile five (???)
    style: {
      top: "993px",
      left: "410px",
      width: "260px",
      height: "302px"
    },
    line: [539, 650, 539, 993]
  },
  {
    // tile six (???)
    style: {
      top: "1448px",
      left: "60px",
      width: "260px",
      height: "302px"
    },
    line: [539, 650, 205, 1448]
  },
  {
    // tile seven (???)
    style: {
      top: "1448px",
      left: "410px",
      width: "260px",
      height: "302px"
    },
    line: [539, 650, 539, 1448]
  },
  {
    // tile eight (???)
    style: {
      top: "1448px",
      right: "60px",
      width: "260px",
      height: "302px"
    },
    line: [539, 650, 875, 1448]
  }
];
