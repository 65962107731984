import { trim } from "lodash";

/**
 * Parse the css transform matrix.
 * See more about css matrix: https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/matrix().
 *
 * @param {String} matrix - The css matrix.
 *
 * @returns {Object} components - The parsed css matrix.
 * @returns {Number} components.scaleX - Scale by x axis in px.
 * @returns {Number} components.scaleY - Scale by y axis in px.
 * @returns {Number} components.skewY - Skew by x axis in px.
 * @returns {Number} components.skewX - Skew by y axis in px.
 * @returns {Number} components.translateX - Translate by x axis in px.
 * @returns {Number} components.translateY - Translate by y axis in px.
 */
function parseCSSMatrix(matrix) {
  matrix = matrix.replace(/\s+/g, "");
  let components = {
    scaleX: 1,
    scaleY: 1,
    skewY: 0,
    skewX: 0,
    translateX: 0,
    translateY: 0
  };

  /* eslint-disable */
  let a, b, c, d, tx, ty, _;
  if (matrix.includes("matrix3d")) {
    [a, b, _, _, c, d, _, _, _, _, _, _, tx, ty, _, _] = trim(matrix.replace("matrix3d", ""), "()").split(",");
  } else if (matrix.includes("matrix")) {
    [a, b, c, d, tx, ty] = trim(matrix.replace("matrix", ""), "()").split(",");
  }
  /* eslint-enable */

  components.scaleX = a || 1;
  components.skewY = b;
  components.skewX = c;
  components.scaleY = d || 1;
  components.translateX = tx;
  components.translateY = ty;

  Object.keys(components).forEach(key => {
    components[key] = parseFloat(components[key]);
  });

  return components;
}

export default {
  data() {
    return {
      orientation: this.$easyscreenCanvasOrientation.orientation
    };
  },
  methods: {
    /**
     * Checks if the orientaion is landscape (the width of screen is bigger than height).
     *
     * @returns {Boolean} `true` - if orientation is landscape, `flase` otherwise.
     */
    _isLandscape() {
      return this.orientation === "landscape-orientation";
    },
    /**
     * Checks if the orientaion is portrait (the height of screen is bigger than width).
     *
     * @returns {Boolean} `true` - if orientation is portrait, `flase` otherwise.
     */
    _isPortrait() {
      return this.orientation === "portrait-orientation";
    },
    /**
     * Apply the screen scale (the scale of html canvas, used in development mode or with the &fit query paramter).
     *
     * @param {Number} number - The number for scale (e.g., with element width in pixel or other units).
     *
     * @returns {Number} The scaled number in the same units.
     */
    _applyScreenScale(number) {
      return number / (this.$easyscreenCanvasOrientation.scale * (1 - this.$easyscreenCanvasOrientation.scaleOffset));
    },
    /**
     * The point in euclidean space.
     *
     * @typedef {Object} EuclideanPoint
     *
     * @property {Number} x - The distance to point by X axis.
     * @property {Number} y - The distance to point by Y axis.
     */
    /**
     * Apply the scale of all parent to point in euclidean space.
     *
     * @param {HTMLElement} node - The root element of the point.
     * @param {EuclideanPoint} point - The point to apply the scale.
     * @param {Object} [options] - The scale options.
     * @param {HTMLElement} [options.stopNode] - The node on which scale calculation will be stopped.
     *
     * @retuns {EuclideanPoint} - Scaled point.
     */
    _applyParentsScale(node, point, options) {
      options = options || {};

      let totalScaleX = 1, totalScaleY = 1;
      while (node && node !== options.stopNode) {
        let { scaleX, scaleY } = parseCSSMatrix(window.getComputedStyle(node).transform);
        totalScaleX *= scaleX;
        totalScaleY *= scaleY;
        node = node.parentElement;
      }

      return { x: point.x / totalScaleX, y: point.y / totalScaleY };
    },
    /**
     * Apply the scale of all parents from selected html element for DOMRect.
     *
     * @param {HTMLElement} node - The element from which scale will be counts.
     * @param {DOMRect} boundings - The element boundigns. See more https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect.
     *                    Only the "x", "y", "width" and "height" properties is used. Due to this you can replace the DOMRect by an object
     *                    with similar structure.
     *
     * @returns {Object} - The scaled boundings. Properties are fully matched to DOMRect.
     */
    _applyParentsScaleToBoundings(node, boundings) {
      let htmlBoundings = document.querySelector("html").getBoundingClientRect();
      let bodyStyles = window.getComputedStyle(document.querySelector("body"));
      let scaledTargetPosition = this._applyParentsScale(node, boundings);
      [{
        /* Apply the translation of "fit" feature. */
        x: parseFloat(bodyStyles.marginLeft),
        y: parseFloat(bodyStyles.marginTop),
        stopNode: document.querySelector("html")
      }, {
        /* Apply the translation and scale of dev canvas scale and transform. */
        x: parseFloat(htmlBoundings.x),
        y: parseFloat(htmlBoundings.y),
        scaled: true
      }].forEach(datum => {
        let scaled = this._applyParentsScale(node, datum, { stopNode: datum.stopNode });

        scaledTargetPosition.x -= scaled.x;
        scaledTargetPosition.y -= scaled.y;
      });

      let scaletTargetSize = this._applyParentsScale(node, {
        x: boundings.width,
        y: boundings.height
      });

      return {
        x: scaledTargetPosition.x,
        y: scaledTargetPosition.y,
        top: scaledTargetPosition.y,
        left: scaledTargetPosition.x,
        width: scaletTargetSize.x,
        height: scaletTargetSize.y,
        bottom: scaledTargetPosition.y + scaletTargetSize.y,
        right: scaledTargetPosition.x + scaletTargetSize.x
      };
    },
    /**
     * Handler of `before-orientation-change` event.
     * @fires orientation#before-orientation-change
     * 
     * @param {String} orientation - The orientation of canvas.
     */
    _onBeforeOrientationChange(orientation) {
      /**
       * The event before the orientation change.
       *
       * @event orientation#before-orientation-change
       * @type {String} orientation
       */
      this.$emit("before-orientation-change", orientation);
    },
    /**
     * Handler of `orientation-changed` event.
     * @fires orientation#before-orientation-change
     * @async
     * 
     * @param {String} orientation - The orientation of canvas.
     */
    async _onOrientationChanged(orientation) {
      this.orientation = orientation;
      await this.$nextTick();
      /**
       * The event after the orientation changed.
       *
       * @event orientation#orientation-changed
       * @type {String} orientation
       */
      this.$emit("orientation-changed", orientation);
    }
  },
  created() {
    this.$easyscreenCanvasOrientation.on("before-orientation-change", this._onBeforeOrientationChange);
    this.$easyscreenCanvasOrientation.on("orientation-changed", this._onOrientationChanged);
  },
  beforeDestroy() {
    this.$easyscreenCanvasOrientation.off("before-orientation-change", this._onBeforeOrientationChange);
    this.$easyscreenCanvasOrientation.off("orientation-changed", this._onOrientationChanged);
  }
};
