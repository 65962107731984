import EventEmitter from "eventemitter3";
import { isFunction } from "lodash";
import * as d3 from "d3-timer";

/* Compatible with jquery easings. */
const animationEasings = {
  linear(_, currentTime, startValue, endValue, totalTime) {
    return (currentTime / totalTime) * endValue + startValue;
  },
  easeOutQuart(_, currentTime, startValue, endValue, totalTime) {
    return -endValue * ((currentTime = currentTime / totalTime - 1) * currentTime * currentTime * currentTime - 1) + startValue;
  },
  easeOutQuad(_, currentTime, startValue, endValue, totalTime) {
    return -endValue * (currentTime /= totalTime) * (currentTime - 2) + startValue;
  }
};

/**
 * The event fires when animation started playing.
 *
 * @event Animate#started
 */

/**
 * The event fires on every animation frame,
 *
 * @event Animate#tic
 */

/**
 * The event fires when animation stopped manually or finished.
 *
 * @event Animate#stopped
 * @type {Boolean} - The flag of force stop (animation is not completely finished).
 */

/**
 * The event fires when animation finished.
 *
 * @event Animate#done
 * @type {Boolean} - The flag of force stop (animation is not completely finished).
 */


/**
 * @class Animate
 * @augments EventEmitter3
 *
 * @fires Animate#started
 * @fires Animate#tic
 * @fires Animate#stopped
 * @fires Animate#done
 *
 * @param {Object} options - Constructor options.
 * @param {Number} [options.fps=60] - The amount of animation calls at the second.
 * @param {Function<*, Number, Number, Number, Number>} [options.easing(_, currentTime, startValue, endValue, totalTime)] - The animation easing.
 * @param {String} [options.easingName] - The name of one defined easing: linear, easeOutQuart (default), easeOutQuad.
 * @param {Number} options.duration - The total duration of animation.
 * @param {Object} options.from - The start values to animate.
 * @param {Object} options.to - The end animation values.
 * @param {Function<Object, Number>} options.tic(currentValues, elapsedTime) - The callback of animation tic.
 * @param {Function<Boolean>} options.done(isForceStop) - The callback animation end.
 */
export default class Animate extends EventEmitter {
  constructor(options) {
    super();

    this.fps = options.fps || 60;
    this.easing = options.easing || animationEasings[options.easingName] || animationEasings.easeOutQuart;
    this.totalTime = options.duration;
    this.elapsedTime = 0;
    this.from = options.from;
    this.to = options.to;

    this.onTic = options.tic;
    this.onDone = options.done;
  }

  get currentValues() {
    return Object.keys(this.from).reduce((currentValues, key) => {
      const from = Math.min(this.from[key], this.to[key]);
      const to = Math.max(this.from[key], this.to[key]);
      const direction = Math.sign(this.to[key] - this.from[key]);

      currentValues[key] = this.from[key] + direction * this.easing(null, this.elapsedTime, 0, to - from, this.totalTime);
      return currentValues;
    }, {});
  }

  /**
   * Start animation
   *
   * @param {String} [elapsedTime=0] - Started elapsed time, range from 0 to this.duration.
   */
  start(elapsedTime) {
    this.stop();
    this.elapsedTime = elapsedTime || 0;

    let lastTic = Date.now();
    this._animationInterval = d3.interval(() => {
      const currentTic = Date.now();

      this.elapsedTime += currentTic - lastTic;
      lastTic = currentTic;

      this._onTic();

      if (this.elapsedTime >= this.totalTime) {
        this._stop();
      }
    }, Math.floor(1000 / this.fps));

    this.emit("started");

    return new Promise(resolve => {
      this.once("stopped", (forceStop) => {
        resolve(forceStop);
      });
    });
  }

  /**
   * Stop the animation. Will emit the force stop event.
   */
  stop() {
    return this._stop(true);
  }

  /**
   * Internal stop animation method.
   *
   * @param {Boolean} force - Is force stop (true) or animaiton finished (false)
   */
  _stop(force) {
    if (this._animationInterval) {
      this._animationInterval.stop();
      this._animationInterval = null;
      if (!force) {
        this.elapsedTime = this.totalTime;
        this._onTic();
      }
      this._onDone(force);
    }
  }

  /**
   * Internal animation tic callback.
   */
  _onTic() {
    this.emit("tic");

    if (isFunction(this.onTic)) {
      this.onTic(this.currentValues, this.elapsedTime);
    }
  }

  /**
   * Internal animation done callback.
   */
  _onDone(forceStop) {
    this.emit("stopped", forceStop);
    this.emit("done", forceStop);

    if (isFunction(this.onDone)) {
      this.onDone(forceStop);
    }
  }
}
