var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'easyscreen-button',
    `easyscreen-button_${ _vm.corners }`,
    _vm.color ? `color-${ _vm.color }` : '',
    `${ _vm.size }-button`,
    _vm.modificator,
    { active: _vm.active }
  ],style:({ '--custom-color': this.customColor, '--custom-active-color': this.customActiveColor }),attrs:{"disabled":_vm.disabled}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }