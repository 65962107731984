import * as d3 from "d3-timer";
import parseDuration from "parse-duration";

class _CustomMap {
  constructor() {
    this._map = [];
  }

  clear() {
    this._map = [];
  }

  delete(key) {
    const filtered = this._map.filter(entry => entry.key !== key);
    const hasRemoved = filtered.length !== this._map.length;
    this._map = filtered;
    
    return hasRemoved;
  }

  set(key, value) {
    let entry = this._getRaw(key);

    if (entry) {
      entry.value = value;
    } else {
      this._map = this._map.concat([{
        key: key,
        value: value
      }]);
    }

    return this;
  }

  get(key) {
    const entry = this._getRaw(key);

    return entry && entry.value;
  }

  keys() {
    return this._map.map(entry => entry.key);
  }

  entries() {
    return this._map.map(entry => entry.value);
  }

  has(key) {
    return !!this._getRaw(key);
  }

  _getRaw(key) {
    return this._map.find(entry => entry.key === key); 
  }
}

/*
 * Note: there used custom implementation of Map, since the native Map isn't tracked by vue 2 watcher.
 * Can be changed to native Map only after migration to vue 3 and add the wrapper `reactive` to CacheMap instances.
 * https://v3.vuejs.org/api/basic-reactivity.html
 */
export default class CacheMap extends _CustomMap {
  constructor(iterable, options) {
    super();

    options = options || {};
    this._defaultTimeout = options.defaultTimeout ? parseDuration(options.defaultTimeout) : 0;
    (iterable || []).map(keyValue => {
      this.set(keyValue[0], keyValue[1], keyValue[2]);
    });
  }

  clear() {
    super.entries().forEach(entry => entry.timer.stop());
    return super.clear();
  }

  delete(key) {
    let entry = super.get(key);
    if (entry) {
      entry.timer.stop();
    }

    return super.delete(key);
  }

  set(key, value, duration) {
    const entry = super.get(key);
    if (entry) {
      entry.timer.stop();
    }

    return super.set(key, {
      value: value,
      timer: d3.timeout(() => this.delete(key), duration ? parseDuration(duration) : this._defaultTimeout)
    });
  }

  get(key) {
    const entry = super.get(key);
    return entry && entry.value;
  }

  entries() {
    return super.entries().map(entry => entry.value);
  }

  /* Not implemented in custom Map solution. */
  /*forEach(callback, thisArg) {
    return super.forEach((entry, key, map) => {
      if (thisArg) {
        return callback.call(thisArg, entry.value, key, map);
      }

      return callback(entry.value, key, map);
    })
  }*/
}
