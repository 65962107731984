import { eq, gt, gte, lt, lte } from "lodash";
/**
 * Fit the number into the limits with overflow jump.
 * The condition priority: eq, lte, lt; eq, gte, gt.
 * 
 * @param {Number} number - The number to fit into limits.
 * @param {Object} [lowerLimit] - The options of lower limit.
 * @param {Number} [lowerLimit.eq] - Limit condition - the `number` must be equal to `eq` value.
 * @param {Number} [lowerLimit.lte] - Limit condition - the `number` must be less than or equal to `lte` value.
 * @param {Number} [lowerLimit.lt] - Limit condition - the `number` must be less than `lt` value.
 * @param {Number} [lowerLimit.value] - The returned value instead of `number` when limit condition is done.
 * @param {Object} [upperLimit] - The options of upper limit.
 * @param {Number} [upperLimit.eq] - Limit condition - the `number` must be equal to `eq` value.
 * @param {Number} [upperLimit.gte] - Limit condition - the `number` must be greater than or equal to `lte` value.
 * @param {Number} [upperLimit.gt] - Limit condition - the `number` must be greater than `lt` value.
 * @param {Number} [upperLimit.value] - The returned value instead of `number` when limit condition is done.
 */
export default function fitNumber(number, lowerLimit, upperLimit) {
  lowerLimit = lowerLimit || {};
  upperLimit = upperLimit || {};

  const matched = [
    { compare: eq, value: number, other: lowerLimit.eq, to: lowerLimit.value },
    { compare: lte, value: number, other: lowerLimit.lte, to: lowerLimit.value },
    { compare: lt, value: number, other: lowerLimit.lt, to: lowerLimit.value },
    { compare: eq, value: number, other: upperLimit.eq, to: upperLimit.value },
    { compare: gte, value: number, other: upperLimit.gte, to: upperLimit.value },
    { compare: gt, value: number, other: upperLimit.gt, to: upperLimit.value }
  ].find(({ compare, value, other }) => {
    return other !== undefined && compare(value, other);
  });

  return (matched || { to: number }).to;
}
