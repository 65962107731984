/**
 * The stopwatch. Will be started on instantiation.
 * @class Stopwatch
 *
 * @property {Boolean} started - The flag indicates the stopwatch started.
 * @property {Number} startTime - The last start time.
 * @property {Number} stopTime - The last stop time.
 * @property {Number} duration - The duration between last start and last stop.
 */
export default class Stopwatch {
  constructor() {
    this.started = false;
    this.startTime = -1;
    this.stopTime = -1;
    this.duration = 0;

    this.start();
  }

  /**
   * Start the stopwatch.
   */
  start() {
    this.started = true;
    this.startTime = Date.now();
  }

  /**
   * Stop the stopwatch and calculate the duration.
   */
  stop() {
    if (!this.started) {
      return this.duration;
    }

    this.started = false;
    this.stopTime = Date.now();
    this.duration = this.stopTime - this.startTime;

    return this.duration;
  }
}
