var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'easyscreen-scrollable',
    { 'easyscreen-scrollable_native-scroll': _vm.$easyscreenNativeScrollable }
  ]},[_c('div',{staticClass:"easyscreen-scrollable--content-wrapper easyscreen-scrollable--smooth-edge"},[(_vm.smoothEdgeColor)?_c('div',{staticClass:"easyscreen-scrollable--smooth-edge-top",style:({ backgroundImage: `linear-gradient(to bottom, ${ _vm.smoothEdgeColor } 15%, rgba(0,0,0,0) 100%)` })}):_vm._e(),_c('div',{ref:"container",class:[
        'easyscreen-scrollable--content',
        { 'easyscreen-scrollable--content_with-scroll-bar': _vm.visibleScrollBar === true }
      ],style:({ maxHeight: _vm.maxHeight, pointerEvents: _vm.scrolling ? 'none' : '' }),domProps:{"scrollTop":_vm.scrollTop}},[_vm._t("default")],2),(_vm.smoothEdgeColor)?_c('div',{staticClass:"easyscreen-scrollable--smooth-edge-bottom",style:({ backgroundImage: `linear-gradient(to top, ${ _vm.smoothEdgeColor } 15%, rgba(0,0,0,0) 100%)` }),attrs:{"data-smooth-edge-color":_vm.smoothEdgeColor}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }