import * as querystring from "querystring";
import { get } from "lodash";
import { preloadQueue } from "./digital-shelf.js";

export default function(data) {
  const wData = data.data;
  const tags = wData.themes.items.map(shelf => shelf.theme);
  const materials = wData.themes.items.map(shelf => {
    return Object.keys(get(shelf, "settings.items", {})).map(materialId => {
      const material = get(shelf, `settings.items[${ materialId }]`);

      return {
        id: material.id || material.faustNumber,
        title: material.title,
        author: material.author,
        cover: material.cover || "/images/defaultCover.jpg",
        faustNumber: material.faustNumber,
        qrCode: material["qr-code"]
      };
    });
  });

  return {
    ...data.widgetPlacement,
    contentType: "M2Shelf",
    name: "qr-shelf",
    settings: function(context) {
      const urlOptions = querystring.parse(window.location.href.split("#")[1]);
      if (wData.wait_user && !urlOptions.standBy) {
        context.$easyscreenScreenActivity.changePendingTime(parseInt(wData.wait_user, 10) * 1000);
      }

      if (!("disableQrShelfPreload" in urlOptions)) {
        materials.forEach(async (_materials, themeIndex) => {
          try {
            await Promise.all(_materials.filter(Boolean).map(material => preloadQueue.pushAsync({
              id: material.id,
              context: context
            })));
            console.info(`All materials from "${ tags[themeIndex] }" theme loaded.`);
          } catch (error) {
            console.error(`Not all materials from "${ tags[themeIndex] }" theme loaded.`, error);
          }
        });
      }
    },
    options: {
      elements: tags.map((tagName, tagIndex) => {
        return {
          tag: tagName,
          materials: materials[tagIndex]
        };
      }),
      animationDuration: wData.animate ? parseInt(wData.animate, 10) * 1000 : undefined
    }
  };
}
