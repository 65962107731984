import { castArray } from "lodash";
import * as querystring from "querystring";
import urlJoin from "url-join";

/**
 * Build the url string from the object.
 * 
 * @param {Object} options - The url options.
 * @param {(String|String[])} options.domain - The url domain.
 * @param {String} options.path - The url pathname.
 * @param {Object} options.search - The search query of the url.
 * @param {Object} options.hash - The hash query of the url.
 */
export default function(options) {
  let url = urlJoin([options.domain || ""].concat(castArray(options.path).filter(Boolean)));

  [{
    delimiter: "?",
    query: options.search
  }, {
    delimiter: "#",
    query: options.hash
  }].forEach(_options => {
    if (_options.query && Object.keys(_options).length !== 0) {
      if (url.includes(_options.delimiter)) {
        return;
      }

      Object.keys(_options.query).forEach(key => {
        if (_options.query[key] === undefined) {
          delete _options.query[key];
        }
      });

      url += _options.delimiter + querystring.stringify(_options.query);
    }
  });

  return url;
}
