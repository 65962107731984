import { get } from "lodash";

export default async function(data) {
  const { slideConverter } = await import("./presentation-set.js");

  return {
    ...data.widgetPlacement,
    contentType: "PresentationSetCollectionCT",
    name: "presentation-set-collection",
    options: {
      slides: await Promise.all(data.data.map(async (options) => {
        return {
          title: options.ps_custom_name || options.ps_name,
          background: options.ps_background,
          content: (await Promise.all(get(options, "content.content", []).map((slide, _, slides) => {
            return slideConverter({
              ...slide,
              /* Filter out the nested presentation set collection for prevent recursive render. */
              widgets: (slide.widgets || []).filter(widget => {
                return widget.widgetName !== "PresentationSetCollectionCT";
              })
            }, {
              slideSwitcher: options.content && options.content.slideSwitcher,
              withDurationOverride: false
            }, slides);
          }))).filter(slide => slide.widgets.length !== 0)
        };
      }))
    }
  };
}
